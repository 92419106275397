<template>
  <!-- Generic dashboard widget -->
  <div :class="columnStyle">
    <div v-if="widget && widget.visible" class="card" :class="cardStyle">
      <div class="card-header media">
        <div class="media-left">
          <slot name="icon" />
        </div>
        <div class="media-body">
          <h3 class="card-title">{{title}}</h3>
          <nav class="nav card-nav">
            <slot name="linkID" />
          </nav>
        </div>
      </div>
      <slot name="options" />
        <!-- Widget contents slot -->
      <slot name="widget-contents" />
      <div class="card-footer">
        <!-- Widget general link slot -->
        <slot name="widget-modal-link" />
        <slot name="widget-modal-body" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { UIBaseWidget } from '@/UIModels/dashboard/baseWidget';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class BaseWidget extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) widget!: UIBaseWidget;

  get columnStyle(): string {
    let className = '';

    if (!this.widget || !this.widget.visible) {
      className = 'd-none';
    } else {
      switch(this.widget.style) {
        case 'full':
          className =  'col-12';
          break;
        case 'half':
          className =  'col-6';
          break;
        case 'one-third':
          className =  'col-4';
          break;
        case 'two-thirds':
          className =  'col-8';
          break;
        default:
          className =  'col-12';
          break;
      }
    }

    return className;
  }

  get cardStyle(): string {
    return this.widget?.style || '';
  }
}
</script>
