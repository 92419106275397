import { APIKidneySpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIKidneySpecificsConfig {
  public enabled: boolean;
  public paediatricAgeOverride: UIFeatureConfig;

  public constructor(apiSource: APIKidneySpecificDetailsConfig) {
    this.enabled = apiSource.enabled;
    this.paediatricAgeOverride = new UIFeatureConfig(apiSource.paediatric_age_override);
  }
}
