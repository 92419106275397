import { APISaveResponse, SaveResult, ValidationErrorObject } from '@/types';
import { AxiosError } from 'axios';
import i18n from '@/i18n';

/**
 * Identify a translation key for the specified error
 *
 * @param apiError error from axios or response containing structured error information
 * @returns string key for translation
 */
export function errorTranslationKey(apiError?: AxiosError|APISaveResponse): string {
  let result = 'error.unexpected_error';
  const response = ((apiError as AxiosError).isAxiosError) ? (apiError as AxiosError).response : apiError as APISaveResponse;
  const errorCode = response?.status;
  if (!errorCode) return result;
  switch (errorCode) {
    case 403:
      result = 'error.forbidden';
      break;
    case 422:
      result = 'error.field_level_validation_error';
      break;
    case 500:
      result = 'error.internal_server_error';
      break;
  }
  return result;
}

/**
 * Prepends validation errors with the specified key
 *
 * @param sourceIdentifier unique prefix to be added to the beginning of each error key
 * @param validationErrors object containing validation errors from API response
 * @returns {any} identical to errors input, except keys are prefixed
 */
export function prefixErrors(sourceIdentifier: string, validationErrors?: ValidationErrorObject): ValidationErrorObject {
  if (!validationErrors) return {};
  const errorEntries = Object.entries(validationErrors);
  const result: { [key: string]: any } = {};
  errorEntries.forEach((entry: [string, any]) => {
    const key = entry[0];
    const value = entry[1];
    const prefixedKey = `${sourceIdentifier}.${key}`;
    result[prefixedKey] = value;
  });
  return result;
}

export class UIError {
  public sourceIdentifier = '';
  public apiSource?: AxiosError|APISaveResponse;
  public statusCode: number|null = null;
  public statusText = '';
  public activityErrors: string[] = [];
  public validationErrors: ValidationErrorObject = {};

  // Derive unsuccessful 'SaveResult' summary with any messages and validation errors from the error
  get errorResult(): SaveResult {
    return {
      success: false,
      errorMessages: this.activityErrors,
      validationErrors: this.validationErrors,
    };
  }

  // Create UI Error view model based on a source identifier and, if available, an API response containing error(s)
  public constructor(sourceIdentifier: string, apiError?: unknown) {
    this.sourceIdentifier = sourceIdentifier;
    if (apiError) this.updateFromAPIResponse(apiError as AxiosError|APISaveResponse);
  }

  // Populate UI model structure based on API data
  public updateFromAPIResponse(apiError: AxiosError|APISaveResponse): void {
    this.apiSource = apiError;
    const response = ((apiError as AxiosError).isAxiosError) ? (apiError as AxiosError).response : apiError as APISaveResponse;
    this.statusCode = response?.status || null;
    this.statusText = response?.statusText || '';
    const rawErrors = (response?.data as any)?.errors;
    if (typeof rawErrors === 'string' || rawErrors instanceof String) {
      // If the errors key is set to a string, display it 'as is' as an error message
      this.activityErrors.push(rawErrors as string);
    } else {
      // Otherwise, handle errors as an object with field-level validation errors
      // Inject field-level validation key prefix if specified
      this.validationErrors = prefixErrors(this.sourceIdentifier, rawErrors);
    }
    // Provide the top-level error message if one is found
    if (!!rawErrors?.errors) this.activityErrors.push(rawErrors.errors as string);
    // If no error messages were found, ensure there is at least one message with default text
    if (this.activityErrors.length === 0) this.activityErrors.push(i18n.t(errorTranslationKey(apiError)));
  }
}
