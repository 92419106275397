import { APILiverSpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UILiverSpecificsConfig {
  public enabled: boolean;
  public peldResults: UIFeatureConfig;
  public hccCriteria: UIFeatureConfig;
  public hccResults: UIFeatureConfig;
  public bridgingTherapy: UIFeatureConfig;
  public downstagingTherapy: UIFeatureConfig;
  public aldProgramParticipation: UIFeatureConfig;
  public exceptionDiseases: UIFeatureConfig;

  public constructor(apiSource: APILiverSpecificDetailsConfig) {
    this.enabled = apiSource.enabled;
    this.peldResults = new UIFeatureConfig(apiSource.lab_liver_peld);
    this.hccCriteria = new UIFeatureConfig(apiSource.hcc_criteria);
    this.hccResults = new UIFeatureConfig(apiSource.hcc_data);
    this.bridgingTherapy = new UIFeatureConfig(apiSource.bridging_therapy);
    this.downstagingTherapy = new UIFeatureConfig(apiSource.downstaging_therapy);
    this.aldProgramParticipation = new UIFeatureConfig(apiSource.ald_program_participation);
    this.exceptionDiseases = new UIFeatureConfig(apiSource.exception_diseases);
  }
}
