import { APIRoute, EP } from '@/api-endpoints';
import { useCurrentPageStore } from '@/stores/currentPage';
import axios from 'axios';
import { UIError } from '@/UIModels/error';

export interface PrototypeBucketIdentifiers {
  recipientId: string|string[];
  journeyId?: string|string[];
}

export class UIPrototypeBucket {
  public name: string;

  public recipientId?: string;
  public journeyId?: string;

  public constructor(name: string, identifiers?: PrototypeBucketIdentifiers) {
    if (!useCurrentPageStore().configuration.prototypes) throw new UIError('prototypeBucket');

    this.name = name;
    if (identifiers) this.setIdentifiers(identifiers);
  }

  public setIdentifiers(opts: PrototypeBucketIdentifiers) {
    if (!opts) return;

    this.recipientId = Array.isArray(opts.recipientId) ? opts.recipientId[0] : opts.recipientId;
    if (opts.journeyId) this.journeyId = Array.isArray(opts.journeyId) ? opts.journeyId[0] : opts.journeyId;
  }

  /**
   * Add item to prototype bucket storage with specified key/value, or update a bucket's item value if the key already exists
   * @param name bucket name
   * @param key item key
   * @param value item value object
   * @returns {Promise<void>} promise that completes when setting is done
   */
  public async setItem(key: string, value: any): Promise<void> {
    const url = APIRoute(EP.prototypes.update_item, [[':id', this.name]]);
    const stringified = JSON.stringify(value);
    const payload = {
      recipient_id: this.recipientId,
      journey_id: this.journeyId,
      item: {
        key,
        value: stringified,
      },
    };
    try {
      await axios.put(url, payload);
    } catch (error: unknown) {
      alert(error);
    }
  }

  /**
   * Return a key's value from prototype storage, or null if the key does not exist
   * @param name bucket name
   * @param key item key
   * @returns {Promise<any|null>} promise that resolves with value object or null
   */
  public async getItem(key: string): Promise<any|null> {
    const url = APIRoute(EP.prototypes.show_item, [[':id', this.name]]);
    const query = {
      params: {
        recipient_id: this.recipientId,
        journey_id: this.journeyId,
        key,
      },
    };
    try {
      const response = await axios.get(url, query);
      if (!response?.data?.item?.value) return null;
      const parsed = JSON.parse(response.data.item.value);
      return parsed;
    } catch (error: unknown) {
      alert(error);
      return null;
    }
  }

  /**
   * Set entire array of key/value items to prototype bucket storage
   * @param items arary of key/value pairs
   * @returns {Promise<void>} promise that completes when setting is done
   */
  public async setAll(items: { key: string, value: any }[]): Promise<void> {
    const url = APIRoute(EP.prototypes.update, [[':id', this.name]]);
    const stringified = items.map((item: { key: string, value: any }): { key: string, value: string } => {
      return { key: item.key, value: JSON.stringify(item.value) };
    });
    const payload = {
      bucket: {
        recipient_id: this.recipientId,
        journey_id: this.journeyId,
        items: stringified,
      },
    };
    try {
      await axios.put(url, payload);
    } catch (error: unknown) {
      alert(error);
    }
  }

  /**
   * Return entire array of key/value items from prototype storage
   * @param name bucket name
   * @param key item key
   * @returns {Promise<{ key: string; value: any}[]>} promise that resolves with array of key/value items
   */
  public async getAll(): Promise<{ key: string; value: any }[]> {
    const url = APIRoute(EP.prototypes.show, [[':id', this.name]]);
    const query = {
      params: {
        recipient_id: this.recipientId,
        journey_id: this.journeyId,
      },
    };
    try {
      const response = await axios.get(url, query);
      if (!response?.data?.items) return [];
      const parsed = response.data.items.map((item: { key: string; value: string }) => {
        return { key: item.key, value: JSON.parse(item.value) };
      });
      return parsed as { key: string; value: any }[];
    } catch (error: unknown) {
      alert(error);
      return [];
    }
  }
}
