import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      id: _ctx.label || _ctx.name,
      for: _ctx.selectId,
      class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
    }, [
      _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
      _createVNode(_component_validation_asterisk, {
        rules: _ctx.formRules,
        crossValues: _ctx.crossValues,
        ruleKey: _ctx.ruleKey
      }, null, 8, ["rules", "crossValues", "ruleKey"]),
      _renderSlot(_ctx.$slots, "link")
    ], 10, _hoisted_1),
    (_ctx.showToolTip)
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          toolTipText: _ctx.toolTipText,
          inputID: _ctx.selectId
        }, null, 8, ["toolTipText", "inputID"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MultiSelect, {
      id: _ctx.selectId,
      modelValue: _ctx.tags,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tags) = $event)),
      options: _ctx.getAutocompleteOptions,
      optionLabel: "text",
      ariaLabelledBy: _ctx.label || _ctx.name,
      "aria-label": _ctx.label || _ctx.name,
      rules: true,
      placeholder: _ctx.placeholder,
      maxSelectedLabels: 3,
      disabled: _ctx.disabled,
      filter: true,
      onChange: _cache[1] || (_cache[1] = updatedTagObjects => _ctx.updateVModel(updatedTagObjects)),
      display: _ctx.displayType
    }, null, 8, ["id", "modelValue", "options", "ariaLabelledBy", "aria-label", "placeholder", "disabled", "display"])
  ]))
}