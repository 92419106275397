import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "form-group col-md-8 col-lg-8 col-xl-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "form-group col-md-6 col-xl-4" }
const _hoisted_6 = { class: "form-group col-md-6 col-xl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, { "form-id": "bookmark-form" }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.selection.isNew ? _ctx.$t('new_bookmark') : _ctx.$t('selected_bookmark')), 1)
          ])
        ]),
        action: _withCtx(() => [
          _createVNode(_component_action_toolbar, {
            permittedActions: _ctx.editState.permittedActions,
            destroyTitleText: _ctx.$t('delete_bookmark_title'),
            destroyGuidingText: _ctx.$t('delete_bookmark_guiding_text'),
            onDestroy: _ctx.onDestroy
          }, null, 8, ["permittedActions", "destroyTitleText", "destroyGuidingText", "onDestroy"])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_text_area_input, {
                inputId: "bookmark-name",
                ruleKey: "bookmarks.name",
                name: _ctx.$t('name'),
                showGuidingText: true,
                guidingText: _ctx.$t('bookmark_description_guiding_text'),
                modelValue: _ctx.editState.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.name) = $event))
              }, null, 8, ["name", "guidingText", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_date_input, {
                inputId: "bookmark-due-date",
                ruleKey: "bookmarks.due_date",
                name: _ctx.$t('due_date'),
                modelValue: _ctx.editState.dueDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.dueDate) = $event))
              }, null, 8, ["name", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_text_input, {
                inputId: "bookmark-link",
                ruleKey: "bookmarks.link",
                name: _ctx.$t('link'),
                modelValue: _ctx.editState.link,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.link) = $event))
              }, null, 8, ["name", "modelValue"])
            ])
          ])
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: true,
            ref: "saveBookmark",
            label: _ctx.$t('save_bookmark'),
            cancelButton: true,
            onSave: _ctx.onSave,
            onCancel: _ctx.onCancel
          }, null, 8, ["label", "onSave", "onCancel"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}