import { APIPrototypes, APIShowPrototypesData } from '@/APIModels/prototypes/types';
import { APIRoute, EP } from '@/api-endpoints';
import { useCurrentPageStore } from '@/stores/currentPage';
import { APIShowResponse } from '@/types';
import axios from 'axios';

// NOTE: For master list of prototypes, see 'PROTOTYPES' enum in src/UIModels/prototypes/list.ts

export class UIPrototypes {
  public apiSource?: APIPrototypes;

  public defined: string[] = [];
  public enabled: string[] = [];

  get prototypesEnabled(): boolean {
    return !!useCurrentPageStore().configuration.prototypes;
  }

  public constructor(apiPrototypes?: APIPrototypes) {
    if (apiPrototypes) this.updateFromAPIPrototypes(apiPrototypes);
  }

  public updateFromAPIPrototypes(apiPrototypes: APIPrototypes) {
    if (!apiPrototypes) throw new Error('Cannot initialize prototypes without API source');
    this.apiSource = apiPrototypes;

    Object.entries(apiPrototypes).forEach((entry: [string, boolean]) => {
      const prototypeKey = entry[0];
      const prototypeEnabled = entry[1];
      this.defined.push(prototypeKey);
      if (prototypeEnabled) this.enabled.push(prototypeKey);
    });
  }

  public async load(): Promise<void> {
    if (!useCurrentPageStore().configuration.prototypes) return;

    const url = APIRoute(EP.prototypes.index);
    try {
      const response: APIShowResponse<APIShowPrototypesData> = await axios.get(url);
      const apiPrototypes: APIPrototypes = response?.data?.prototypes;
      this.updateFromAPIPrototypes(apiPrototypes);
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  // Check if a prototype is defined in the prototypes index
  public isDefined(prototypeKey: string): boolean {
    if (!this.prototypesEnabled) return false;
    return this.defined.includes(prototypeKey);
  }

  // Check if a prototype is enabled (true)
  public isEnabled(prototypeKey: string): boolean {
    if (!this.prototypesEnabled) return false;
    return this.enabled.includes(prototypeKey);
  }

  // Check if all of the specified prototypes are enabled
  public allEnabled(prototypeKeys: string[]): boolean {
    if (!this.prototypesEnabled) return false;
    const filtered = prototypeKeys.filter((prototypeKey: string) => { return this.isEnabled(prototypeKey); });
    return filtered.length === prototypeKeys.length;
  }

  // Check if at least one of the specified prototypes are enabled
  public anyEnabled(prototypeKeys: string[]): boolean {
    if (!this.prototypesEnabled) return false;
    const filtered = prototypeKeys.filter((prototypeKey: string) => { return this.isEnabled(prototypeKey); });
    return filtered.length > 0;
  }
}
