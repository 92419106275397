import { APIRecipient, APIPatientProfile, APITransplantCentre } from "@/store/recipients/types";
import { useCurrentPageStore } from "@/stores/currentPage";
import { APIActivityErrorData, APISaveRecipientResponse, APIShowRecipientData, SaveResult } from "@/types";
import { parseDateUi, parseDateUiFromDateTime, parseTimeUiFromDateTime, sanitizeDateApi } from "@/utilities/date-utils";
import { calculateAge } from "@/utils";
import { UIRecipient } from "@/UIModels/recipient";
import { UIError } from "@/UIModels/error";
import { APIRoute, EP } from "@/api-endpoints";
import axios from "axios";
import { UISuccess } from "@/UIModels/success";
import { UIDemographicsConfig } from "@/UIModels/configuration/features/recipientConfig/demographics";

export class UIDemographics {
  public apiSource?: APIRecipient;

  // Registration Info
  public affloId = '';
  public ctrId = '';
  public registrationDate = '';
  public registrationTime = '';
  public urgentRecipient = false;

  // Personal Info
  public firstName = '';
  public middleName = '';
  public lastName = '';
  public preferredName = '';
  public preferredPronouns = '';
  public dateOfBirth = '';
  public mrn = '';
  public governmentIdentificationNumber = '';
  public race: string[] = [];
  public ethnicity: string|null = null;
  public ethnicityOther = '';
  public indigenousIdentityCodes: string[] = [];
  public sexAtBirth: string|null = null;
  public genderSexDifferent = false;
  public genderIdentity: string|null = null;
  public genderOther = '';
  public highestEducationLevel: string|null = null;
  public academicGradeLevel: string|null = null;

  // TODO: TECH_DEBT replace Demographics-only 'isNew' check with Create Patient flow
  get isNew(): boolean {
    return !this.apiSource;
  }

  get demographicsConfig(): UIDemographicsConfig {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics;
  }

  /**
   * Get a string representation for the age of the Patient in years
   *
   * Calculates the age of the Recipient using the value of Date of Birth and Date of Death (if exists)
   *
   * @returns {string} Patient's age in years as a string
   */
  get ageInYears(): string {
    const recipientDoB: string|null = this.dateOfBirth;
    const recipientDoD: string|null = parseDateUi(this.apiSource?.death?.death_date) || null;
    const ageInYears: number|null = calculateAge(recipientDoB, recipientDoD);
    return ageInYears === null ? '' : ageInYears.toString();
  }

  public constructor(apiRecipient?: APIRecipient) {
    if (apiRecipient) this.updateFromAPIResponse(apiRecipient);
  }

  // Build a copy of the view model
  public copyViewModel() {
    return new UIDemographics(this.apiSource);
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiRecipient: APIRecipient) {
    this.apiSource = apiRecipient;
    // Fetch nested documents in a type-safe way
    const apiTransplantCentre: APITransplantCentre = apiRecipient.transplant_centre || {};
    const apiPatientProfile: APIPatientProfile = apiRecipient.patient_profile || {};
    const apiCtrDetails = apiPatientProfile.ctr || {};
    const apiBirth = apiPatientProfile.birth || {};
    const apiGovernmentIdentification = apiPatientProfile.government_identification || {};
    const apiEducation = apiPatientProfile.education || {};
    // Registration Info
    this.affloId = apiRecipient.client_id == null ? '' : apiRecipient.client_id?.toString();
    this.ctrId = apiCtrDetails.national_recipient_id;
    this.registrationDate = parseDateUiFromDateTime(apiPatientProfile.registration_date) || '';
    this.registrationTime = parseTimeUiFromDateTime(apiPatientProfile.registration_date) || '';
    this.urgentRecipient = apiRecipient.urgent || false;
    // Personal Info
    this.firstName = apiPatientProfile.first_name || '';
    this.middleName = apiPatientProfile.middle_name || '';
    this.lastName = apiPatientProfile.last_name || '';
    this.preferredName = apiPatientProfile.preferred_name || '';
    this.preferredPronouns = apiPatientProfile.preferred_pronouns || '';
    this.dateOfBirth = parseDateUi(apiBirth.date) || '';
    this.mrn = apiTransplantCentre.mrn || '';
    this.governmentIdentificationNumber = apiGovernmentIdentification.number || '';
    this.race = apiPatientProfile.race_codes || [];
    this.ethnicity = apiPatientProfile.ethnicity_code || null;
    this.ethnicityOther = apiPatientProfile.ethnicity_other || '';
    this.indigenousIdentityCodes = apiPatientProfile.indigenous_identity_codes || [];
    this.sexAtBirth = apiPatientProfile.sex || null;
    this.genderSexDifferent = apiPatientProfile.gender_sex_different || false;
    this.genderIdentity = apiPatientProfile.gender || null;
    this.genderOther = apiPatientProfile.gender_other || '';
    this.highestEducationLevel = apiEducation.highest_education_level || null;
    this.academicGradeLevel = apiEducation.academic_grade_level || null;
  }

  // Generate request payload parameters to provide to API
  private extractPatch(): APIRecipient {
    const result: APIRecipient = {};
    // Registration Info
    if (this.demographicsConfig.registrationInfo.urgentRecipient.enabled) {
      result.urgent = this.urgentRecipient ? true : false;
    }
    // Personal Info
    if (this.demographicsConfig.personalInfo.enabled) {
      result.patient_profile = {
        first_name: this.firstName,
        middle_name: this.middleName,
        last_name: this.lastName,
        preferred_name: this.preferredName,
        preferred_pronouns: this.preferredPronouns,
        birth: {
          date: sanitizeDateApi(this.dateOfBirth),
        },
        government_identification: {
          number: this.governmentIdentificationNumber,
        },
        sex: this.sexAtBirth || null,
        gender_sex_different: this.genderSexDifferent ? true : false,
        gender: (this.genderSexDifferent && this.genderIdentity) || null,
        gender_other: (this.genderSexDifferent && this.genderOther) || null,
        race_codes: this.race,
        ethnicity_code: this.ethnicity || null,
        ethnicity_other: this.ethnicityOther || null,
        indigenous_identity_codes: this.indigenousIdentityCodes,
        education: {
          highest_education_level: this.highestEducationLevel || null,
          academic_grade_level: this.academicGradeLevel || null,
        },
      };
      result.transplant_centre = {
        mrn: this.mrn,
      };
    }
    return result;
  }

  // Save edit state to the backend
  public async save(opts: { selected: UIDemographics|null, recipient: UIRecipient }): Promise<SaveResult> {
    const recipientId = opts.recipient.clientId;
    let method: any;
    let ep: string;
    if (this.isNew) {
      method = axios.post;
      ep = APIRoute(EP.recipients.create);
    } else {
      method = axios.patch;
      ep = APIRoute(EP.recipients.update, [[':id', recipientId as string]]);
    }
    const payload: { recipient: APIRecipient } = {
      recipient: this.extractPatch()
    };
    try {
      const response: APISaveRecipientResponse = await method(ep, payload);
      if ((response.data as APIActivityErrorData)?.errors) throw response;
      const apiRecipient: APIRecipient = (response.data as APIShowRecipientData)?.recipient;
      opts.recipient.updateFromAPIResponse(apiRecipient);
      await opts.recipient.load({ reload: true });
      return new UISuccess(response).getSaveResult();
    } catch (error: unknown) {
      throw new UIError('recipient_demographics', error).errorResult;
    }
  }
}
