<template>
  <div class="mb-3" v-if="tableConfig && tableConfig.columns"
  :class="getStyleClass()">
    <!-- primevue table -->
    <div v-if="showTopScrollBar" class="adaptive-top-scrollbar" ref="adaptiveTopScrollbar">
      <div></div>
    </div>
    <DataTable
      :id="tableId"
      :ref="tableId"
      responsiveLayout="stack"
      :showGridlines="true" 
      :value="paginatedRows"
      :loading="isLoading"
      rowKey='id'
      :lazy="isLazy"
      :class="{'scrollbox': true}"
      :totalRecords="getNumberOfRecords"
      @row-click="onRowClick($event)"
      :expandedRows="paginatedRows"
      :first="pageOffset"
      :rows="pageSize"
      :rowClass="rowStyle"
      :rowGroupMode="getGroupMode"
      :groupRowsBy="getGroupBy"
      :filter-display="filterDisplay"
      :show-filter-menu="false"
      :sort-mode="sortMode"
      :null-sort-order="-1"
      :multi-sort-meta="multiSortMeta"
      :removable-sort="true"
      @sort="onSort"
      >
      <template v-for="col of tableConfig.columns">
        <Column
          v-if="!col.expanded && !col.hidden"
          :field="col.field"
          :class="col.class"
          :header="col.label"
          :sortable="col.sortable || false"
          :key="col.field"
          :style="buildColumnStyle(col)"
          :bodyClass="col.tdClass"
          :sortField="col.sortField"
        >
          <template #filter>
            <div v-if="col.filterOptions">
              <text-input
                v-if="col.filterOptions.type == 'text'"
                :input-id="`${col.field}_filter`"
                :name="col.label"
                :hide-label="true"
                :model-value="searchParams[col.field]"
                @update:model-value="(event) => onFilter(event, col)"
              />
              <select-input
                v-else-if="col.filterOptions.type == 'dropdown'"
                :select-id="`${col.field}_filter`"
                :name="col.label"
                :hide-label="true"
                :model-value="searchParams[col.field]"
                @update:model-value="(event) => onFilter(event, col)"
                :options="col.filterOptions.filterDropdownItems"
                :undefinedText="col.filterOptions.placeholder"
              />
            </div>
          </template>
          <template #body="slotProps">
            <span v-if="isMobile" class="p-column-title">{{col.label}}</span>
           
            <span v-if="col.html" v-html="slotProps.data[col.field]" />
            <span v-else-if="col.download">
              <a href="#" @click.prevent.stop="onDownloadClick(slotProps.data.downloadParams)" :title="col.tooltip">
                <font-awesome-icon class='text-grey mr-2' :icon="['far', 'download']" size='sm' aria-hidden='true' fixed-width />
                {{ slotProps.data[col.field] }}
              </a>
            </span>
            <span v-else-if="col.htmlLink">
              <a
                v-if="slotProps.data[col.field]"
                :href="slotProps.data[col.field]"
                :title="slotProps.data[col.field]"
                target="_blank"
              >
                <font-awesome-icon class='text-grey mr-2' :icon="['far', 'external-link']" size='sm' aria-hidden='true' fixed-width />
                <span class="bold-link">{{ $t('follow_link') }}</span>
              </a>
              <span v-else>-</span>
            </span>
            <span v-else-if="labelMatchesTabbableColumn(col.field, tabbableColumn)">
              <a href="#" tabindex="0" class="table-link" v-on:click.stop.prevent="onRowClick(slotProps)">{{slotProps.data[col.field]}}</a>
            </span>
            <span v-else>{{ slotProps.data[col.field] }}</span>
          </template>
        </Column>
      </template>
      <template #groupheader="">
        <span aria-hidden="true">&nbsp;</span>
      </template>
      <template #expansion="slotProps" v-if="getNameOfExpandedField">
        <div>
          <more-less :descriptionText="slotProps.data[getNameOfExpandedField]" />
        </div>
      </template>
      <template #empty>
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ tableConfig.empty || $t('use_form_below_new_row') }}
      </template>
      <!-- second row -->
    </DataTable>

    <template v-if="tableConfig.pagination">
      <Paginator
        :class="{ 'p-paginator-spacer': (!!getNumberOfRecords && getNumberOfRecords > 0) }"
        :first="pageOffset"
        :rows="pageSize"
        :totalRecords="getNumberOfRecords" 
        :rowsPerPageOptions="getPaginationDropdown"
        @page="onPageNumber($event)"
        :template="paginationTemplate"
        :currentPageReportTemplate="currentPageReportTemplate"
        >
        <template v-slot:start>
          <span>{{ $t('results_per_page') }}:</span>
          <select-input
            selectId="results-per-page"
            :name="$t('rows_per_page')"
            v-model="pageSize"
            :hideLabel="true"
            :options="getPaginationDropdown"
            @change="onPageSize($event)"
            :inline="true"
            :nullable="false"
            :numeric="true"
          />
        </template>
        <template #previcon>
          <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
        </template>
        <template #nexticon>
          <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
        </template>
      </Paginator>
    </template>
  </div>
</template>

<script lang="ts">
import { SortType, TableConfig } from '@/types';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import MoreLess from '@/components/shared/MoreLessComponent.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { i18nMessages } from '@/i18n';
import { ColumnOption } from '@/types';
import TextInput from "@/components/shared/TextInput.vue";

interface SortParameters {
  sort_by: string|null;
  sort_type: string|null;
}

interface SearchParameters {
  [key: string]: string;
}

interface PaginationEvent {
  first: number; // index of first record
  page: number; // new page number, zero-based
  pageCount: number; // total number of pages
  rows: number; // number of rows to display in new page
}

@Component({
  components: {
    DataTable,
    Column,
    MoreLess,
    Paginator,
    SelectInput,
    TextInput,
  },
  ...i18nMessages([
    require('@/components/shared/_locales/TableList.json'),
  ]),
  emits: [
    'on-page-size',
    'on-page-number',
    'on-column-filter',
    'on-sort-change',
    'table-row-click',
    'table-row-download',
  ],
})
export default class TableList extends Vue {
  @Prop({ required: true }) tableId!: string;
  @Prop({ required: true }) tableConfig!: TableConfig;
  @Prop({ required: false, default: null }) totalRecords?: number|null;
  @Prop({ required: false }) mode?: string;
  @Prop({ default: false }) highlightSelection!: boolean; // when true, highlight selected rows
  @Prop({ required: false }) rowStyleClass?: string|((row: any) => string)
  @Prop({ default: false }) jumpToPage!: boolean;
  @Prop({ default: false}) isLoading!: boolean;
  // row grouping params
  @Prop({ default: null }) groupBy!: string; // group mode (options are 'subheader' or null)
  @Prop({ default: null }) groupMode!: string; // row item to group by (e.g. field name)

  // Lookup properties
  @Prop({ default: () => { return []; } }) lookupsToLoad!: string[];
  @Prop({ default: () => { return []; } }) laboratoriesToLoad!: string[];
  @Prop({ default: () => { return []; } }) hospitalsToLoad!: string[];

  @Prop({ default: false }) horizontalScroll!: boolean;
  @Prop({ default: false }) listingView!: boolean;
  @Prop({ default: false }) showTopScrollBar!: boolean;
  @Prop({ default: false }) tabbableColumn!: string;
  @Prop({ default: 'single' }) sortMode!: string;

  // Private attributes
  private selectedIds: string[] = [];

  // Zero-relative number of the first row to be displayed
  private pageOffset: number|null = 0;

  // User-facing one-based page number
  private pageNumber: number|null = 1;

  // User-selected 'Results per page' dropdown
  private pageSize: number|null = 10;

  private isMobile = false;

  // sorting and search
  public sortParams: SortParameters = { sort_by: null, sort_type: null };
  private searchParams: SearchParameters = {};

  // Get the total number of records
  // NOTE: for remote-paginated table, we must let parent component pass this number (see AP-1587)
  private get getNumberOfRecords(): number {
    if (this.totalRecords != null) return this.totalRecords;

    return this.tableConfig.data ? this.tableConfig.data.length : 0;
  }

  public  getStyleClass() {
    return { "spaced-list-items": this.isMobile, 'horizontal-view': this.horizontalScroll, 'listing-view' : this.listingView };
  } 

  private get tableRef(): TableList {
    return this.$refs[this.tableId] as TableList;
  }

  public checkwidth() {
    if (!this.horizontalScroll && window.innerWidth < 960) {
      this.isMobile = window.innerWidth < 960;
    } else {
      this.isMobile = false;
      if (this.showTopScrollBar) {
        this.setupScrollBarStyle((this.tableRef as any).rootEl);
      }
    }
  }

  public setupScrollBarStyle(element: any) {
    // make reference to scrollbar
    let scrollbar = (this.$refs.adaptiveTopScrollbar as any);

    // alter style
    const scrollbarStyle = scrollbar?.style;
    if (scrollbarStyle) {
      scrollbarStyle.overflowY = 'hidden';
    }
  
    // alter child
    if (scrollbar && scrollbar.children) {
      const scrollbarChild = scrollbar.children[0];
      scrollbarChild.style.width = element.scrollWidth+'px';
    }
  }

  public setupScrollBarEvents(element: any) {
    // make reference to scrollbar
    let scrollbar = (this.$refs.adaptiveTopScrollbar as any);

    // add scroll events
    scrollbar.onscroll = function() {
      element.scrollLeft = scrollbar.scrollLeft;
    };
    element.onscroll = function() {
      scrollbar.scrollLeft = element.scrollLeft;
    };    
  }

  mounted(): void {
    this.pageSize = this.defaultPerPageOption;
 
    if(!this.showTopScrollBar) {
      return;
    }

    this.setupScrollBarEvents((this.tableRef as any).rootEl);
    this.checkwidth();
  
    window.addEventListener('resize', this.checkwidth);
    this.initializeSearchParameters();
  }

  get getGroupMode(): string {
    return this.isMobile ? 'subheader' : this.groupMode; 
  }

  get getGroupBy(): string {
    return this.isMobile ? 'id' : this.groupBy; 
  }

  get paginationTemplate(): string {
    return this.jumpToPage ? 'JumpToPageInput CurrentPageReport PrevPageLink NextPageLink' : 'CurrentPageReport PrevPageLink NextPageLink';
  }

  get currentPageReportTemplate(): string {
    return this.jumpToPage ? 'of {totalPages}' : '{first} - {last} of {totalRecords}';
  }

  get filterDisplay(): string|null {
    if (!this.tableConfig) return null;

    const hasFilters = (this.tableConfig?.columns || []).some((col: ColumnOption) => {
      return !!col.filterOptions?.enabled;
    });
    return hasFilters ? 'row' : null;
  }

  get isLazy(): boolean { return this.mode === 'remote'; }

  get multiSortMeta(): { field: string, order?: number|null }[]|null {
    if (this.sortMode !== 'multiple') return null;
    if (!this.tableConfig?.sortOptions?.enabled) return null;

    const sortMeta = (this.tableConfig.sortOptions.initialSortBy || []).map((initialSortBy: { field: string; type: SortType; }): { field: string, order?: number|null } => {
      return {
        field: initialSortBy.field,
        order: this.buildSortOrder(initialSortBy.type),
      };
    });
    return sortMeta;
  }

  get getNameOfExpandedField(): string|null {
    if (!this.tableConfig) return null;
    const found = this.tableConfig.columns.find((item: any) => {
      return item.expanded === true;
    });
    return found ? found.field : null;
  }

  get getPaginationDropdown(): {code: number, value: number}[] {
    const perPageDropdown = this.tableConfig.paginationOptions.perPageDropdown || [10,25,50];
    return perPageDropdown.map((item: number) => {
      return { code: item, value: item };
    });
  }

  get defaultPerPageOption(): number {
    if (!this.tableConfig?.paginationOptions?.perPageDropdown || this.tableConfig.paginationOptions.perPageDropdown.length === 0) return 10;
    if (this.tableConfig.paginationOptions.defaultPageSize) return this.tableConfig.paginationOptions.defaultPageSize;
    return this.tableConfig.paginationOptions.perPageDropdown[0];
  }

  // Handle frontend pagination based on the current "pageNumber" and "perPage" values
  // NOTE: make sure to do filtering before paginating
  get paginatedRows(): any[] {
    const allRows = this.tableConfig.data || [];
    if (this.mode === 'remote') return allRows;

    const pageNumber = this.pageNumber || 0;
    const perPage = this.pageSize || 0;
    const startIndex = (pageNumber - 1) * perPage;
    const endIndex = Math.min(startIndex + perPage, allRows.length);
    const sliced = perPage > 0 ? allRows.slice(startIndex, endIndex) : allRows;
    return sliced;
  }

  initializeSearchParameters(): void {
    const params: SearchParameters = {};
    this.tableConfig.columns.forEach((col: any) => {
      if (col.filterOptions?.enabled && col.field) {
        params[col.field] = '';
      }
    });
    this.searchParams = params;
  }

  onPageSize(value: number): void {
    this.pageOffset = 0;
    this.pageNumber = 1;
    this.$emit('on-page-size', value);
  }

  // Callback to invoke when page changes, the event object contains information about the new state.
  onPageNumber(e: PaginationEvent): void {
    if (!e) return;

    this.pageOffset = e.first;
    this.pageNumber = e.page + 1;
    this.$emit('on-page-number', this.pageNumber);
  }

  labelMatchesTabbableColumn(col: string, tabbable: string|undefined): boolean {
    if (!tabbable) { return false; }
    if (!col) { return false; }
    return col.replace(/\s/g, '') === tabbable.replace(/\s/g, '');
  }

  get rowStyle(): any {
    // For selection highlighting, use function driven by _id field (styling purposes only)
    return (row: any): string => {
      const results: string[] = [];
      // Permit customization of row style class using props
      if (this.rowStyleClass) results.push(typeof this.rowStyleClass == 'string' ? this.rowStyleClass : this.rowStyleClass(row));

      const id: any = this.idFromRow(row);
      const selected = this.selectedIds.includes(id);
      results.push(this.highlightSelection && selected ? 'tr-active' : 'tr-link');
      results.push('p-datatable-selectable-row');

      return results.join(' ');
    };
  }

  // Extract ID from row
  // NOTE: assumes row has 'id' or '_id.$oid'
  private idFromRow(row: any): string {
    if (!row) return '';
    if (row.id) return row.id;
    if (row._id?.$oid) return row._id.$oid;
    return '';
  }

  // Event handlers
  public onRowClick(event: any) {
    const row = event.data;
    // Update internal sense of which rows are selected (only used for styling purposes)
    const id = this.idFromRow(row);
    this.selectedIds = [id];
    // Emit event to parent, which will handle functional logic separate from styling
    this.$emit('table-row-click', { row: row });
  }

  private buildSortType(sortOrder: number|null): SortType|null {
    switch (sortOrder) {
      case 1:
        return SortType.Ascending;
      case -1:
        return SortType.Descending;
      default:
        return null;
    }
  }

  private buildSortOrder(sortType: SortType|null): number|null {
    switch (sortType) {
      case SortType.Ascending:
        return 1;
      case SortType.Descending:
        return -1;
      default:
        return null;
    }
  }

  public onSort(event: { sortField: string|null, sortOrder: number|null }) {
    const fieldName = event.sortField;
    this.sortParams = { sort_by: null, sort_type: null  };
    if (fieldName && event.sortOrder != null) {
      this.sortParams.sort_by = fieldName;
      this.sortParams.sort_type = this.buildSortType(event.sortOrder);
    }
    const sortChangeEvent = {
      sortParams: this.sortParams,
      searchParams: this.searchParams,
    };
    this.$emit('on-sort-change', sortChangeEvent);
  }

  private searchDebounce: any;

  private onFilter(value: string, col: ColumnOption) {
    const fieldName = col.field;
    this.searchParams[fieldName] = value;

    if (this.searchDebounce) clearTimeout(this.searchDebounce);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _vm = this;
    this.searchDebounce = setTimeout(() => {
      const columnFilterEvent = {
        sortParams: this.sortParams,
        searchParams: this.searchParams,
      };
      _vm.$emit('on-column-filter', columnFilterEvent);
    }, 1500);
  }

  public resetSelection(): void {
    this.selectedIds = [];
  }

  private onDownloadClick(params: any): void {
    this.$emit('table-row-download', params);
  }

  private buildColumnStyle(col: ColumnOption): string|undefined {
    const styleParts = [];
    if (col.width) {
      styleParts.push(`width: ${col.width}`);
      styleParts.push(`min-width: ${col.width}`);
      styleParts.push(`max-width: ${col.width}`);
    }

    styleParts.push('overflow: hidden');
    styleParts.push('text-overflow: ellipsis');

    return styleParts.join('; ');
  }
}
</script>
