<template>
  <card-section
    section-id="recipient-serology"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('title') }}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('subtitle')"
        sub-section-id="record-recipient-serology"
      >
        <template v-slot:contents>
          <loading-fields v-if="isLoading || !editState" />

          <!-- Filter Bar + Create Button -->
          <div class="vertical-direction-navigation d-flex">
            <button
              type="button"
              data-dismiss="modal"
              @click="scrollLeft"
              :disabled="!hasMultipleSerologyRecords || navigationButton == 'left' || !scrollBarVisible"
              class="btn btn-sm btn-secondary mr-2 ml-auto mb-2">
              {{ $t('left') }}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              @click="scrollRight"
              :disabled="!hasMultipleSerologyRecords || navigationButton == 'right' || !scrollBarVisible" 
              class="btn btn-sm btn-secondary mb-2">
              {{ $t('right') }}
            </button>
          </div>
          <table-toolbar
            :createButton="canSave && !newRecipient"
            :createText="$t('create')"
            @table-create-row="handleTableCreateRow"
          >
          </table-toolbar>

          <!-- List of Items, or History List -->
          <vertical-table-list
            ref="patientSerologyTable"
            :showTopScrollBar="true"
            table-id="patient-serology-table"
            :table-config="patientSerologyTableConfig"
            @table-col-click="handleTableColClick"
            :highlightSelection="true"
            :horizontalScroll="true"
            listingView="false"
            @update-scroll-button="setScrollValue"
          />

          <validation-observer ref="validations">
            <form-layout
              :disabled="!enableForm"
              form-id="serology_form">
              <template v-slot:title>
                <!-- Mode indicator / subsection form title -->
                <legend class="has-guiding-text">
                  <h5 v-if="!selection?.isNew" class="legend-title">
                    {{$t('selected_result')}}
                  </h5>
                  <h5 v-else class="legend-title has-guiding-text">
                    {{$t('new_result')}}
                  </h5>
                  <small>{{ $t('guiding_text') }}</small>
                </legend>
                
              </template>

              <template v-slot:contents>
                <!-- Data Entry Form (Add, View, and Edit modes) -->
                <div class="row mb-2">
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="recipient_virology.sample_datetime"
                      inputId="collection_date"
                      :name="$t('collection_date')"
                      v-model="editState.collection_date"
                    />
                  </div>
                  <div class="standard-form-group">
                    <time-input
                      ruleKey="recipient_virology.sample_datetime"
                      inputId="collection_time"
                      :name="currentConfiguration.replaceTimezoneAbbreviation($t('collection_time'))"
                      v-model="editState.collection_time"
                    />
                  </div>
                  <div class="standard-form-group-large">
                    <text-area-input
                      ruleKey="recipient_virology.sample_comment"
                      inputId="collection_comments"
                      :name="$t('collection_comments')"
                      v-model="editState.collection_comments"
                    />
                  </div>
                </div>                

                <sub-section
                  sub-section-id="serology-markers"
                 >         
                  <template v-slot:contents>
                    <legend class="mb-4">
                      <h5 class="legend-title">
                        {{$t('results')}}
                      </h5>
                    </legend>
                    <div :class="isMobile ? 'spaced-form-items' : ''">
                      <DataTable :value="editState.markers" ref="markerTable">
                        <Column field="test">                  
                          <template #header>
                            {{ $t('test') }}
                            <span class="asterisk" v-if="requiredMarkerArray['test']"><i>&nbsp;*</i></span>                            
                          </template>
                          <template #body="slotProps">
                            <AutoCompleteInput
                              ruleKey="recipient_virology.results.code"
                              :name="$t('test')"
                              :options="getFilteredVirologyCodesCombined"
                              v-model="slotProps.data.test"
                              :disabled="!enableForm"
                              :inputId="`serology.markers.${slotProps.data.id}.test`"
                              :hideLabel="!isMobile"
                              :showDropdown="true"
                              :placeholder="$t('select')"
                              :hide_restricted_permissions="true"
                            />
                          </template>
                        </Column>
                        <Column field="result">
                          <template #header>
                            {{ $t('result') }}
                            <span class="asterisk" v-if="requiredMarkerArray['result']"><i>&nbsp;*</i></span>                            
                          </template>
                          <template #body="slotProps">
                            <SelectInput
                              ruleKey="recipient_virology.results.result"
                              :selectId="`serology.markers.${slotProps.data.id}.result`"
                              :name="$t('result')"
                              :hideLabel="!isMobile"
                              :disabled="!enableForm"
                              v-model="slotProps.data.result"
                              :options="virologyResultsCombined"
                            />
                          </template>
                        </Column>
                        <Column field="comments">
                          <template #header>
                            {{ $t('result_comments') }}
                            <span class="asterisk" v-if="requiredMarkerArray['result_comments']"><i>&nbsp;*</i></span>                            
                          </template>
                          <template #body="slotProps">
                            <text-input
                              ruleKey="recipient_virology.results.comments"
                              :inputId="`serology.markers.${slotProps.data.id}.comments`"
                              :name="$t('result_comments')"
                              :hideLabel="!isMobile"
                              :disabled="!enableForm"
                              v-model="slotProps.data.comments"
                            />
                          </template>
                        </Column>
                        <Column field="date">
                          <template #header>
                            {{ $t('result_date') }}
                            <span class="asterisk" v-if="requiredMarkerArray['result_date']"><i>&nbsp;*</i></span>                            
                          </template>
                          <template #body="slotProps">
                            <date-input
                              ruleKey="recipient_virology.results.result_received_datetime"
                              :inputId="`serology.markers.${slotProps.data.id}.date`"
                              :name="$t('result_date')"
                              :hideLabel="!isMobile"
                              :disabled="!enableForm"
                              v-model="slotProps.data.date"
                            />
                          </template>
                        </Column>
                        <Column field="time">
                          <template #header>
                            {{ currentConfiguration.replaceTimezoneAbbreviation($t('result_time')) }}
                            <span class="asterisk" v-if="requiredMarkerArray['result_time']"><i>&nbsp;*</i></span>                            
                          </template>
                          <template #body="slotProps">
                            <time-input
                              ruleKey="recipient_virology.results.result_received_datetime"
                              :inputId="`serology.markers.${slotProps.data.id}.time`"
                              :name="currentConfiguration.replaceTimezoneAbbreviation($t('result_time'))"
                              :hideLabel="!isMobile"
                              :disabled="!enableForm"
                              v-model="slotProps.data.time"
                            />
                          </template>
                        </Column>
                        <Column field="apiSource" header="Actions">
                          <template #body="slotProps">
                            <button
                              v-if="enableForm"
                              ref="markerRemove"
                              type="button"
                              class="btn btn-sm btn-danger-outline removebutton"
                              @click="markerRemove(slotProps.data.id)">
                              <slot name="label">
                                {{ $t('remove') }}
                              </slot>
                            </button>
                          </template>                    
                        </Column>
                        <template #footer>
                          <button
                            v-if="enableForm"
                            ref="markerAdd"
                            type="button"
                            class="btn btn-sm btn-primary-outline"
                            @click="markerAdd">
                            <slot name="label">
                              {{ $t('add') }}
                            </slot>
                          </button>
                        </template>
                        <template #empty>
                          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                            {{ $t('guiding_text') }}
                        </template>
                      </DataTable>

                      <error-message 
                        inputId="marker_results_error"/>
                    </div>
                  </template>
                </sub-section>
              </template>

              <template v-slot:save>
                <save-toolbar
                  :show="showSaveToolbar"
                  ref="saveSerology"
                  class="card-footer action-row temp-saving row"
                  :label="$t('save_result')"
                  :cancelButton="true"
                  @save="handleSave()"
                  @cancel="handleCancel()"
                />
              </template>      

            </form-layout>
          </validation-observer>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">

import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveResult, APIPermittedActions, APIResponseWithPermittedActions } from "@/types";
import { Component, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { EP } from '@/api-endpoints';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { UIRecipient } from '@/UIModels/recipient';
import { UIConfiguration } from '@/UIModels/configuration';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UISerology, UISerologyMarker } from "@/UIModels/recipients/serology";
import LoadingFields from '@/components/shared/LoadingFields.vue';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import VerticalTableList from '@/components/shared/VerticalTableList.vue';
import { UIError } from '@/UIModels/error';
import DateInput from "@/components/shared/DateInput.vue";
import TimeInput from "@/components/shared/TimeInput.vue";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import AutoCompleteInput from "@/components/shared/AutoCompleteInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import ErrorMessage from '@/components/shared/ErrorMessage.vue';
import { VirologyCode, VirologyCodeValue } from '@/store/lookups/types';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { Rules } from '@/store/validations/types';

interface SerologyRow {
  id: string|null;
}

@Component({
  components: {
    CardSection,
    SubSection,
    FormLayout,
    VerticalTableList,
    TableToolbar,
    SaveToolbar,
    LoadingFields,
    DateInput,
    TimeInput,
    TextAreaInput,
    AutoCompleteInput,
    SelectInput,
    TextInput,
    ErrorMessage,
    DataTable,
    Column
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/RecipientSerology.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class RecipientSerology extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.virology_codes_combined) private virologyCodesCombined!: VirologyCode[];
  @State(state => state.lookups.virology_results_combined) private virologyResultsCombined!: VirologyCode[];  

  @Getter('clientId', { namespace: 'recipients' }) clientId!: string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string, lookupId: string) => any;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;
  
  private isMobile = false;
  private contractSerology = true;
  private navigationButton = 'left';
  private scrollBarVisible = false;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['virology_codes_combined', 'virology_results_combined'];

  // Lookup tables to be loaded by the CardSection component
  private isLoading = true;

  // Immutable view model based on current recipient (e.g for cancel)
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = new UISerology();
  private permittedActions: string[] = [];

  private requiredMarkerArray: any = {};

  private showAsterisk(ruleKey: string): any {
    const rules = this.getRules(this.ruleSet, ruleKey, '');
    return rules.includes('required');
  }

  private setScrollValue(value: string) {
    this.navigationButton = value;
  }

  private generateRequiredMarkerArray(): any {
    return {
      'test': this.showAsterisk('recipient_virology.results.code'),
      'result': this.showAsterisk('recipient_virology.results.result'),
      'result_comments': this.showAsterisk('recipient_virology.results.comments'),
      'result_date': this.showAsterisk('recipient_virology.results.result_received_datetime'),
      'result_time': this.showAsterisk('recipient_virology.results.result_received_datetime')
    };
  }

  // Returns all test codes chosen in markers
  private get getChosenTestMarkers(): string[] {
    const chosenTestsMarkers: string[] = [];
    this.editState.markers.filter((item: UISerologyMarker) => {
      if (item.test) {
        chosenTestsMarkers.push(item.test);
      }
    });
    return chosenTestsMarkers;
  }

  public scrollLeft() {
    const content = document.querySelector(".d-vertical-table");
    if (content) {
      content.scrollLeft -= 115;
      if (content.scrollLeft + content.clientWidth < content.scrollWidth) {
        this.navigationButton = 'none';
      } 
      if (content.scrollLeft == 0) {
        this.navigationButton = 'left';
      }
    }
  } 

  public checkScrollWidth() {
    const content = document.querySelector(".horizontal-view-wrapper");
    if (content) {
      this.scrollBarVisible = (this.patientSerologyColumns.length - 1) * 114 + (this.contractSerology ? 167 : 300) > content.clientWidth;
    }
  }

  public scrollRight() {
    const content = document.querySelector(".d-vertical-table");
    if (content) {
      content.scrollLeft += 115;
      if (content.scrollLeft == 115) {
        this.navigationButton = 'none';
      } else if (Math.ceil(content.scrollLeft + content.clientWidth + 20) == content.scrollWidth) {
        this.navigationButton = 'right';
      }
    }
  }

  // Returns virology code lookup, with any already chosen set to disabled
  // so the user cannot select the same virology code twice
  private get getFilteredVirologyCodesCombined(): VirologyCode[] {
    const chosenTestMarkers = this.getChosenTestMarkers;
    const filtered = this.virologyCodesCombined.map((item: VirologyCode) => {
      const newItem = item;
      newItem.disabled = chosenTestMarkers.includes(item.code as string) ? true : false;
      return newItem;
    });
    return filtered;
  }

  public checkwidth() {
    this.checkScrollWidth();
    if (window.innerWidth < 600) {
       this.contractSerology = window.innerWidth < 600;
       this.isMobile = window.innerWidth < 600;
    }
    else if (window.innerWidth < 960) {
      this.isMobile = window.innerWidth < 960;
      this.contractSerology = window.innerWidth < 960;
    } else {
      this.isMobile = false;
      this.contractSerology = false;
    }
  }

  // Select an address UI Model List item from the list based on a col click event
  private handleTableColClick(event: { id: string|null }): void {
    const uiSerology: UISerology[] = this.uiRecipient?.serologyResults || [];
    const listItem = uiSerology.find((listItem: UISerology) => { return listItem.id == event.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.prepareEditEntry();
    this.resetSaveToolbar();
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Load #edit rules
  private queryEditRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.serology.show,
      payload: [[':recipient_id', this.currentRecipient.clientId], [':id', this.selection.id]],
      prefix: 'recipient_virology',
    });
  }

  // Load #new rules
  private queryNewRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.serology.index,
      payload: [[':recipient_id', this.currentRecipient.clientId]],
      prefix: 'recipient_virology',
    });
  }

  private async prepareNewEntry(): Promise<void> {
    this.resetFormInputs();
    await this.queryNewRules();
    this.editState = new UISerology();
    this.permittedActions = [];
    this.resetFormErrors();
    this.markerAdd();
  }

  // Select a Serology UI Model List item from the list based on a row click event
  private async prepareEditEntry(): Promise<void> {
    try {
      await this.queryEditRules();
      const uiSerology: UISerology[] = this.uiRecipient?.serologyResults || [];
      const listItem = uiSerology.find((listItem: UISerology) => { return listItem.id == this.selection.id; });
      if (!listItem) return;

      // clear editState first (dataTable bug)
      this.editState = new UISerology();

      // delay added to deal with a ghosting issue in the PrimeVue DataTable where if the table is re-populated too fast the first row will not be rendered
      setTimeout(() => {
        this.editState = listItem.copyViewModel();
      }, 100);
      this.permittedActions = this.editState.permitted_actions;
    } catch(err) {
      console.warn(err, 'Something unexpected happened when attempting to load serology details');
    }
    this.resetFormErrors();
  }

  private translateResultCode(value: string|null): VirologyCode|null {
    if (!value) return null;
    const found = this.virologyResultsCombined.find((item: VirologyCode) => {
      return item.code === value;
    });
    return found ? found : null;
  }

  // Map from UI Serology view models from current page pinia store UI Recipient view model to row interface
  get patientSerologyRows(): SerologyRow[] {
    if (!this.uiRecipient) return [];
    if (!this.virologyCodesCombined) return [];

    const serologyResults: UISerology[] = this.uiRecipient.serologyResults || [];
    const serologyCodesUsed: any[] = this.uiRecipient.serologyCodesUsed || [];

    return serologyCodesUsed.map((virologyCode: VirologyCode) => {
      const row: any = {
        code: virologyCode.value
      };
      serologyResults.map((record: UISerology) => {
        const found = record.markers.find((item: UISerologyMarker) => { return item.test === virologyCode.code; });
        if (record.id && found) {
          const translateResult = this.translateResultCode(found.result);
        row[record.id] = translateResult ? translateResult.value : null;
          row[`${record.id}_virology_result_code`] = translateResult ? translateResult.virology_result_class : null;
          row[`${record.id}_comments`] = found.comments;
        }
      });
      return row;
    });
  }

  get patientSerologyColumns(): any {
    const uiSerology: UISerology[] = this.uiRecipient.serologyResults || [];

    // now we know what virus's we have data for. let's build the header
    const placeholder = this.currentConfiguration.replaceTimezoneAbbreviation(this.$t('collection_date_and_time').toString());
    const columns: any = [{
      label: uiSerology.length > 0 ? placeholder : '',
      field: "code",
      sortable: false,
      frozen: true,
      width: this.contractSerology ? '167px' : '300px',
    }];

    if (uiSerology.length === 0) return columns;

    // build an array of all the virology codes we have results for.
    const columnsToAdd = uiSerology.map((item: UISerology) => {
      columns.push({
        date: this.parseDisplayDateUiFromDateTime(item.collection_date),
        time: item.collection_time,
        field: item.id,
        collection_comments: item.collection_comments,
        sortable: false,
        marker: true,
        width: '115px',
      });
    });

    return columns;
  }

  get hasMultipleSerologyRecords(): boolean {
    return this.uiRecipient.serologyResults.length > 1;
  }

  // Configure the list table
  get patientSerologyTableConfig(): TableConfig {
    return {
      // if no records to show, don't show any viruses
      data: this.patientSerologyColumns.length > 1 ? this.patientSerologyRows : [],
      columns: this.patientSerologyColumns,
      empty: this.enableForm ? this.$t('new_result_text').toString() : this.$t('no_results_text').toString(),
      sortOptions: {
        enabled: false,
        initialSortBy: [{ field: 'date', type: 'desc' }]
      },
      pagination: false,
    };
  }

  // How many serology items does this Patient have based on the current filter assignments?
  // NOTE: make sure to do filtering before counting records
  get totalRecords(): number {
    if (!this.patientSerologyRows) return 0;

    return this.patientSerologyRows.length;
  }

  get uiRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  get patientSerologyTable(): VerticalTableList {
    return this.$refs.patientSerologyTable as VerticalTableList;
  }

  private handleTableCreateRow(): void {
    this.patientSerologyTable.resetSelection();
    this.selection = new UIListFormSelection();
    this.prepareNewEntry();
    this.resetSaveToolbar();
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    this.resetFormErrors();
    this.editState.removeBlankMarkers();

    // reset table refs
    await this.resetDataTableRefs(this.editState.markers);

    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams = { id: this.selection.id, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
      this.resetFormInputs();
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
   
  }

  // Clear selection
  private async handleSuccess(success: SaveResult): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    try {
      this.patientSerologyTable.resetSelection();
      this.selection = new UIListFormSelection();
      this.prepareNewEntry();
      this.isLoading = true;
      await this.currentRecipient.loadSerologyResults();
      this.isLoading = false;
    } catch(err) {
      console.warn(err, 'Something unexpected happen when attempting to load serology details');
    }
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  public resetFormInputs(): void {
    this.editState.markers = [];
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    // Re-initialize form
    if (this.selection.isNew) {
      this.prepareNewEntry();
    } else {
      this.prepareEditEntry();
    }
    this.resetSaveToolbar();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveSerology as unknown as SaveToolbar;
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;

    this.checkwidth();
    window.addEventListener('resize', this.checkwidth);

    if (!uiRecipient) return;

    try {
      await this.queryNewRules();
      await uiRecipient.loadSerologyResults();
      this.prepareNewEntry();
      this.isLoading = false;
      this.checkScrollWidth();
      // pre-generate an array we can use to tell if a marker header should show *, as datatable sometimes fails to access getters on render
      this.requiredMarkerArray = this.generateRequiredMarkerArray();
    } catch(err) {
      this.isLoading = false;
      console.warn('Something unexpected happen when attempting to get serology information', err);
    }
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recipientDeath#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'serology');
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const markers = this.editState.markers || [];

    const mapping: IdLookup = {
      'patient_serology.sample_comment': 'collection_comments',
      'patient_serology.sample_datetime': ['collection_date', 'collection_time'],
      'patient_serology.results': 'marker_results_error',
    };

    // sometimes get the id as .0. or [0].
    markers.map((item: UISerologyMarker, index: number) => {
      mapping[`patient_serology.results.${index}.code`] = `serology.markers.${item.id}.test`;
      mapping[`patient_serology.results.${index}.result`] = `serology.markers.${item.id}.result`;
      mapping[`patient_serology.results.${index}.comment`] = `serology.markers.${item.id}.comments`;
      mapping[`patient_serology.results.${index}.result_received_datetime`] = [`serology.markers.${item.id}.date`, `serology.markers.${item.id}.time`];

      mapping[`patient_serology.results[${index}].code`] = `serology.markers.${item.id}.test`;
      mapping[`patient_serology.results[${index}].result`] = `serology.markers.${item.id}.result`;
      mapping[`patient_serology.results[${index}].comment`] = `serology.markers.${item.id}.comments`;
      mapping[`patient_serology.results[${index}].result_received_datetime`] = [`serology.markers.${item.id}.date`, `serology.markers.${item.id}.time`];
    });

    return mapping;
  }

  public resetEmptyMarkersError(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetField('marker_results_error');
  }

  private markerAdd() {
    const newMarker = new UISerologyMarker();
    // append a new marker to the array
    this.editState.markers.push(newMarker);
    if (this.editState.markers.length === 1) {
      this.resetEmptyMarkersError();
    }
  }

  private resetDataTableRefs(markers: any): Promise<void> {
    return new Promise<void>((resolve) => {
      // empty markers to force datatable to drop all internal ref's
      this.editState.markers = [];

      setTimeout(() => {
        // set markers with new order so datatable re-generate's ref's
        this.editState.markers = markers;
        resolve();
      }, 100);
    });
  }

  private async markerRemove(id: string): Promise<void> {
    // filter out specific marker
    const filteredMarkers = this.editState.markers.filter((marker: UISerologyMarker) => {
      return marker.id !== id;
    });

    this.resetDataTableRefs(filteredMarkers);
  }
}
</script>

