<template>
  <div>
       <proto-save-toolbar-top class="save-toolbar action-row mb-0 temp-saving d-flex" showCancelCreateClass="btn btn-sm btn-wide mb-3 btn-secondary ml-auto"
       :cancelCreateText='isNew ? "Cancel Create New Item" : "Close Item Details"' showCancelCreateButton="true" @cancel="cancelItem" />
       <hr/>
    <fieldset>
      <div class="row">
        <div class="standard-form-group">
          <text-input input-id="item-name" name="Item Name" v-model="name" rules="required" />
        </div>
        <div class="standard-form-group standard-form-group-checkbox">
          <checkbox-input name="external" labelName="External" inputId="external" v-model="external" label='Yes' />
        </div>
        <div class="standard-form-group col-12 col-md-8 col-lg-3 ">
          <select-input select-id="checklistGroup" aria-label="Item Status" undefined-text="Select"
            :options="statusOptions" name="Item Status" v-model="status" @change="handleStatusChange">
          </select-input>
        </div>
      </div>
    </fieldset>
    <save-toolbar class="save-toolbar action-row mb-0 temp-saving d-md-flex" buttonClass="btn btn-success sec-submit"
      label="Save Item Details" :showSaveAndCreate="isNew"  :saveAndCreateText="`Save & Create New Item`" 
      showCancelButton="true" cancelButtonText="Cancel"  @cancel="clearForm" @saveAndCreate="saveAndCreate"
      @save="saveNewItem"
      :show="true" :cancelButton="true"
      ref="protoTaskForm"
    />
  </div>
</template>

<script lang="ts">
  import {
    Getter,
    State
  } from 'vuex-facing-decorator';
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import ProtoSaveToolbarTop from '@/prototypes/shared/ProtoSaveToolbarTop.vue';
  @Component({
    components: {
      TextInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      ProtoSaveToolbarTop
    },
    emits: [
      'cancel',
      'save',
      'update',
    ],
  })
  export default class ProtoTaskForm extends Vue {

    @Prop({
      default: null
    }) statusOptions!: any;

        @Prop({
      default: null
    }) id!: any;


    @Prop({
      default: null
    }) taskOptions!: any;


    @Prop({
      default: null
    }) selectedItems!: any;

    @Prop({
      default: null
    }) emptyForm!: any;


    @Prop({
      default: null
    }) isNew!: any;

    // // watch
    // @Watch("emptyForm", {
    //   immediate: true,
    //   deep: true
    // })

    @Getter('getUser', {
      namespace: 'users'
    }) private getUser!: any;

    private onClearForm(): void {
      console.log('onclearform');
      this.clearForm();
    }

    @Watch('selectedItems', {
      immediate: true,
      deep: true
    })
      private onSelectedItem(): void {
      this.editSelected();
    }

    get getUserFullName(): string | undefined {
      let user = this.getUser;
      return user.name;
    }


    private handleStatusChange(): void {
      if (this.status == '5' ) {
        this.completedDate =  Date.now();
        this.completedBy = this.getUserFullName;
      }
    }

    private performArchive(): void {
      console.log('archive');
    }

 
    private idd = '';
    private name = '';
    private external = '';
    private selectedGroup = '';
    private status = '';
    private completedBy: any = '';
    private completedDate: any = '';

    private editSelected() {
      const row = this.selectedItems.find((item: any) => {
        return item.id == this.id;
      });
      if (row) {
        this.idd = row.id;
        this.name = row.name;
        this.external = row.external;
        this.selectedGroup = row.list_id;
        this.status = row.status;
      }
    }

    private cancelItem() {
      this.$emit("cancel", this.id);
    }

    // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.protoTaskForm as SaveToolbar;
    }

    private saveNewItem() {
      if (!this.name) {
        return undefined;
      }
      const selectedItem = this.selectedItems.find((item: any) => {
        return item.id == this.id;
      });

      let payload = selectedItem;
      payload.list_id = this.selectedGroup;
      payload.name = this.name;
      payload.status = this.status;
      payload.completed_by = this.completedBy;
      payload.completed_date = this.completedDate;

      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      if (this.isNew) {
        this.$emit("save", payload);
      } else {
         this.$emit("update", payload);
      }

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    }

    private saveAndCreate() {
      if (!this.name) {
        return undefined;
      }
      const selectedItem = this.selectedItems.find((item: any) => {
        return item.id == this.id;
      });

      let payload = selectedItem;
      payload.list_id = this.selectedGroup;
      payload.name = this.name;
      payload.status = this.status;
      this.$emit("save", payload, 'create');
    }


    public clearForm(): any {
      if (this.isNew) {
        this.name = '';
        this.external = '';
        this.status = '0';
      } else {
        this.editSelected();
      }
  
    }


  }

</script>

<style>


</style>
