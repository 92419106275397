<template>
  <!-- blood type -->
  <sub-section
    :title="$t('blood_type')"
    sub-section-id="gci_blood_type"
    ref="blood_type">
    <template v-slot:contents>
      <validation-observer ref="validations">
      <form-layout
        :disabled="!canSave"
        form-id="blood_type_form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <select-input
                ruleKey="diagnostics.blood.type"
                selectId="gci-blood.type"
                :name="$t('blood_type')"
                v-model="editState.type"
                :options="bloodTypeLookup"
              />
            </div>
            <div class="standard-form-group">
              <select-input
                ruleKey="diagnostics.blood.rh_indicator"
                selectId="gci-blood.rh"
                :name="$t('rh_factor')"
                v-model="editState.rh_indicator"
                :options="rhIndicatorLookup"
              />
            </div>
          </div>
        </template>

        <template v-slot:save>
          <save-toolbar
            :show="canSave"
            ref="saveBloodType"
            class="card-footer action-row temp-saving row"
            :label="$t('save_blood_type')"
            :cancelButton="true"
            @save="handleSave()"
            @cancel="handleCancel()"
          />
        </template>      
      </form-layout>      
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import HiddenInput from '@/components/shared/HiddenInput.vue';
import { SaveResult } from "@/types";
import { Component, Prop } from 'vue-facing-decorator';
import { BloodType, RhIndicator } from '@/store/lookups/types';
import { RecipientValidations } from '@/store/recipients/types';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import { IdLookupProvider } from '@/types';
import { UIBloodType } from '@/UIModels/recipients/bloodType';
import { UIRecipient } from '@/UIModels/recipient';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    NumberInput,
    CheckboxInput,
    HiddenInput,
    FormLayout
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/GeneralClinicalInformation.json'),
    require('@/components/_locales/common.json'),
  ]),
})
export default class BloodTypeForm extends mixins(DateUtilsMixin) implements IdLookupProvider {
  // State
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  @Getter('blood_type', { namespace: 'lookups' }) private bloodTypeLookup!: BloodType;
  @Getter('rh_indicator', { namespace: 'lookups' }) private rhIndicatorLookup!: RhIndicator;

  private isLoading = false;

  // Immutable view model based on current recipient (e.g for cancel)
  private selection = UIBloodType.buildNew();

  // Editable view model for the form
  private editState = this.selection.copyViewModel();

  // Lookup tables to be loaded by the CardSection component

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  mounted(): void {
    if (this.currentRecipient) {
      this.currentRecipient.load().then(() => {
        const uiBlood = this.currentRecipient.bloodType;
        if (uiBlood) this.selection = uiBlood.copyViewModel();
        this.initializeForm();
        this.isLoading = false;
      }).catch((uiError: UIError) => {
        this.isLoading = false;
        console.warn('Something unexpected happened when attempting to get blood type information', uiError);
      });
    }
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();

    const currentPageStore = useCurrentPageStore();
    const uiRecipient = currentPageStore.currentRecipient;
    if (!uiRecipient) return;

    const params = {
      selected: this.editState,
      recipient: this.currentRecipient,
    };

    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    // re-load data
    const uiBlood = this.currentRecipient.bloodType || null;
    if (uiBlood) this.selection = uiBlood.copyViewModel();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset edit state based on selected address for cancel button click event
  private handleCancel(): void {
    this.initializeForm();
    this.resetSaveToolbar();
  }

  /**
   * Populates the Recipient Blood Type form state with data from the selected Recipient.
   */
   public initializeForm(): void {
    this.editState = this.selection.copyViewModel();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    this.saveToolbar.reset();
  }
  
  /**
   * Populates the Recipient Blood Type form state with data from the current Recipient.
   */
  public copyViewModel(): void {
    this.editState = this.selection.copyViewModel();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveBloodType as unknown as SaveToolbar;
  }
 
  public idLookup(): {[key: string]: string} {
    const result = {
      'diagnostics.blood.type'                     : 'gci-blood.type',
      'diagnostics.blood.rh_indicator'             : 'gci-blood.rh',
    };
    return result;
  }
}
</script>
