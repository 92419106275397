<template>
  <validation-observer class="card-body card-options p-0 collapse" :id="id" ref="validations">
    <div class="customize-panel">
      <h4>{{$t('panel_options')}}</h4>
      <form>
        <div class="form-row mb-3">
          <div class="col-sm-6">
            <select-input
              name="panel-style"
              :select-id="`${id}-style`"
              v-model="editState.style"
              :label="$t('panel_style')"
              :options="getPanelStyles"
              rules="required"
              />
          </div>
        </div>
        <div class="sub-divider"></div>
        <div class="form-row mb-3">
          <div class="col-sm-12 col-lg-8">
            <checkbox-input
              :input-id="`${id}-visible`"
              v-model="editState.visible"
              :label="$t('show_this_panel')"
              :disabled="editState.required"
            />
          </div>
        </div>
        <div class="sub-divider"></div>
        <!-- Save toolbar -->
        <save-toolbar
          :show="true"
          :ref="`${id}-saveWidgetOptions`"
          :label="$t('save_panel_options')"
          :cancelButton="true"
          buttonClass="btn btn-sm btn-wide btn-success"
          cancelButtonClass="btn btn-sm btn-wide btn-secondary ml-auto"
          @save="onSave"
          @cancel="onCancel"
        />
      </form>
    </div>
  </validation-observer>
</template>

<script lang="ts">
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { STYLE_OPTIONS, UIBaseWidget } from '@/UIModels/dashboard/baseWidget';
import { i18nMessages } from '@/i18n';
import { GenericCodeValue } from '@/store/types';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { SaveResult } from '@/types';
import { Form as ValidationObserver } from 'vee-validate';

export interface WidgetOptionsModel {
  style: string;
  visible: boolean;
}

@Component({
  components: {
    SelectInput,
    CheckboxInput,
    SaveToolbar,
  },
  ...i18nMessages([
    require('@/components/dashboard/widgets/shared/_locales/WidgetOptions.json'),
  ]),
})
export default class WidgetOptions extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) widget!: UIBaseWidget;

  // Local component state is used to provide editable input values
  private editState = new UIBaseWidget();

  // Options for widget style
  get getPanelStyles(): GenericCodeValue[] {
    return STYLE_OPTIONS.map((option: GenericCodeValue): GenericCodeValue => { return { code: option.code, value: this.$t(option.value) }; });
  }

  // Reference to save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs[`${this.id}-saveWidgetOptions`];
    if (!saveToolbar) return null;
    return saveToolbar as SaveToolbar;
  }

  // Setup component when it is mounted
  mounted(): void {
    this.resetEditState();
  }

  // Reference to the form validations observer
  get validations(): typeof ValidationObserver {
    return this.$refs.validations as typeof ValidationObserver;
  }

  // Save dashboard panel widget options to user preferences
  public async onSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    if (this.validations) {
      const result = await this.validations.validate();
      if (!result.valid) {
        if (this.saveToolbar) this.saveToolbar.stopSaving(result as SaveResult);
        return;
      }
    }
    try {
      const saveParams = { selected: this.widget };
      const result = await this.editState.saveWidgetOptions(saveParams);
      if (this.saveToolbar) this.saveToolbar.stopSaving(result);
    } catch (error: unknown) {
      console.warn(error);
      if (this.saveToolbar) this.saveToolbar.stopSaving(error as SaveResult);
    }
  }

  // Reset form edit state
  private onCancel(): void {
    this.resetEditState();
    this.resetSaveToolbar();
  }

  // Initialize form edit
  private resetEditState(): void {
    this.editState = this.widget.copyViewModel();
  }

  // Dismiss save toolbar success or error indicator
  private resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Handle change to underlying widget, e.g. hide/show from dashboard-level settings change
  @Watch('widget', { immediate: true, deep: true })
  private handleWidgetChange(_newWidget: UIBaseWidget, oldWidget?: UIBaseWidget): void {
    if (!oldWidget) return;

    this.resetEditState();
  }
}
</script>
