import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ffe948aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-links" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: `#${_ctx.optionsId}`,
      class: "nav-link card-header-btn py-0",
      "data-toggle": "collapse"
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['far', 'cog'],
        "data-toggle": "tooltip",
        title: _ctx.$t('customize_panel')
      }, null, 8, ["title"])
    ], 8, _hoisted_2),
    _createElementVNode("a", {
      href: `#${_ctx.sectionId}`,
      class: "nav-link card-header-btn py-0",
      "data-toggle": "collapse"
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['far', 'caret-down'],
        "data-toggle": "tooltip",
        title: _ctx.$t('collapse_panel')
      }, null, 8, ["title"])
    ], 8, _hoisted_3)
  ]))
}