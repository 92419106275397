import { APILungSpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UILungSpecificsConfig {
  public enabled: boolean;
  public thoracicMeasurements: UIFeatureConfig;

  public constructor(apiSource: APILungSpecificDetailsConfig) {
    this.enabled = apiSource.enabled;
    this.thoracicMeasurements = new UIFeatureConfig(apiSource.thoracic_measurements);
  }
}
