<template>
  <validation-observer ref="validations">
  <form-layout
    :disabled="!enableForm"
    form-id="patient-contact-detail-form"
  >
    <template v-slot:title>
      <!-- Mode indicator / subsection form title (H5) -->
      <legend>
        <h5 class="legend-title">
          {{ selection.isNew ? $t('new_patient_contact_detail_heading') : $t('selected_patient_contact_detail_heading') }}
        </h5>
      </legend>
    </template>
    <template v-slot:action>
      <!-- Action Toolbar -->
      <action-toolbar
        ref="contactDetailActionToolbar"
        @archive="handleArchive"
        @restore="handleRestore"
        :permittedActions="editState.permittedActions"
        :isArchiving="isArchiving"
        :isRestoring="isRestoring"
        :loading="isLoadingActions"
      />
    </template>
    <template v-slot:contents>
      <!-- Data Entry Form (Add, View, and Edit modes) -->
      <loading-field v-if="isLoadingForm" />
      <div class="row" v-else>
        <div class="standard-form-group">
          <select-input
            ruleKey="patient_contact_detail.type"
            select-id="contact-detail-category"
            :name="$t('contact_detail_field.category')"
            :options="categoryOptions"
            v-model="editState.categoryType"
            @change="handleCategoryChanged"
          />
        </div>
      </div>

      <!-- start of Format details -->
      <loading-fields v-if="editState.categoryType && isLoadingSubForm" />
      <template v-else-if="editState.categoryType">
        <component
          ref="contactDetailFormatComponent"
          v-if="contactDetailFormatComponentName"
          v-bind:is="contactDetailFormatComponentName"
          :editState="editState"
        />
        <!-- Fallback -->
        <template v-else>
          <p class="mt-2">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ $t('contact_detail_error.missing_format_configuration', { formatCode: selectedCategoryFormatCode }) }}
          </p>
        </template>
      </template>
      <!-- end of Format details -->
    </template>
    <template v-slot:save>
      <save-toolbar
        :show="showSaveToolbar"
        :disabled="!enableSaveToolbar"
        ref="savePatientContactDetail"
        :label="$t('save_patient_contact_detail')"
        :cancelButton="true"
        @save="handleSave"
        @cancel="handleCancel"
        />
    </template>
  </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { GenericCodeValue } from '@/store/types';
import { IdLookup } from '@/store/validations/types';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { Component, Watch, Prop } from 'vue-facing-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import { ContactDetailCategoryLookup } from '@/store/lookups/types';
import { SaveResult, APIResponseWithPermittedActions } from '@/types';
import PatientPhoneFormat from '@/components/recipients/contactInformation/patientContactDetails/PatientPhoneFormat.vue';
import PatientEmailFormat from '@/components/recipients/contactInformation/patientContactDetails/PatientEmailFormat.vue';
import LoadingFields from '@/components/shared/LoadingFields.vue';
import LoadingField from '@/components/shared/LoadingField.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { IdLookupProvider, RulesQuery } from '@/types';
import { UIRecipient } from '@/UIModels/recipient';
import { EP } from '@/api-endpoints';
import { UIContactDetail } from '@/UIModels/contactDetail';
import { i18nMessages } from '@/i18n';
import { parseFormErrors } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';

const contactDetailFormatComponentMapping: { [key: string]: string } = {
  'phone_number': 'PatientPhoneFormat',
  'email': 'PatientEmailFormat',
};

@Component({
  components: {
    FormLayout,
    ActionToolbar,
    SaveToolbar,
    LoadingFields,
    LoadingField,
    SelectInput,
    PatientPhoneFormat,
    PatientEmailFormat,
  },
  ...i18nMessages([
    require('@/components/recipients/contactInformation/_locales/PatientContactDetails.json'),
  ]),
  emits: [
    'success',
  ],
})
export default class ContactDetailForm extends mixins(DateUtilsMixin) implements IdLookupProvider {
  @State(state => state.lookups.contact_detail_categories) contactDetailCategories!: ContactDetailCategoryLookup[];

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;

  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Local component state is used to provide editable input values
  // NOTE: this local instance of the view model CAN be used for v-model in form template
  private editState = new UIContactDetail();

  // Here we are tracking prior System Format to detect change to System Format
  private previousCategoryFormatCode: string|null = null;

  private isLoadingActions = false;
  private isLoadingForm = false;
  private isLoadingSubForm = false;

  private isArchiving = false;
  private isRestoring = false;
  private isSuccessfullySaving = false;

  // Can we enable the form?
  get enableForm(): boolean {
    if (this.newRecipient) return false;
    return this.canSave && this.editState.canEdit;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    if (this.newRecipient) return false;
    return this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.editState.canEdit;
  }

  // What contact detail categories can be selected?
  get categoryOptions(): GenericCodeValue[] {
    return this.contactDetailCategories;
  }

  // Which system format is associated with the currently selected "Category"?
  get selectedCategoryFormatCode(): string|null {
   if (!this.editState) return null;

    return this.contactDetailCategoryFormat(this.editState.categoryType);
  }

  // Which form component do we need to use, based on system format associated with selected "Category"?
  get contactDetailFormatComponentName(): string|null {
    const formatCode = this.selectedCategoryFormatCode;
    if (!formatCode) return null;

    const componentName = contactDetailFormatComponentMapping[formatCode];
    if (!componentName) return null;

    return componentName;
  }

  // Reference to Patient Contact Detail toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.savePatientContactDetail;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  // Reference to Contact Detail Form's Action Toolbar
  get actionToolbar(): ActionToolbar|null {
    const actionToolbar = this.$refs.contactDetailsActionToolbar;
    if (!actionToolbar) return null;

    return actionToolbar as ActionToolbar;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // What is the default category lookup entry?
  get defaultContactDetailCategory(): ContactDetailCategoryLookup|null {
    return (this.contactDetailCategories || []).find((contactDetailCategory: ContactDetailCategoryLookup) => { return contactDetailCategory.default; }) || null;
  }

  // What is the lookup-based default value for "Category" field?
  get defaultCategoryType(): string|null {
    return this.defaultContactDetailCategory?.code || null;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};
    if (this.defaultCategoryType) params['type'] = this.defaultCategoryType;
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    const selectedCategoryType = this.editState.categoryType;
    if (selectedCategoryType) params['type'] = selectedCategoryType;
    return params;
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;
    this.isLoadingSubForm = true;
    if (this.selection.id) {
      this.isLoadingActions = true;
    } else {
      this.editState.permittedActions = [];
    }

    // Make an edit state by loading the selected list item if it exists
    // NOTE: 'load' will initialize field-level validaiton rules, so here we pass contract 'query'
    const newEditState = new UIContactDetail();
    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;
    await newEditState.load({ recipientId: this.currentRecipient.clientId as string, id: this.selection.id as string, query });

    if (newEditState.isNew) {
      // When initializing a form edit state with no underlying selected model, set defaults!
      Object.assign(newEditState, {
        categoryType: this.defaultCategoryType,
      });
    }
    this.editState = newEditState;

    // NOTE: we also need to keep track of 'previous' type and format to handle changes to type or format
    this.previousCategoryFormatCode = this.contactDetailCategoryFormat(newEditState.categoryType);

    this.isLoadingActions = false;
    this.isLoadingForm = false;
    this.isLoadingSubForm = false;
  }

  // Load validation rules with specified query parameters
  // NOTE: this can involve either #new or #edit rules, depending on the situation
  private queryRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.selection.isNew ? this.queryNewContactDetailRules(query) : this.queryRecipientEditContactDetailRules(query);
  }

  // Load #edit rules with specified query parameters
  private queryRecipientEditContactDetailRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.patient_profile.contactDetails.edit_validations,
      payload: [[':recipient_id', this.recipientId], [':id', this.selection.id]],
      prefix: 'patient_contact_detail',
      query,
    });
  }

  // Load #new rules with specified query parameters
  private queryNewContactDetailRules(query: RulesQuery): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.patient_profile.contactDetails.new_validations,
      payload: [[':recipient_id', this.recipientId]],
      prefix: 'patient_contact_detail',
      query,
    });
  }

  // Extract 'format_code' string from specified category lookup specified by the lookup entry code
  private contactDetailCategoryFormat(categoryType: string|null): string|null {
    if (!categoryType || !this.contactDetailCategories) return null;

    const categoryLookup = this.contactDetailCategories.find((categoryLookup: ContactDetailCategoryLookup) => {
      return categoryLookup.code === categoryType;
    });
    if (!categoryLookup) return null;

    return categoryLookup.format_code;
  }

  // Process change of "Category" dropdown, in scenario when new category has different system format
  // NOTE: here is where we reset entire form, to prevent copying data that might be invalid in new format
  private clearFormatMismatchFields(newFormat: string|null, _oldFormat: string|null): void {
    if (!this.editState) return;

    const newValues = {
      phoneNumber: null,
      okayTo: [],
      email: null,
    };
    if (!newFormat) {
      Object.assign(newValues, {
        preferred: null,
        comments: null,
      });
    }
    Object.assign(this.editState, newValues);
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams = { id: this.selection.id, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    this.isSuccessfullySaving = true;
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    this.$emit('success', success);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Process archive button click event
  private async handleArchive(): Promise<void> {
    this.isArchiving = true;
    this.resetSaveToolbar();
    const archiveParams = { id: this.selection.id, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.archive(archiveParams);
      this.isArchiving = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.resetEditState();
    } catch (error: unknown) {
      this.isArchiving = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.handleErrors(error as SaveResult);
    }
  }

  // Process restore button click event
  private async handleRestore(): Promise<void> {
    this.isRestoring = true;
    this.resetSaveToolbar();
    const restorePrams = { id: this.selection.id, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.restore(restorePrams);
      this.isRestoring = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.resetEditState();
    } catch (error: unknown) {
      this.isRestoring = false;
      if (this.actionToolbar) this.actionToolbar.resetToolbar();
      this.handleErrors(error as SaveResult);
    }
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Reset edit state based on selection for cancel button click event
  private handleCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Process change to "Category" dropdown in form
  private async handleCategoryChanged(_newCategoryType: string|null): Promise<void> {
    const oldFormat = this.previousCategoryFormatCode;
    const newFormat = this.selectedCategoryFormatCode;

    this.editState.formatCode = newFormat;

    if (oldFormat != newFormat) {
      this.isLoadingSubForm = true;
      this.clearFormatMismatchFields(newFormat, oldFormat);
    }

    this.previousCategoryFormatCode = newFormat;
    await this.queryRules(this.ruleQueryEditState);
    this.isLoadingSubForm = false;
  }

  // Process change to "selection" prop
  // NOTE: this will initialize New Contact Detail form, e.g. #new rules with query params based on lookup defaults
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(newSelection: UIListFormSelection, oldSelection?: UIListFormSelection): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }

  // Initialize edit state e.g. defaults
  private mounted(): void {
    this.resetEditState();
  }

  // Validation mapping
  public idLookup(): IdLookup {
    const result: IdLookup = {
      'patient_contact_detail.type': 'contact-detail-category',
    };
    const contactDetailFormatComponent = this.$refs.contactDetailFormatComponent as unknown as IdLookupProvider;
    if (contactDetailFormatComponent) {
      Object.assign(result, { ... contactDetailFormatComponent.idLookup() });
    }
    return result;
  }

  // Dismiss save toolbar success or error indicator
  private resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }
}
</script>
