<template>
  <div class="row skeleton-padding-text">
    <div class="standard-form-group">
      <span class="skeleton-box w-50" />
      <span class="skeleton-box w-100" style="height: 30px;" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class LoadingField extends Vue {}
</script>
