<template>
  <validation-provider
    ref="provider"
    :rules="formRules"
    :name="inputId"
    :label="name"
    :vid="validationId ? validationId : inputId"
    v-model="validatedValue"
    v-slot="{ field, errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!isDisabled"
        :rules="formRules"
        :crossValues="crossValues"
        :ruleKey="ruleKey"
      />
      <slot name="link" />
    </label>
    <!-- TODO: ensure that the Calculated indicator is accessible to screen readers -->
    <small v-if="calculated" class="form-text text-muted" :title="$t('calculated')">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
      {{calculatedText}}
    </small>
    <span class="mobile-spacing-wrapper">
      <template v-if="isMasked">
        <span v-if="isLoading" class="skeleton-box w-100" />
        <input
          v-else
          type="text"
          :class="{ 'is-invalid': !isDisabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
          :id="inputId"
          :disabled="true"
          :readonly="true"
          :value="modelValue"
        />
      </template>
      <template v-else-if="append">
        <div class="input-group mb-3">
          <span v-if="isLoading" class="skeleton-box w-100" />
          <input
            v-else
            :id="inputId"
            type="text"
            :class="{ 'is-invalid': !isDisabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
            v-bind="field"
            :readonly="isReadOnly(readonly||isDisabled)"
            v-on="maskedInputEvents()"
            :placeholder="getSettings.placeholder"
            :aria-label="getSettings.ariaLabel"
            :v-maska="getSettings.vMask"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{appendText}}</span>
          </div>
          <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ translateError(errors, label || name) }}
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <span v-if="isLoading" class="skeleton-box w-100" />
          <VueDatePicker
            ref="vueDatePicker"
            v-model="datePickerValue"
            :uid="inputId"
            :format="getSettings.format"
            auto-apply
            arrowNavigation
            class="vue-3-datepicker"
            :class="{ 'is-invalid': !isDisabled && errors[0]}"
            :placeholder="getSettings.placeholder"
            :disabled="isReadOnly(readonly||isDisabled)"
            :tabindex="isReadOnly(readonly||isDisabled) ? '0': '-1'"
            :config="{ 
              closeOnScroll: false,
              setDateOnMenuClose: false
            }"
            :enable-time-picker="false" 
            :text-input="true"
            :enterSubmit="true"
            :partial-range="false"
            :clearable="true"
            :locale="getSettings.locale"
            :v-maska="getSettings.vMask"
            @update:model-value="newDate"
            @keydown.enter="onKeyPress"
            @text-input="textInput"
            @cleared="onCleared"
            @blur="onBlur"
          />
          <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ translateError(errors, label || name) }}
          </div>
        </div>
      </template>
    </span>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Watch } from 'vue-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { isMasked } from '@/utils';
import { i18nMessages } from '@/i18n';
import { mixins } from "vue-facing-decorator";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { useCurrentPageStore } from '@/stores/currentPage';
import { Mask } from "maska";
import { UIConfiguration } from '@/UIModels/configuration';
import { UI_MODEL_DATETIME } from '@/utilities/date-utils';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { systemTimeZone } from '@/utilities/date-utils';

enum source {
  keyboard = 'keyboard',
  calendar = 'calendar',
}

@Component({
  components: {
    ValidationAsterisk
  },
  ...i18nMessages([
    require('@/components/shared/_locales/DateInput.json'),
  ]),
  emits: [
    'update:modelValue',
  ],
})
export default class DateInput extends mixins(TranslationUtilsMixin) {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: null }) minDateLimit!: any; // Minimum date limit
  @Prop({ default: null }) maxDateLimit!: any; // Maximum date limit
  @Prop({ default: null }) direction!: any; // direction
  @Prop({ default: false }) isLoading!: boolean;

  private validatedValue: any = null; // validated date we will pass thru to the parent
  private datePickerValue: any = null; // working value for vue 3 date picker (will be updated only when it's valid)
  private rawValue: string|null = null; // raw date picker value (from text input, may not be valid)

  get isDisabled(): boolean {
    let childDisabled = this.disabled; // get child's value
    if (!this.$parent) return childDisabled; // if no parent return disabled value

    // get parent's disabled value
    const parentDisabled = (this.$parent as any).disabled || null;

    // if parent has a disabled value but child doesn't opt for parent, otherwise use child
    return !childDisabled && parentDisabled ? parentDisabled : childDisabled;
  }

  // Apply validation rules
  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  get refDatePicker(): any {
    return this.$refs.vueDatePicker as any;
  }

  textInput(event: any): void {
    this.rawValue = event.target.value || null;
  }

  // used by parent component
  @Watch('modelValue')
  onModelValue(): void {
    if (this.modelValue) {
      this.validationProvider.reset({ value: this.modelValue });
      this.datePickerValue = this.buildDatePickerObject(this.modelValue);
      this.setValidatedDate(this.datePickerValue);
    } else {
      this.datePickerValue = null;
      this.setValidatedDate(this.datePickerValue);
    }
    this.validationProvider.reset({ value: this.modelValue });
  }

  mounted(): void {
    if (this.modelValue) {
      this.onModelValue();
    }
  }

  // used by calendar control
  newDate(value: any): any {
    this.datePickerValue = value;
    this.setValidatedDate(this.datePickerValue);
    this.emitValidatedDate();
  }

  // Returns true if valid date string
  isValidDateFormat(value: string): boolean {
    if (!value) return false;
    if (isNaN(Date.parse(value))) return false;
    if (value.length < 10) return false;
    return true;
  }

  // Returns true if valid date object
  isValidDateObject(object: any) {
    return object instanceof Date && !isNaN(object as any);
  }

  /**
   * @param date year-first date string
   * @returns {Date} sanitized Date for date picker
   */
  buildDatePickerObject(date: string): Date {
    const sanitized = formatInTimeZone(date, this.getTimeZoneCode, UI_MODEL_DATETIME);
    // create new date object against system timezone
    const zonedTime = toZonedTime(new Date(sanitized), systemTimeZone());
    return new Date(zonedTime);
  }

  // determines the source of the keyboard value
  determineSource(element: any): source|null {
    if (!element) return null;

    const div = element.target;
    // if value came from calendar control
    if (div.inputMode != 'text') return source.calendar;
    return source.keyboard; // value came from text input control
  }

  // used by text input control
  onKeyPress(element: any): any {
    if (this.determineSource(element) === source.calendar) {
      // do nothing, rely on calendar control to set the value
    }

    if (this.determineSource(element) === source.keyboard) {
      // otherwise if user enters from text input
      const enteredValue = element.target.value;
      if (enteredValue && enteredValue.length === 8) {
        const mask = new Mask({ mask: this.getSettings.vMask });
        const maskedString = mask.masked(enteredValue);
        const formattedMaskedString = this.getSettings.formatMask(maskedString);

        const newDate = this.buildDatePickerObject(formattedMaskedString);
        if (this.isValidDateObject(newDate)) {
          this.datePickerValue = newDate;
          this.setValidatedDate(this.datePickerValue);
          this.emitValidatedDate();
        }
      }

      if (!enteredValue) {
        this.datePickerValue = null;
        this.setValidatedDate(this.datePickerValue);
        this.emitValidatedDate();
      }
    }
  }

  onBlur(): void {
    // if blur when raw value is not valid and we have a previous 'good' datePickerValue use that one
    if (this.rawValue && this.rawValue.length < 10 && this.datePickerValue) {
      // if the user has chosen a value without pressing enter the value stored in
      // datePickerValue will be using the browser timezone so we'll need to alter that to our
      // system timezone before using it as valid
      if (typeof this.validatedValue === 'string' && this.isValidDateObject(this.datePickerValue)) {
        const zonedTime = toZonedTime(new Date(this.datePickerValue), systemTimeZone());
        this.setValidatedDate(zonedTime);
        this.emitValidatedDate();
      }
    } else {
      this.validationProvider.validate();
    }
  }

  onCleared(): void {
    this.$nextTick(() => {
      this.validationProvider.validate();
    });
  }

  setValidatedDate(dateObject: any) {
    if (dateObject) {
      const dateIso = dateObject.toISOString().substring(0, 10);
      this.validatedValue = dateIso;
      this.validationProvider.validate();
    } else {
      this.validatedValue = null;
    }
  }

  emitValidatedDate(): void {
    this.$emit('update:modelValue', this.validatedValue);
  }

  // masked input
  get isMasked(): boolean {
    if (!this.modelValue) return false;
    return isMasked(this.modelValue.toString());
  }

  get validationProvider() {
    return this.$refs.provider as any;
  }

  get getDefaultMinDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 40); // default current - 40 years
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getDefaultMaxDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // default current + 1 year
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getMinDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.minDateLimit ? this.minDateLimit : this.getDefaultMinDate;
  }

  get getMaxDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.maxDateLimit ? this.maxDateLimit : this.getDefaultMaxDate;
  }

  // returns timezone code (e.g. EST)
  get getTimeZoneCode(): string {
    return this.currentConfiguration.systemTimeZoneAbbreviation;
  }

  get currentConfiguration(): UIConfiguration {
    return useCurrentPageStore().configuration;
  }

  get getSettings(): any {
    switch (useCurrentPageStore().configuration.dateFormat) {
      case("en"):
        return {
          buttonLabel: this.$t('choose_date').toString(),
          placeholder: this.$t('date_format.en').toString(),
          selectedDateMessage: this.$t('selected_date_is').toString(),
          prevMonthLabel: this.$t('previous_month').toString(),
          nextMonthLabel: this.$t('next_month').toString(),
          monthSelectLabel: this.$t('month').toString(),
          yearSelectLabel: this.$t('year').toString(),
          closeLabel: this.$t('close_window').toString(),
          keyboardInstruction: this.$t('you_can_use_arrow_keys').toString(),
          calendarHeading: this.$t('choose_a_date').toString(),
          locale: 'en-GB',
          format: "dd-MM-yyyy", // format for date-fns
          ariaLabel: this.$t('entry_pattern_is_numeric.en'), // aria label
          vMask: "##-##-####", // v-mask
          regex: /^(\d{2})\-?(\d{2})\-?(\d{4})$/,
          formatMask: function(value: string) { return value.split('-').reverse().join('-'); },
        };

        break;
      case("iso"):
        return {
          buttonLabel: this.$t('choose_date').toString(),
          placeholder: this.$t('date_format.iso').toString(),
          selectedDateMessage: this.$t('selected_date_is').toString(),
          prevMonthLabel: this.$t('previous_month').toString(),
          nextMonthLabel: this.$t('next_month').toString(),
          monthSelectLabel: this.$t('month').toString(),
          yearSelectLabel: this.$t('year').toString(),
          closeLabel: this.$t('close_window').toString(),
          keyboardInstruction: this.$t('you_can_use_arrow_keys').toString(),
          calendarHeading: this.$t('choose_a_date').toString(),
          locale: 'en-US',
          format: "yyyy-MM-dd", // format for date-fns
          ariaLabel: this.$t('entry_pattern_is_numeric.iso'), // aria label
          vMask: "####-##-##", // v-mask
          regex: /^(\d{4})\-?(\d{2})\-?(\d{2})$/,
          formatMask: function(value: string) { return value; }, // tbd
        };
        break;

      case("us"):
        return {
          buttonLabel: this.$t('choose_date').toString(),
          placeholder: this.$t('date_format.us').toString(),
          selectedDateMessage: this.$t('selected_date_is').toString(),
          prevMonthLabel: this.$t('previous_month').toString(),
          nextMonthLabel: this.$t('next_month').toString(),
          monthSelectLabel: this.$t('month').toString(),
          yearSelectLabel: this.$t('year').toString(),
          closeLabel: this.$t('close_window').toString(),
          keyboardInstruction: this.$t('you_can_use_arrow_keys').toString(),
          calendarHeading: this.$t('choose_a_date').toString(),
          locale: 'en-US',
          format: "MM-dd-yyyy", // format for date-fns
          ariaLabel: this.$t('entry_pattern_is_numeric.iso'), // aria label
          vMask: "##-##-####", // v-mask
          regex: /^(\d{4})\-?(\d{2})\-?(\d{2})$/,
          formatMask: function(value: string) { return value; }, // tbd
        };
        break;
    }
  }
}
</script>
