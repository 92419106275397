import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 1,
  class: "form-text text-muted",
  title: "calculated"
}
const _hoisted_4 = { class: "mobile-spacing-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "input-group mb-3"
}
const _hoisted_6 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_7 = ["id", "readonly", "tabindex", "title", "placeholder"]
const _hoisted_8 = { class: "input-group-append" }
const _hoisted_9 = { class: "input-group-text" }
const _hoisted_10 = ["id"]
const _hoisted_11 = ["id"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["id"]
const _hoisted_14 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_15 = ["id", "readonly", "tabindex", "title", "placeholder"]
const _hoisted_16 = ["id"]
const _hoisted_17 = ["id"]
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTipControl = _resolveComponent("ToolTipControl")!
  const _component_validation_provider = _resolveComponent("validation-provider")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "link")
      ], 10, _hoisted_1),
      (_ctx.guidingText)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            class: "form-guiding-text text-muted d-flex",
            title: _ctx.guidingTextContent
          }, _toDisplayString(_ctx.guidingTextContent), 9, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.calculated)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              class: "text-grey",
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.calculatedText), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showToolTip)
        ? (_openBlock(), _createBlock(_component_ToolTipControl, {
            key: 2,
            toolTipText: _ctx.toolTipText
          }, null, 8, ["toolTipText"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, [
        (_ctx.append)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                : _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                    key: 1,
                    id: _ctx.inputId,
                    type: "text",
                    class: {
              'is-invalid': !_ctx.disabled && errors[0], 
              'form-control': !_ctx.isReadOnly(_ctx.readonly), 
              'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly), ..._ctx.cssClasses,
              'is-warning': _ctx.showWarning 
              }
                  }, field, {
                    readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled),
                    tabindex:  _ctx.readonly ? -1 : 0,
                    title: _ctx.showTitle ? field : '',
                    placeholder: _ctx.placeholder
                  }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_7)), [
                    [_directive_maska, _ctx.mask]
                  ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.appendText), 1)
              ]),
              (errors[0])
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "invalid-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                  ], 8, _hoisted_10))
                : _createCommentVNode("", true),
              (!errors[0] && _ctx.showWarning)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: "warning-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createElementVNode("span", { innerHTML: _ctx.getWarningMessage }, null, 8, _hoisted_12)
                  ], 8, _hoisted_11))
                : _createCommentVNode("", true),
              (_ctx.showAdvisoryMessage)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: "advisory-message",
                    id: `${_ctx.inputId}-warning`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('max_Length_message', { maxLength: _ctx.maxLength })), 1)
                  ], 8, _hoisted_13))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14))
                : _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                    key: 1,
                    id: _ctx.inputId,
                    type: "text",
                    class: { 
            'is-invalid': !_ctx.disabled && errors[0], 
            'form-control': !_ctx.isReadOnly(_ctx.readonly), 
            'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly), ..._ctx.cssClasses,
            'is-warning': _ctx.showWarning 
            }
                  }, field, {
                    readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled),
                    tabindex:  _ctx.readonly ? -1 : 0,
                    title: _ctx.showTitle ? field : '',
                    placeholder: _ctx.placeholder
                  }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_15)), [
                    [_directive_maska, _ctx.mask]
                  ]),
              (errors[0] && !_ctx.disabled)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "invalid-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                  ], 8, _hoisted_16))
                : _createCommentVNode("", true),
              (!errors[0] && _ctx.showWarning)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: "warning-feedback",
                    id: `${_ctx.inputId}-error`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createElementVNode("span", { innerHTML: _ctx.getWarningMessage }, null, 8, _hoisted_18)
                  ], 8, _hoisted_17))
                : _createCommentVNode("", true),
              (_ctx.showAdvisoryMessage)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: "advisory-message",
                    id: `${_ctx.inputId}-warning`
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'exclamation-circle'],
                      "fixed-width": ""
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('max_Length_message', { maxLength: _ctx.maxLength })), 1)
                  ], 8, _hoisted_19))
                : _createCommentVNode("", true)
            ], 64))
      ])
    ]),
    _: 3
  }, 8, ["rules", "name", "label", "modelValue"]))
}