<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="inputId"
    :label="name"
    :vid="validationId ? validationId : inputId" 
    v-model="validatedValue"
    v-slot="{ field, errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!disabled"
        :rules="formRules"
        :crossValues="crossValues"
        :ruleKey="ruleKey"
      />
      <slot name="link" />
    </label>
    <!-- TODO: ensure that the Calculated indicator is accessible to screen readers -->
    <small v-if="calculated" class="form-text text-muted" title="Calculated">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
      {{calculatedText}}
    </small>
    <span class="mobile-spacing-wrapper">
      <template v-if="isMasked">
        <input
          type="text"
          :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
          :id="inputId"
          :disabled="true"
          :readonly="true"
          v-bind="field"
        />
      </template>
      <template v-else-if="append">
        <div class="input-group mb-3">
          <input 
            :id="inputId"
            type="text"
            :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
            v-bind="field"
            :readonly="isReadOnly(readonly||disabled)"
            v-on="maskedInputEvents()"
            :placeholder="timeFormatDisplay"
            :aria-label="timeFormatAriaLabel"
            v-maska="timeFormatMask"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{appendText}}</span>
          </div>
          <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ translateError(errors, label || name) }}
          </div>
        </div>
      </template>
      <template v-else>
        <input
          :id="inputId"
          type="text"
          :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
          v-bind="field"
          :readonly="isReadOnly(readonly||disabled)"
          v-on="maskedInputEvents()"
          :placeholder="timeFormatDisplay"
          :aria-label="timeFormatAriaLabel"
          v-maska="timeFormatMask"
        />
        <!-- error block, not shown if disabled -->
        <div
          v-if="errors[0] && !disabled"
          class="invalid-feedback"
          :id="`${inputId}-error`"
        >
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          {{ translateError(errors, label || name) }}
        </div>
      </template>
    </span>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { isMasked } from '@/utils';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    ValidationAsterisk
  },
  emits: [
    'update:modelValue',
  ],
})
export default class TimeInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end 
                                            
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix

  // Local instance
  private validatedValue = '';

  // masked input
  get isMasked(): boolean {
    return isMasked(`${this.modelValue}`);
  }

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  // Default time input format expressed as a human-readable string
  get timeFormatDisplay(): string {
    return useCurrentPageStore().configuration.dateFormatDefinition.TIME_DISPLAY;
  }

  // Default date input format expressed as a text masking directive
  get timeFormatMask(): string {
    return useCurrentPageStore().configuration.dateFormatDefinition.TIME_MASK;
  }

  // Default time input format described for screen readers
  get timeFormatAriaLabel(): string {
    return useCurrentPageStore().configuration.dateFormatDefinition.TIME_ARIA_DISPLAY;
  }

  get validationProvider() {
    return this.$refs.provider as any;
  }

  mounted(): void {
    this.validationProvider.reset({ value: this.modelValue });
  }

  @Watch('modelValue')
  onModelValue(): void {
    this.validationProvider.reset({ value: this.modelValue });
  }

  // Forward events to the parent component
  public maskedInputEvents(): any {
    const _vm = this as TimeInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('update:modelValue', event.target.value);
        }
      }
    );
  }
}
</script>
