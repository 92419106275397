<template>
  <div class="nav-block-wrap">
    <ul class="nav nav-block flex-column" :class="{ active: active }">
      <li class="nav-item">
        <span class="nav-link">
          <font-awesome-icon :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" class="nav-caret" fixed-width @click.prevent="toggleNav" />
          <slot name="profile-return-link" />
        </span>
      </li>
      <ul class="nav flex-column" v-if="show">
        <li class="nav-item" @click="toggleSideNav">
          <a class="nav-link" 
            :href="getUrl('demographics')">
            {{$t('demographics')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isContactInfoEnabled">
          <a class="nav-link" 
            :href="getUrl('contactinfo')">
            {{$t('contact_information')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav"  v-if="!newRecipient && isProtoCareGiversEnabled">
          <a class="nav-link" 
          :href="getUrl('proto_care_givers')">
          Care Givers</a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isProtoCommunicationInformationEnabled">
          <a class="nav-link" 
          :href="getUrl('proto_communication_information')">
          Communication Information</a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isProtoTransportationAndMobilityEnabled">
          <a class="nav-link" 
          :href="getUrl('proto_transportation_and_mobility')">
          Transportation and Mobility</a>
        </li>
        <li class="nav-item" @click="toggleSideNav"  v-if="!newRecipient && isProtoPaceAndEngagementEnabled">
          <a class="nav-link" 
          :href="getUrl('proto_pace_and_engagement')">
          Pace and Engagement</a>
        </li>
        <li class="nav-item" @click="toggleSideNav"  v-if="!newRecipient && isProtoSchedulingPreferencesEnabled">
          <a class="nav-link" 
          :href="getUrl('proto_scheduling_preferences')">
          Scheduling Preferences</a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isRecipientDocumentsEnabled">
          <a class="nav-link" 
            :href="getUrl('recipient-documents')">
            {{$t('recipient_documents')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isRecipientGeneralClinicalEnabled">
          <a class="nav-link" 
            :href="getUrl('gci')">
            {{$t('general_clinical_information')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isRecipientSerologyEnabled">
          <a class="nav-link" 
            :href="getUrl('recipient-serology')">
            {{$t('recipient_serology')}}
          </a>
        </li>
        <li class="nav-item" @click="toggleSideNav" v-if="!newRecipient && isRecipientDeathEnabled">
          <a class="nav-link" 
            :href="getUrl('recipient-death')">
            {{$t('recipient_death')}}
          </a>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script lang="ts">
import { State } from 'vuex-facing-decorator';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Recipient } from "@/store/recipients/types";
import { useCurrentPageStore } from '@/stores/currentPage';
import { i18nMessages } from '@/i18n';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  ...i18nMessages([
    require('@/components/organs/shared/_locales/OrganDetailsSection.json'),
    require('@/components/recipients/_locales/SelectedRecipientProfileLinks.json'),
  ]),
})
export default class SelectedRecipientProfileLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  @Prop({ default: false }) active!: boolean;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;

  get isContactInfoEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.contactInfo.enabled;
  }

  get isProtoCareGiversEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.CareGivers);
  }

  get isRecipientDocumentsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientDocuments.enabled;
  }
  
  get isProtoTransportationAndMobilityEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.TransportationAndMobility);
  }
  
  get isProtoCommunicationInformationEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.CommunicationInformation);
  }

  get isProtoPaceAndEngagementEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.PaceAndEngagement);
  }

  get isProtoSchedulingPreferencesEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SchedulingPreferences);
  }



  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Gets the recipient client_id.
   *
   * Converts the recipient client_id to a string
   *
   * @returns {string} client_id as a string
   */
  get clientId(): string {
    return this.recipient.client_id == null ? '' : this.recipient.client_id.toString();
  }

  // Check if Recipient Death is enabled
  get isRecipientDeathEnabled(): boolean {
    const currentPageStore = useCurrentPageStore();
    if (!currentPageStore.configuration) return false;

    return currentPageStore.configuration.features.recipientConfig.recipientDeath.enabled;
  }

  // Check if Recipient Serology is enabled
  get isRecipientSerologyEnabled(): boolean {
    const currentPageStore = useCurrentPageStore();
    if (!currentPageStore.configuration) return false;

    return currentPageStore.configuration.features.recipientConfig.recipientSerology.enabled;
  }

  /**
   * Create a return to profile link
   *
   * @returns {string} client_id as a string
   */
  get profileReturnLink(): string {
    return this.clientId === '' ? `/recipients` : `/recipients/${this.clientId}`;
  }

  // Check if Recipient General Clinical is enabled
  get isRecipientGeneralClinicalEnabled(): boolean {
    const currentPageStore = useCurrentPageStore();
    if (!currentPageStore.configuration) return false;

    return currentPageStore.configuration.features.recipientConfig.recipientGeneralClinical.enabled;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    if(this.active) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-recipient', params: { id: this.clientId }, hash: hash });
      return location.href;
    }
  }
}
</script>
