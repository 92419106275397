import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "recipient-summary" }
const _hoisted_2 = {
  key: 0,
  class: "list-inline-item"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "list-inline-item" }
const _hoisted_5 = {
  key: 1,
  class: "list-inline-item"
}
const _hoisted_6 = {
  key: 2,
  class: "list-inline-item"
}
const _hoisted_7 = {
  key: 3,
  class: "list-inline-item"
}
const _hoisted_8 = {
  key: 4,
  class: "list-inline-item"
}
const _hoisted_9 = {
  key: 0,
  class: "sticky-organ-summary-area"
}
const _hoisted_10 = {
  key: 0,
  class: "organ-summary"
}
const _hoisted_11 = { for: "sticky-summary-active-journeys" }
const _hoisted_12 = {
  id: "sticky-summary-active-journeys",
  class: "organ-summary"
}
const _hoisted_13 = {
  key: 1,
  class: "organ-summary"
}
const _hoisted_14 = { for: "sticky-summary-post-transplant-journeys" }
const _hoisted_15 = {
  id: "sticky-summary-post-transplant-journeys",
  class: "organ-summary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organ_icon = _resolveComponent("organ-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_sticky_summary = _resolveComponent("sticky-summary")!

  return (_ctx.uiRecipientSummary)
    ? (_openBlock(), _createBlock(_component_sticky_summary, { key: 0 }, {
        summaryContent: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_ctx.isBasicNameEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('name')) + ": ", 1),
                    _createElementVNode("b", null, [
                      _createTextVNode(_toDisplayString(_ctx.uiRecipientSummary.summaryName) + " ", 1),
                      (_ctx.uiRecipientSummary.summaryStatus)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.uiRecipientSummary.summaryStatus)), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('client_id')) + ": ", 1),
                _createElementVNode("b", null, _toDisplayString(_ctx.uiRecipientSummary.clientId), 1)
              ])
            ]),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('dob')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiRecipientSummary.dateOfBirth || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('age')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiRecipientSummary.age != null ? _ctx.uiRecipientSummary.age : '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isSexEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('sex')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiRecipientSummary.sex || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.isBloodTypeEnabled)
              ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('abo')) + ": ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.uiRecipientSummary.bloodType || '-'), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          ((_ctx.uiRecipientSummary.activeJourneys.length + _ctx.uiRecipientSummary.postTransplantJourneys.length) > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (_ctx.uiRecipientSummary.activeJourneys.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('active_journeys')), 1),
                      _createElementVNode("ul", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiRecipientSummary.activeJourneys, (uiJourneySummary, idx) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: idx,
                            class: _normalizeClass(["list-inline-item", _ctx.$route.params.organ_id == uiJourneySummary.id ? 'active' : ''])
                          }, [
                            _createVNode(_component_router_link, {
                              to: { name: 'edit-organ', params: { organ_id: uiJourneySummary.id } },
                              class: "nav-link"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_organ_icon, {
                                  "organ-code": uiJourneySummary.organCode,
                                  "summary-bar-icon": true
                                }, null, 8, ["organ-code"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.journeyName(uiJourneySummary.organCode, uiJourneySummary.id))), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ], 2))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.uiRecipientSummary.postTransplantJourneys.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('post_transplant_journeys')), 1),
                      _createElementVNode("ul", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiRecipientSummary.postTransplantJourneys, (uiJourneySummary, idx) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: idx,
                            class: _normalizeClass(["list-inline-item", _ctx.$route.params.organ_id == uiJourneySummary.id ? 'active' : ''])
                          }, [
                            _createVNode(_component_router_link, {
                              to: { name: 'edit-organ', params: { organ_id: uiJourneySummary.id } },
                              class: "nav-link"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_organ_icon, {
                                  "organ-code": uiJourneySummary.organCode,
                                  "summary-bar-icon": true
                                }, null, 8, ["organ-code"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.journeyName(uiJourneySummary.organCode, uiJourneySummary.id))), 1)
                              ]),
                              _: 2
                            }, 1032, ["to"]),
                            _createTextVNode(" (" + _toDisplayString(_ctx.$t('transplanted')) + " " + _toDisplayString(uiJourneySummary.transplantedDate || '-') + ") ", 1)
                          ], 2))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}