import { APIMeasurement } from '@/store/recipients/types';
import { parseDateUi, sanitizeDateApi } from '@/utilities/date-utils';
import { UIRecipient } from '@/UIModels/recipient';
import { SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { APISaveMeasurementResponse } from '@/types';
import { buildErrorResult, toFixed, isValidFloatFormat } from '@/utils';
import { BmiResult } from '@/store/tools/types';

const DIGITS_AFTER_DECIMAL = 1;

export class UIMeasurement {
  private loaded: boolean;

  public apiSource: APIMeasurement|null = null;

  public id: string|null = null;
  public date: string|null = null;
  public height_cm: string|null = null;
  public height_in: string|null = null;
  public weight_kg: string|null = null;
  public weight_lbs: string|null = null;
  public bmi: number|null = null;

  public permitted_actions: string[] = []; 

  public constructor() {
    this.loaded = false;
  }

  // Define empty UI model structure
  public static buildNew() {
    const uiModel = new UIMeasurement();
    return uiModel;
  }

  // Map from API data structure to UI model structure
  public static buildFromAPIMeasurement(apiMeasurement: APIMeasurement) {
    const uiModel = new UIMeasurement();

    uiModel.apiSource = apiMeasurement;

    uiModel.id = apiMeasurement._id?.$oid || null;
    uiModel.date = parseDateUi(apiMeasurement.date) || null;
    uiModel.height_cm = toFixed(apiMeasurement.height_cm, DIGITS_AFTER_DECIMAL) || null;
    uiModel.weight_kg = toFixed(apiMeasurement.weight_kg, DIGITS_AFTER_DECIMAL) || null;
    uiModel.bmi = apiMeasurement.bmi || null;

    uiModel.permitted_actions = apiMeasurement.permitted_actions || [];

    uiModel.loaded = true;
    return uiModel;
  }

  // Build a copy of the view model
  public copyViewModel() {
    const apiMeasurement = this.apiSource as APIMeasurement;
    if (!apiMeasurement) return UIMeasurement.buildNew();

    return UIMeasurement.buildFromAPIMeasurement(apiMeasurement);
  }

  public updateFromBMIResult(bmiResult: BmiResult) {
    if (!bmiResult) return;

    // Set calculated BMI if it was provided in the calculation response
    this.bmi = bmiResult.bmi == null ? null : toFixed(bmiResult.bmi, DIGITS_AFTER_DECIMAL);

    // Convert height if applicable
    switch (bmiResult.height_unit) {
      case 'cm':
        if (this.height_cm != null && isValidFloatFormat(this.height_cm) && this.height_cm.length > 0) {
          this.height_in = toFixed(bmiResult.height.in, DIGITS_AFTER_DECIMAL);
        } else if (this.height_cm == null || this.height_cm == '') {
          this.height_in = null;
        }
        break;
      case 'in':
        if (this.height_in != null && isValidFloatFormat(this.height_in) && this.height_in.length > 0) {
          this.height_cm = toFixed(bmiResult.height.cm, DIGITS_AFTER_DECIMAL);
        } else if (this.height_in == null || this.height_in == '') {
          this.height_cm = null;
        }
        break;
    }

    // Convert weight if applicable
    switch (bmiResult.weight_unit) {
      case 'kg':
        if (this.weight_kg != null && isValidFloatFormat(this.weight_kg) && this.weight_kg.length > 0) {
          this.weight_lbs = toFixed(bmiResult.weight.lbs, DIGITS_AFTER_DECIMAL);
        } else if (this.weight_kg == null || this.weight_kg == '') {
          this.weight_lbs = null;
        }
        break;
      case 'lbs':
        if (this.weight_lbs && isValidFloatFormat(this.weight_lbs) && this.weight_lbs.length > 0) {
          this.weight_kg = toFixed(bmiResult.weight.kg, DIGITS_AFTER_DECIMAL);
        } else if (this.weight_lbs == null || this.weight_lbs == '') {
          this.weight_kg = null;
        }
        break;
    }
  }

  // Generate request payload parameters to provide to API for a Recipient Death patch
  private extractPatch(): APIMeasurement {
    const result: APIMeasurement = {
      date: sanitizeDateApi(this.date),
      height_cm: this.height_cm != null && isValidFloatFormat(this.height_cm) ? parseFloat(this.height_cm) : this.height_cm as any,
      weight_kg: this.weight_kg != null && isValidFloatFormat(this.weight_kg) ? parseFloat(this.weight_kg) : this.weight_kg as any,
      bmi: this.bmi
    };

    return result;
  }

  // Save edit state to the backend
  public save(opts: { selected: UIMeasurement|null, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      const recipientId = opts.recipient.clientId;
      if (!recipientId) reject((new UIError('recipient_measurements')));

      let method: any;
      let ep: string;
      if (opts.selected?.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.measurements.update, [[':recipientId', recipientId as string], [':id', opts.selected.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.measurements.create, [[':recipientId', recipientId as string]]);
      }
      const payload = {
        measurements: this.extractPatch()
      };
      method(ep, payload).then((response: APISaveMeasurementResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_measurements', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        // if 403 alert user, as there will be no server response
        if (errorResponse.response.status === 403) {
          const forbiddenResponse = buildErrorResult(errorResponse.message);
          reject(forbiddenResponse);
        // otherwise send error response
        } else {
          reject((new UIError('recipient_measurements', errorResponse)).errorResult);
        }
      });
    });
  }
}
