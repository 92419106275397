import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["action"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_list = _resolveComponent("user-list")!
  const _component_login_google_workspace = _resolveComponent("login-google-workspace")!
  const _component_login_container = _resolveComponent("login-container")!

  return (_openBlock(), _createBlock(_component_login_container, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showUserSelect)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.bypassFusionAuth)
                ? (_openBlock(), _createBlock(_component_user_list, {
                    key: 0,
                    fusionAuth: !_ctx.bypassFusionAuth
                  }, null, 8, ["fusionAuth"]))
                : (_openBlock(), _createElementBlock("form", {
                    key: 1,
                    action: _ctx.fusionAuthURL,
                    onSubmit: _cache[0] || (_cache[0] = (event) => _ctx.prepareRequest('fusionauth')),
                    ref: "fusionRequest",
                    method: "post",
                    class: "oneIdRequest"
                  }, [
                    _createElementVNode("input", {
                      type: "submit",
                      value: _ctx.$t('login_fusionauth'),
                      class: "btn btn-info login float-left"
                    }, null, 8, _hoisted_3)
                  ], 40, _hoisted_2))
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.loginWithGoogleWorkspace)
          ? (_openBlock(), _createBlock(_component_login_google_workspace, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}