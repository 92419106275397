<template>
  <div>
    <div v-if="!organCode">
      {{$t('loading')}}
    </div>
    <div v-else>
      <!-- Page Top -->
      <page-top>
        <router-link :to="{ name: 'list-recipients' }">
          {{$t('recipients')}}
        </router-link>
        /
        <router-link :to="{ name: 'edit-recipient', params: { id: recipientId } }">
          {{recipientDisplayName}}
        </router-link>
        / {{$t(organName)}}
      </page-top>
      <recipient-sticky-summary />
      <!-- Page wrap -->
      <div class="content-wrap">
        <!-- Page Container  -->
        <div class="container-fluid">
          <!-- Nav wrapper -->
          <div class="nav-wrapper">
            <!-- Sidebar Navigation -->
            <side-nav-journey :newOrgan="organName" :organCode="organCode"/>
            <!-- Organ Content -->
            <div class="page-content">
              <recipient-summary />
              <validation-observer ref="validations">
                <component
                  v-bind:is="organComponentName"
                  :new-journey="true"
                  ref="organComponent"
                />
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Getter, State } from 'vuex-facing-decorator';
import { Component } from 'vue-facing-decorator';
import { mixins } from "vue-facing-decorator";
import { ValidationUtilsMixin } from "@/mixins/validation-utils-mixin";
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';
import PageTop from '@/components/shared/PageTop.vue';
import SideNavJourney from "@/components/organs/shared/side-nav/SideNavJourney.vue";
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    PageTop,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary
  },
  ...i18nMessages([
    require('@/views/_locales/organs.json'),
    require('@/components/_locales/Organs.json'),
  ]),
})
export default class NewOrgan extends mixins(ValidationUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|number|undefined, lookupId: string) => any;
  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;


  get recipientDisplayName(): string {
    return useCurrentPageStore().currentRecipient?.displayName || '';
  }

  public async mounted(): Promise<void> {
    await useCurrentPageStore().currentRecipient?.load();
    Promise.all([
      this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.$t(this.organName)}`),
      this.$store.dispatch('validations/loadNew', { view: `recipients/${this.recipientId}/journeys/`, action: 'new' })
    ]);
  }

  /**
   * Return organ_code param from url
   *
   * @returns {number} organ code
   */
  get organCode(): number {
    return parseFloat(this.$route.params.organ_code as string);
  }

  /**
   * Return titlized organ name from lookup value
   *
   * @returns {string} organ name
   */
  get organName(): string {
    const organ = this.lookupValue(this.organCode, 'organ');
    return organ ? organ : '';
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponentName(): string {
    return organCodeLookup[this.organCode];
  }
}
</script>
