import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_2 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "standard-form-group" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "standard-full-width-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_ctx.checklistTask.showOutcomeForm)
    ? (_openBlock(), _createBlock(_component_validation_observer, {
        key: 0,
        ref: "validations"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_form_layout, {
            disabled: !_ctx.enableForm,
            "form-id": "checklist-outcome-form"
          }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                (_ctx.selection.isNew)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.$t('new_outcome')), 1))
                  : (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t('selected_outcome')), 1))
              ])
            ]),
            contents: _withCtx(() => [
              _createVNode(_component_action_toolbar, {
                permittedActions: _ctx.editState.permitted_actions,
                destroyTitleText: _ctx.$t('delete_outcome'),
                destroyGuidingText: _ctx.$t('delete_outcome_question'),
                onDestroy: _ctx.onDestroy
              }, null, 8, ["permittedActions", "destroyTitleText", "destroyGuidingText", "onDestroy"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_date_input, {
                    ruleKey: `checklist_outcomes_${_ctx.checklistTask.id}.service_date`,
                    name: _ctx.$t('service_date'),
                    "input-id": `checklist_outcomes_${_ctx.checklistTask.id}_service_date`,
                    modelValue: _ctx.editState.service_date,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.service_date) = $event))
                  }, null, 8, ["ruleKey", "name", "input-id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_date_input, {
                    ruleKey: `checklist_outcomes_${_ctx.checklistTask.id}.expiration_date`,
                    name: _ctx.$t('expiration_date'),
                    "input-id": `checklist_outcomes_${_ctx.checklistTask.id}_expiration_date`,
                    modelValue: _ctx.editState.expiration_date,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.expiration_date) = $event))
                  }, null, 8, ["ruleKey", "name", "input-id", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_select_input, {
                    ruleKey: `checklist_outcomes_${_ctx.checklistTask.id}.imaging_received`,
                    "select-id": `checklist_outcomes_${_ctx.checklistTask.id}_imaging_received`,
                    name: _ctx.$t('imaging_received'),
                    options: _ctx.checklistTakeOutcomeImagingReceivedOptions,
                    modelValue: _ctx.editState.imaging_received,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.imaging_received) = $event))
                  }, null, 8, ["ruleKey", "select-id", "name", "options", "modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_text_area_input, {
                    ruleKey: `checklist_outcomes_${_ctx.checklistTask.id}.outcome`,
                    "input-id": `checklist_outcomes_${_ctx.checklistTask.id}_outcome`,
                    name: _ctx.$t("outcome"),
                    modelValue: _ctx.editState.outcome,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.outcome) = $event)),
                    rows: "4"
                  }, null, 8, ["ruleKey", "input-id", "name", "modelValue"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }, 512))
    : _createCommentVNode("", true)
}