import { APIRecipientJourneyConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";
import { UIHeartSpecificsConfig } from "@/UIModels/configuration/features/journeyConfig/heartSpecifics";
import { UIKidneySpecificsConfig } from "@/UIModels/configuration/features/journeyConfig/kidneySpecifics";
import { UILiverSpecificsConfig } from "@/UIModels/configuration/features/journeyConfig/liverSpecifics";
import { UILungSpecificsConfig } from "@/UIModels/configuration/features/journeyConfig/lungSpecifics";
import { UIJourneyOverviewConfig } from "@/UIModels/configuration/features/journeyConfig/journeyOverview";
import { UIPostTransplantFollowUpConfig } from "@/UIModels/configuration/features/journeyConfig/postTransplantFollowUp";
import { UIManageWaitlistConfig } from "@/UIModels/configuration/features/journeyConfig/manageWaitlist";
import { UIChecklistConfig } from "@/UIModels/configuration/features/journeyConfig/checklists";

export class UIJourneyConfig {
  public enabled: boolean;
  public journeyOverview: UIJourneyOverviewConfig;
  public referralDetails: UIFeatureConfig;
  public clinicalNotes: UIFeatureConfig;
  public checklists: UIChecklistConfig;
  public donorAcceptability: UIFeatureConfig;
  public manageWaitlist: UIManageWaitlistConfig;
  public transplantDetails: UIFeatureConfig;
  public postTransplantFollowUp: UIPostTransplantFollowUpConfig;

  public liverSpecifics: UILiverSpecificsConfig;
  public heartSpecifics: UIHeartSpecificsConfig;
  public kidneySpecifics: UIKidneySpecificsConfig;
  public lungSpecifics: UILungSpecificsConfig;

  public constructor(apiSource: APIRecipientJourneyConfig) {
    this.enabled = apiSource.enabled;
    this.journeyOverview = new UIJourneyOverviewConfig(apiSource.journey_overview);
    this.referralDetails = new UIFeatureConfig(apiSource.referral_details);
    this.clinicalNotes = new UIFeatureConfig(apiSource.clinical_notes);
    this.checklists = new UIChecklistConfig(apiSource.checklists);
    this.donorAcceptability = new UIFeatureConfig(apiSource.donor_acceptability);
    this.manageWaitlist = new UIManageWaitlistConfig(apiSource.manage_waitlist);
    this.transplantDetails = new UIFeatureConfig(apiSource.transplant);
    this.postTransplantFollowUp = new UIPostTransplantFollowUpConfig(apiSource.post_transplant_follow_up);

    this.liverSpecifics = new UILiverSpecificsConfig(apiSource.liver_specific_details);
    this.heartSpecifics = new UIHeartSpecificsConfig(apiSource.heart_specific_details);
    this.kidneySpecifics = new UIKidneySpecificsConfig(apiSource.kidney_specific_details);
    this.lungSpecifics = new UILungSpecificsConfig(apiSource.lung_specific_details);
  }
}
