import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "save-toolbar action-row"
}
const _hoisted_2 = ["disabled", "title"]
const _hoisted_3 = ["disabled", "title"]
const _hoisted_4 = {
  key: "saving",
  class: "notification notification-info notification-inline button-area"
}
const _hoisted_5 = {
  key: "success",
  class: "notification notification-success notification-inline button-area"
}
const _hoisted_6 = { class: "btn-close" }
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = { class: "btn-close" }
const _hoisted_9 = ["aria-label", "onClick"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "actions"),
        _createElementVNode("button", {
          type: "button",
          disabled: _ctx.isDisabled,
          class: _normalizeClass(_ctx.buttonClassObject(_ctx.currentState, _ctx.requestedState)),
          title: _ctx.buttonTitle,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 10, _hoisted_2),
        (_ctx.showSaveAndCreateAnother)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              disabled: _ctx.isDisabled,
              class: _normalizeClass(_ctx.buttonClassObject(_ctx.currentState, _ctx.requestedState)),
              title: _ctx.labelSaveAndCreateAnother,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.saveAndCreateAnother && _ctx.saveAndCreateAnother(...args)))
            }, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.labelSaveAndCreateAnother), 1)
              ])
            ], 10, _hoisted_3))
          : _createCommentVNode("", true),
        _createVNode(_Transition, {
          name: "fade-slide",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.currentState === _ctx.saveState.Saving)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", null, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['far', 'spinner-third'],
                      "fixed-width": "",
                      spin: "",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.savingText), 1)
                  ])
                ]))
              : (_ctx.currentState === _ctx.saveState.Success)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("p", null, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'check-circle'],
                        "fixed-width": "",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.successText), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("button", {
                        ref: "dismissSaveReferralDetailsSuccess",
                        type: "button",
                        class: "btn btn-sm",
                        "aria-label": _ctx.$t('close_successful_save_notification'),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismissSuccess()))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'times'],
                          "fixed-width": "",
                          "aria-hidden": "true"
                        })
                      ], 8, _hoisted_7)
                    ])
                  ]))
                : (_ctx.currentState === _ctx.saveState.Error)
                  ? (_openBlock(), _createBlock(_TransitionGroup, {
                      name: "fade-list",
                      tag: "span",
                      key: "error"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, idx) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            (!error.dismissed)
                              ? (_openBlock(), _createElementBlock("div", {
                                  class: "fade-list-item notification notification-error notification-inline button-area",
                                  key: `error-${idx}`
                                }, [
                                  _createElementVNode("p", null, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: ['far', 'exclamation-circle'],
                                      "fixed-width": "",
                                      "aria-hidden": "true"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.translateError([error.text || _ctx.defaultErrorText], _ctx.label)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                      ref_for: true,
                                      ref: "dismissSaveReferralDetailsError",
                                      type: "button",
                                      class: "btn btn-sm",
                                      "aria-label": _ctx.$t('close_save_error_notification'),
                                      onClick: ($event: any) => (_ctx.dismissError(idx))
                                    }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: ['far', 'times'],
                                        "fixed-width": "",
                                        "aria-hidden": "true"
                                      })
                                    ], 8, _hoisted_9)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 256))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.cancelButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              disabled: _ctx.isDisabled,
              class: _normalizeClass(_ctx.cancelButtonClass),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.performCancel && _ctx.performCancel(...args)))
            }, [
              _renderSlot(_ctx.$slots, "label", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
              ])
            ], 10, _hoisted_10))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}