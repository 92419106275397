import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12 considerations-section" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "standard-form-group" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group-small-checkbox" }
const _hoisted_8 = { class: "standard-form-group" }
const _hoisted_9 = { class: "standard-form-group" }
const _hoisted_10 = { class: "standard-form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "standard-form-group-3-quarters" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "standard-form-group-3-quarters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_sub_section = _resolveComponent("sub-section")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_card_section = _resolveComponent("card-section")!

  return (_ctx.isProtoCommunicationInformationEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_card_section, { "section-id": "proto_communication_information" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          body: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "proto-considerations-section-form" }, {
              contents: _withCtx(() => [
                _createVNode(_component_sub_section, {
                  title: _ctx.title,
                  "sub-section-id": "proto-considerations-section-internal",
                  hideDivider: true
                }, {
                  contents: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("fieldset", null, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_checkbox_input, {
                                name: "interpreter-needed",
                                labelName: "Interpreter Needed?",
                                inputId: "interpreter-needed",
                                modelValue: _ctx.interpreter,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.interpreter) = $event)),
                                label: "Yes"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_text_input, {
                                "input-id": "primary-language",
                                name: "Primary Language",
                                "validation-id": "insuranceStatus",
                                modelValue: _ctx.language,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.language) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_checkbox_input, {
                                name: "poa",
                                labelName: "POA?",
                                inputId: "poa",
                                modelValue: _ctx.poa,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.poa) = $event)),
                                label: "Yes"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_text_input, {
                                "input-id": "Care-partner-name-poa",
                                name: "Care Partner Name (POA)",
                                "validation-id": "CarePartnerName",
                                modelValue: _ctx.carePartnerName,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.carePartnerName) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_checkbox_input, {
                                name: "decision-support",
                                labelName: "Decision Support Needed?",
                                inputId: "decision-support",
                                modelValue: _ctx.decision,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.decision) = $event)),
                                label: "Yes"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_text_input, {
                                inputId: "care-partner-for-support",
                                name: "Care Partner Name (Decision Support)",
                                modelValue: _ctx.supportPartner,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.supportPartner) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "audiovisual-inpairments-devices",
                                name: "Audiovisual Impairments and Devices",
                                rows: "4",
                                showToolTip: true,
                                toolTipText: "Describe the patient's audiovisual impairments and devices and how they impact communication.",
                                modelValue: _ctx.inpairmentsDevices,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inpairmentsDevices) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_text_area_input, {
                                "input-id": "audiovisual-comments",
                                name: "Comments",
                                rows: "4",
                                modelValue: _ctx.comments,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.comments) = $event))
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveDemographics",
                  label: `Save ${_ctx.title}`,
                  cancelButton: true
                }, null, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}