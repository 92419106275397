import axios from 'axios';
import { ActionTree } from 'vuex';
import { SaveResult } from '@/types';
import { APIRoute, EP } from '@/api-endpoints';
import { RootState } from '@/store/types';
import { UsersState, ResponseType } from '@/store/users/types';
import { useCurrentPageStore } from '@/stores/currentPage';

export const actions: ActionTree<UsersState, RootState> = {
  // load user info
  loadUser({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      // Get validations based on view and action
      const url = APIRoute(EP.auth.show);
      axios.get(url).then((response: any) => {
        const responseData: any = response.data;
        // Pass value to mutation to place in store
        const user = responseData && responseData.user ? responseData.user : null;
        const currentUser = user ? user.user_id : undefined;
        commit('setUser', user);
        commit('setCurrentUser', currentUser, { root: true });
        useCurrentPageStore().preferences.updateFromAPIUser(user);
        resolve();
      }).catch((error: any) => {
        // check for response error code
        const response = error && error.response ? error.response : null;
        if (!response) reject(error);
        if (response.status == 401) {
          localStorage.removeItem('access_token');
          window.location.href = EP.auth.login;
          reject(error);
        } else if (response.status == 403) {
          reject(error);
        } else {
          reject(error);
        }
      });
    });
  },
  // load all possible user roles
  loadRoles({ commit, getters, rootState, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (rootGetters["users/isAdmin"]) {
        // Get validations based on view and action
        const url = APIRoute(EP.users.roles);
        axios.get(url).then((response: any) => {
          const responseData: any = response.data;
          // Pass value to mutation to place in store
          const roles = responseData && responseData.roles ? responseData.roles : null;
          commit('setRoles', roles);
          resolve();
        }).catch((error: any) => {
          console.warn(error);
          reject(error);
        });
      } else {
        commit('setRoles', []);
        resolve();
      }
    });
  },
  
  // access_token token methods
  readAccessToken({ commit }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const token = localStorage.getItem('access_token') || undefined;
      resolve();
    });
  },
  removeAccessToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // remove access token and access type when logging out
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_type');
      resolve();
    });
  },

  // store route for permissions
  setRoute({ commit }, route): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('setRoute', route);
      resolve();
    });
  },

  // send logout request
  logout({ commit, dispatch }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {
      // dispatch('removeAccessToken');
      const url = APIRoute(EP.auth.logout);
      axios.delete(url).then((response: ResponseType) => {
        const responseData = response.data;
        resolve({ success: true, responseData: responseData });
      }).catch((error: any) => {
        console.warn(error);
        reject({ success: false, message: error });
      });
    });
  },
};
