<template>
  <validation-observer ref="validations">
    <form-layout form-id="bookmark-form">
      <template v-slot:title>
        <!-- Mode indicator -->
        <legend>
          <h5 class="legend-title">
            {{ selection.isNew ? $t('new_bookmark') : $t('selected_bookmark') }}
          </h5>
        </legend>
      </template>
      <template v-slot:action>
        <!-- Action toolbar -->
        <action-toolbar
          :permittedActions="editState.permittedActions"
          :destroyTitleText="$t('delete_bookmark_title')"
          :destroyGuidingText="$t('delete_bookmark_guiding_text')"
          @destroy="onDestroy"
        />
      </template>
      <template v-slot:contents>
        <div class="row">
          <div class="form-group col-md-8 col-lg-8 col-xl-12">
            <text-area-input
              inputId="bookmark-name"
              ruleKey="bookmarks.name"
              :name="$t('name')"
              :showGuidingText="true"
              :guidingText="$t('bookmark_description_guiding_text')"
              v-model="editState.name"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-xl-4">
            <date-input
              inputId="bookmark-due-date"
              ruleKey="bookmarks.due_date"
              :name="$t('due_date')"
              v-model="editState.dueDate"
            />
          </div>
          <div class="form-group col-md-6 col-xl-8">
            <text-input
              inputId="bookmark-link"
              ruleKey="bookmarks.link"
              :name="$t('link')"
              v-model="editState.link"
            />
          </div>
        </div>
      </template>
      <template v-slot:save>
        <!-- Save toolbar -->
        <save-toolbar
          :show="true"
          ref="saveBookmark"
          :label="$t('save_bookmark')"
          :cancelButton="true"
          @save="onSave"
          @cancel="onCancel"
        />
      </template>
    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { Vue, Component, Watch, Prop } from 'vue-facing-decorator';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { i18nMessages } from '@/i18n';
import { UIBookmark } from '@/UIModels/dashboard/widgetBookmarks/bookmark';
import { SaveResult } from '@/types';
import { Form as ValidationObserver } from 'vee-validate';
import { UIWidgetBookmarks } from '@/UIModels/dashboard/widgetBookmarks';

@Component({
  components: {
    FormLayout,
    ActionToolbar,
    SaveToolbar,
    DateInput,
    TextAreaInput,
    TextInput,
  },
  ...i18nMessages([
    require('@/components/dashboard/widgets/widgetBookmarks/_locales/BookmarkForm.json'),
  ]),
  emits: [
    'success',
    'deleted',
  ],
})
export default class BookmarkForm extends Vue {
  @Prop({ required: true }) widget!: UIWidgetBookmarks;
  @Prop({ required: true }) selection!: UIListFormSelection;

  // Form component edit state
  private editState = new UIBookmark(this.widget);

  private isSuccessfullySaving = false;

  // Reference to save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveBookmark;
    if (!saveToolbar) return null;
    return saveToolbar as SaveToolbar;
  }

  // Reference to the form validations observer
  get validations(): typeof ValidationObserver {
    return this.$refs.validations as typeof ValidationObserver;
  }

  // Initialize form edit state
  private mounted(): void {
    this.resetEditState();
  }

  // Delete selected item
  public async onDestroy(): Promise<void> {
    try {
      const result: SaveResult = await this.editState.delete({ id: this.selection.id as string });
      this.$emit('deleted');
    } catch (error: unknown) {
      this.handleError(error);
    }
  }

  // Save form edit state to item
  public async onSave(): Promise<void> {
    if (this.saveToolbar) this.saveToolbar.startSaving();
    if (this.validations) {
      const result = await this.validations.validate();
      if (!result.valid) {
        this.handleError(result);
        return;
      }
    }
    try {
      const result: SaveResult = await this.editState.save({ id: this.selection.id as string });
      this.handleSuccess(result);
    } catch (error: unknown) {
      this.handleError(error);
    }
  }

  private handleSuccess(result: SaveResult): void {
    this.isSuccessfullySaving = true;
    if (this.saveToolbar) this.saveToolbar.stopSaving(result);
    this.$emit('success');
  }

  private handleError(error: unknown): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(error as SaveResult);
  }

  // Cancel form edit state, i.e. revert item or reset to empty/new
  private onCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Initialize form edit state based on selection
  private async resetEditState(): Promise<void> {
    this.editState = new UIBookmark(this.widget);
    await this.editState.load({ id: this.selection.id as string });
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Dismiss save toolbar success or error indicator
  private resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Handle selection change
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(newSelection: UIListFormSelection, oldSelection?: UIListFormSelection): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }
}
</script>
