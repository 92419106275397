import vuexStore from '@/store'; // for gradual conversion, see fullUserDetails
import axios from 'axios';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIBaseWidget, DEFAULT_WIDGET_PREFERENCES } from '@/UIModels/dashboard/baseWidget';
import { UIError } from '@/UIModels/error';
import { SaveResult } from '@/types';
import { APIDashboardPreferences } from '@/APIModels/user/preferences/types';
import { APIRoute, EP } from '@/api-endpoints';
import { APIDashboardWidget, WidgetName } from '@/APIModels/dashboardWidgets/types';
import { UIWidgetBookmarks } from '@/UIModels/dashboard/widgetBookmarks';

export const DEFAULT_DASHBOARD_PREFERENCES: APIDashboardPreferences = {
  widgets: {
    bookmarks: DEFAULT_WIDGET_PREFERENCES,
  },
};

export class UIDashboard {
  public widgets: UIBaseWidget[] = [];
  public loaded: boolean;

  get empty(): boolean {
    return this.widgets.filter((widget: UIBaseWidget) => { return widget.visible; }).length === 0;
  }

  public constructor() {
    this.loaded = false;
  }

  public async load(): Promise<void> {
    const ep = APIRoute(EP.dashboard.widgets);
    try {
      const response = await axios.get(ep);
      const apiDashboardWidgets: APIDashboardWidget[] = response.data.dashboard_widgets;
      this.widgets = apiDashboardWidgets.map((apiDashboardWidget: APIDashboardWidget): UIBaseWidget => {
        let result = new UIBaseWidget();
        switch (apiDashboardWidget.widget_name) {
          case WidgetName.Bookmarks:
            result = new UIWidgetBookmarks(apiDashboardWidget);
            break;
          default:
            result = new UIBaseWidget(apiDashboardWidget);
        }
        return result;
      });
      await this.loadAllWidgets();
    } catch (error: unknown) {
      console.warn(error);
    }

    this.loaded = true;
  }

  public async loadAllWidgets(): Promise<void> {
    Promise.all([
      ...this.widgets.map((uiWidget: UIBaseWidget) => { return uiWidget.load(); }),
    ]);
  }

  public widgetByName(name: WidgetName): UIBaseWidget {
    return this.widgets.find((uiWidget: UIBaseWidget) => { return uiWidget.name === name; }) as UIBaseWidget;
  }

  public async save(): Promise<SaveResult> {
    try {
      const result = await useCurrentPageStore().preferences.saveCustomizeDashboard(this) as SaveResult;
      await this.loadAllWidgets();
      return result;
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('dashboard', error);
    }
  }
}
