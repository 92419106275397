<template>
  <div class="col-12">
    <div class="card full">
      <div class="card-header media">
        <span class="skeleton-box w-100" style="height: 30px;" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LoadingField from '@/components/shared/LoadingField.vue';

@Component({
  components: {
    LoadingField,
  }
})
export default class LoadingWidget extends Vue {
}
</script>
