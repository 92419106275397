<template>
  <validation-provider 
    ref="provider"
    :rules="formRules" 
    :name="inputId"
    :label="name"
    :vid="validationId ? validationId : inputId" 
    v-model="validatedValue"
    v-slot="{ field, errors }">
    <span class='d-flex justify-content-between'>
      <label :for="inputId" :class="{ 'sr-only': hideLabel }">
        {{label || name}}
        <!-- don't show asterisk if disabled -->
        <validation-asterisk
          v-if="!disabled"
          :rules="formRules"
          :crossValues="crossValues"
          :ruleKey="ruleKey"
        />
      </label>
      <span v-if="maxLength > 0" class="character-counter">
        {{getCounter ? getCounter : 0}} / {{maxLength}}
      </span>
    </span>
    <small v-if="showGuidingText">{{ guidingText }}</small>

    <span class="mobile-spacing-wrapper">
      <textarea 
        ref="textarea"
        :id="inputId"
        type="text" 
        :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
        v-bind="field"
        :readonly="isReadOnly(readonly||disabled)"
        :tabindex=" readonly ? -1 : 0"
        :rows="rows"
        v-on="inputEvents()" />
      <!-- error block, not shown if disabled -->
      <div
        v-if="errors[0] && !disabled"
        class="invalid-feedback"
        :id="`${inputId}-error`"
      >
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ translateError(errors, label || name) }}
      </div>
      <div class="advisory-message" :id="`${inputId}-warning`" v-if="showAdvisoryMessage">
        <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
        {{ $t('max_Length_message', { maxLength }) }}
      </div>

    </span>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { Getter } from 'vuex-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { i18nMessages } from '@/i18n';

@Component({
  components: {
    ValidationAsterisk
  },
  emits: [
    'update:modelValue',
  ],
  ...i18nMessages([
    require('@/components/shared/_locales/TextAreaInput.json'),
  ]),

})
export default class TextAreaInput extends Vue {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;
  @Getter('maximumLength', { namespace: 'validations' }) private maximumLength!: (ruleKey: string) => number|null;

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  // Optional properties
  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: 2 }) rows!: number; // Number of rows to show
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: false }) showGuidingText!: boolean;
  @Prop({ default: null }) guidingText!: any;

  get maxLength(): number|null {
    return this.maximumLength(this.ruleKey);
  }

  // Local instance
  private validatedValue = '';

  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  get showAdvisoryMessage(): boolean {
    if (!this.maxLength) return false;
    if (!this.validatedValue) return false;
    return Number(this.validatedValue.length) === Number(this.maxLength);
  }

  get validationProvider() {
    return this.$refs.provider as any;
  }

  get getCounter(): string|undefined {
    if (this.validatedValue) {
      return (this.validatedValue.length).toString();
    }
  }

  // Modify model value based on max length
  private truncate(s: string|null): string {
    let result = s || '';
    if (!this.maxLength) return result;

    // NOTE: using spread operator to count unicode code points instead of 16-bit code units to align with back-end validations
    const codePoints = [...result];
    if (codePoints.length >= this.maxLength) {
      result = codePoints.slice(0, this.maxLength).join('');
      this.validationProvider.reset({ value: result });
    }
    return result;
  }

  mounted(): void {
    this.validationProvider.reset({ value: this.truncate(this.modelValue) });
  }

  @Watch('modelValue')
  onModelValue(): void {
    this.validationProvider.reset({ value: this.modelValue });
  }

  // Forward events to the parent component
  public inputEvents(): any {
    const _vm = this as TextAreaInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        // custom listeners
        input(event: any) {
          // Emit updated value for v-model
          _vm.$emit('update:modelValue', _vm.truncate(event.target.value));
        }
      }
    );
  }
}
</script>
