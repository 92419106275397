<template>
  <sticky-summary v-if="uiRecipientSummary">
    <template v-slot:summaryContent>
      <ul class="recipient-summary">
        <li class="list-inline-item" v-if="isBasicNameEnabled">
          <span>{{ $t('name') }}: <b>{{ uiRecipientSummary.summaryName }} <span v-if="uiRecipientSummary.summaryStatus">{{ $t(uiRecipientSummary.summaryStatus) }}</span></b></span>
        </li>
        <li class="list-inline-item">
          <span>{{ $t('client_id') }}: <b>{{ uiRecipientSummary.clientId }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isDateOfBirthEnabled">
          <span>{{ $t('dob') }}: <b>{{ uiRecipientSummary.dateOfBirth || '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isDateOfBirthEnabled">
          <span>{{ $t('age') }}: <b>{{ uiRecipientSummary.age != null ? uiRecipientSummary.age : '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isSexEnabled">
          <span>{{ $t('sex') }}: <b>{{ uiRecipientSummary.sex || '-' }}</b></span>
        </li>
        <li class="list-inline-item" v-if="isBloodTypeEnabled">
          <span>{{ $t('abo') }}: <b>{{ uiRecipientSummary.bloodType || '-' }}</b></span>
       </li>
      </ul>
      <div class="sticky-organ-summary-area" v-if="(uiRecipientSummary.activeJourneys.length + uiRecipientSummary.postTransplantJourneys.length) > 0">
        <!-- Active Journeys -->
        <div class="organ-summary" v-if="uiRecipientSummary.activeJourneys.length > 0">
          <label for="sticky-summary-active-journeys">{{ $t('active_journeys') }}</label>
          <ul id="sticky-summary-active-journeys" class="organ-summary">
            <li v-for="(uiJourneySummary, idx) in uiRecipientSummary.activeJourneys" :key="idx" class="list-inline-item " :class="$route.params.organ_id == uiJourneySummary.id ? 'active' : ''">
              <router-link :to="{ name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }" class="nav-link">
                <organ-icon :organ-code="uiJourneySummary.organCode" :summary-bar-icon="true" />
                {{ $t(journeyName(uiJourneySummary.organCode, uiJourneySummary.id)) }}
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Post-Transplant Journeys -->
        <div class="organ-summary" v-if="uiRecipientSummary.postTransplantJourneys.length > 0">
          <label for="sticky-summary-post-transplant-journeys">{{ $t('post_transplant_journeys') }}</label>
          <ul id="sticky-summary-post-transplant-journeys" class="organ-summary">
            <li v-for="(uiJourneySummary, idx) in uiRecipientSummary.postTransplantJourneys" :key="idx" class="list-inline-item " :class="$route.params.organ_id == uiJourneySummary.id ? 'active' : ''">
              <router-link :to="{ name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }" class="nav-link">
                <organ-icon :organ-code="uiJourneySummary.organCode" :summary-bar-icon="true" />
                {{ $t(journeyName(uiJourneySummary.organCode, uiJourneySummary.id)) }}
              </router-link>
              ({{$t('transplanted')}}&nbsp;{{ uiJourneySummary.transplantedDate || '-'}})
            </li>
          </ul>
        </div>
      </div>
    </template>
  </sticky-summary>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Component } from 'vue-facing-decorator';
import StickySummary from '@/components/shared/StickySummary.vue';
import { Getter, State } from 'vuex-facing-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';
import OrganIcon from '@/components/shared/OrganIcon.vue';
import { i18nMessages } from "@/i18n";
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipientSummary } from "@/UIModels/recipients/recipientSummary";
import { UIConfiguration } from "@/UIModels/configuration";
import { DATA_BUNDLES } from "@/UIModels/configuration/features";

@Component({
  components: {
    StickySummary,
    OrganIcon
  },
  ...i18nMessages([
    require('../_locales/Organs.json'),
    require('@/components/recipients/_locales/RecipientStickySummary.json'),
  ]),
})
export default class RecipientStickySummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter("journeyName", { namespace: "recipients" }) journeyName!: (organCode?: number, journeyId?: string) => string;

  // Get view model from shared client state in pinia store
  get uiRecipientSummary(): UIRecipientSummary {
    return useCurrentPageStore().currentRecipient?.summary as UIRecipientSummary;
  }

  get isBasicNameEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientNameBasic);
  } 

  get isDateOfBirthEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientDOBAge);
  }

  get isSexEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.demographics.bundles.isEnabled(DATA_BUNDLES.PatientSex);
  }

  get isBloodTypeEnabled(): boolean {
    return useCurrentPageStore().configuration.features.recipientConfig.recipientGeneralClinical.bloodType.enabled;
  }
}
</script>
