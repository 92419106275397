<template>
  <validation-observer ref="discontinueValidations" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
    <modal-section
      modalId="discontinue-organs-modal"
      ref="discontinueOrgansModal"
      :centered="true"
      size="md"
      :closeButton="false"
    >
      <template v-slot:title>
        {{ $t('discontinue_allocation_recommendation') }}
      </template>
        <template v-slot:body v-if="editState">
          <p>
            {{ editState.modalType == 1 ? $t('confirm_discontinue_all_allocations_for_donor') : $t('discontinue_allocation_recommendation') }}
          </p>
          <template v-if="!!modalErrorMessage">
            <div class="alert alert-danger">
              {{modalErrorMessage}}
            </div>
          </template>
          <div class="hr-break" />
          <div class="row">
            <div class="form-group col-sm-4">
              <select-input
                selectId="discontinue-type"
                :name="$t('discontinue_reason')"
                rules="required"
                v-model="editState.reason"
                :options="editState.discontinueReasons"
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="modal-footer-body">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-secondary"
              @click="closeModal()"
              :disabled="isLoadingAllocation || isDiscontinuingAllAllocations || isDiscontinuingOneAllocation"
            >
              {{ $t('cancel') }}
            </button>
            <button
              type=button 
              class="btn btn-success"
              @click="handleSubmit(performDiscontinue)"
              :disabled="isLoadingAllocation || isDiscontinuingAllAllocations || isDiscontinuingOneAllocation"
            >
              {{ $t('discontinue_donor_allocations') }}
              <span class="pl-2" v-if="isDiscontinuingAllAllocations || isDiscontinuingOneAllocation">
                <font-awesome-icon class="fa-spin" :icon="['far', 'spinner-third']" />
              </span>
            </button>
          </div>
        </template>
    </modal-section>
  </validation-observer>
</template>

<i18n src="./_locales/common.json"></i18n>
<i18n src="./_locales/_DiscontinueOrgansModal.json"></i18n>
<i18n src="@/components/allocations/_locales/_CTRIntegrationWorkflows.json"></i18n>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import ModalSection from '@/components/shared/ModalSection.vue';
import { discontinueAllocationReason, discontinueAllocationLookupType } from '@/store/lookups/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Allocation, Allocations, AllocationRecipient, OfferOutcomeContext, DISCONTINUE_ALLOCATION } from '@/store/allocations/types';
import { CtrErrorContext } from '@/types';
import { organIposProgram } from '@/store/allocations/getters';

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class DiscontinueOrgansModal extends mixins(AllocationErrorsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isDiscontinuingAllAllocations) private isDiscontinuingAllAllocations!: boolean;
  @State(state => state.allocations.isDiscontinuingOneAllocation) private isDiscontinuingOneAllocation!: boolean;
  @State(state => state.pageState.currentPage.discontinueAllocationState) private editState!: any;
  @State(state => state.allocations.selected) private allocation!: Allocation;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private clientId!: string;
  @Getter('getDiscontinueAllocationReasons', { namespace: 'lookups' }) private getDiscontinueAllocationReasons!: (type: string) => discontinueAllocationReason[];
  @Getter('parseCtrErrors', { namespace: 'allocations' }) private parseCtrErrors!: (actions: any[]) => CtrErrorContext[];

  @Prop({ required: true }) discontinueType!: string; // MANDATORY discontinue lookup type

  public modalErrorMessage = '';

  /**
   * Return the organ code
   *
   * Get the organ_code param from the url.
   *
   * @returns {string} organ code
   */
  get organCode(): string {
    return this.$route.params?.organ_code?.toString() || '';
  }

  // Toggle modal
  public openModal(): void {
    const targetModal = this.$refs.discontinueOrgansModal as ModalSection;
    targetModal.toggleStaticModal();
  }

  // Close modal
  private closeModal(): void {
    const targetModal = this.$refs.discontinueOrgansModal as ModalSection;
    targetModal.hideModal();
  }

  /**
   * Populates state with reason state
   */
  public initialize(): void {
    // clear any error message
    this.modalErrorMessage = '';
    // build state from valid ids
    this.$store.commit('pageState/set', {
      pageKey: 'discontinueAllocationState',
      value: { 
        reason: null,
        discontinueReasons: this.getDiscontinueAllocationReasons(this.discontinueType),
        modalType: this.discontinueType
      }
    });
    // reset the form
    (this.$refs.discontinueValidations as any).setErrors({});
    // open modal
    this.openModal();
  }

  // PRIVATE

  private performDiscontinue(): void {
    // Before discontinue, provide an alert/confirm dialogue
    const confirmed = confirm(this.$t('DISCONTINUE_DONOR_CONFIRM').toString());
    if (!confirmed) {
      return;
    }
    switch (this.editState.modalType) {
      case discontinueAllocationLookupType.DonorRelated:
        this.discontinueOrgans();
        break;
      case discontinueAllocationLookupType.OfferRelated:
        this.discontinueAllocation();
        break;
      default:
        this.discontinueAllocation();
        break;
    }
  }

  /**
   * Attempt to discontinue all organs
   */
  private discontinueOrgans(): void {
    // Create a payload for discontinuing
    const payload = {
      clientId: this.clientId,
      reasonId: this.editState.reason
    };
    // Attempt to discontinue the all Organs
    this.$store.dispatch('allocations/discontinueOrgans', payload).then((success: any) => {
      this.closeModal();
    }).catch((error: any) => {
      this.modalErrorMessage = error;
    });
  }

  // Discontinue allocation and cancel/withdraw all existing offers
  private discontinueAllocation(): void {
    // Create a payload for discontinuing
    const payload = {
      clientId: this.clientId,
      organCode: this.organCode,
      allocationId: this.allocation._id,
      reasonId: this.editState.reason
    };
    // Attempt to discontinue the Allocation
    this.$store.dispatch('allocations/discontinueAllocation', payload).then((success: any) => {
      this.closeModal();
      // Do we need to show a secondary warning outcome popup?
      if (this.isOutcomeNotificationRequired(success)) this.displayOutcomeNotification(success);
    }).catch((error: any) => {
      this.modalErrorMessage = error;
    });
  }

  // Which inter-provincial organ sharing program(s) was just discontinued?
  get discontinuedAllocationIposProgram(): string {
    const pageOrganCode = parseFloat(this.organCode);
    return organIposProgram(pageOrganCode);
  }

  /*
   * Do we need to show a CTR error workflow pop-up?
   *
   * @param response the response payload received from API after discontinue the allocation(s)
   * @returns {boolean} true only if we need a CTR error workflow pop-up
   */
  private isOutcomeNotificationRequired(response: any): boolean {
    const ctrErrors = this.parseCtrErrors(response?.data?.allocation?.actions || []);
    return ctrErrors.length > 0;
  }


  // Define what is needed for the CTR error workflow and emit an event
  private displayOutcomeNotification(response: any, isHardStopError?: boolean): void {
    // This CTR error workflow will be based on errors returned by CTR when syncing HSP offer discontinues
    const ctrErrors = this.parseCtrErrors(response?.data?.allocation?.actions || []);
    const warningMessages = ctrErrors.map((warning: CtrErrorContext): string => {
      const warningKey = `warning.${this.discontinuedAllocationIposProgram}.${warning.ctr_error_id}`;
      return this.$te(warningKey) ? this.$t(warningKey).toString() : warning.ctr_error_message;
    });
    // Fetch CTR workflow instructions if there are any
    const instructionsTemplates = ctrErrors.map((ctrError: CtrErrorContext): string => {
      const instructionsKey = `instructions.${this.discontinuedAllocationIposProgram}.${ctrError.ctr_error_id}`;
      return this.$te(instructionsKey) ? this.$t(instructionsKey).toString() : this.$t('instructions.generic').toString();
    });

    const context: OfferOutcomeContext = {
      actionId: DISCONTINUE_ALLOCATION,
      ctrErrors,
      warningMessages,
      instructionsTemplates,
      isHardStopError,
    };

    this.$emit('display-outcome-notification', context);
  }
}
</script>
