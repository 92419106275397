import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    id: "side-nav",
    class: _normalizeClass([{ active: _ctx.isVisible, footerIsVisible: _ctx.footerIsVisible }, "page-nav"]),
    style: _normalizeStyle({ 'top': _ctx.topPosition + 'px' })
  }, [
    _renderSlot(_ctx.$slots, "side-nav-content")
  ], 6))
}