// dashboard widget names as found in /dashboard_widgets response
export enum WidgetName {
  Bookmarks = 'bookmarks',
}

// per-widget details in one /dashboard_widgets response item
export interface APIDashboardWidget {
  widget_name: WidgetName;
  required: boolean;
}
