<template>
  <base-widget title="Reminders" :widget="uiWidget">
    <template v-slot:icon>
      <div class="circle dashboard-icon circle-active-donor">
        <font-awesome-icon :icon="['fas', 'user']" />
      </div>
    </template>
    <template v-slot:linkID>
      <widget-links options-id="idBookmarks" section-id="bodyBookmarks" />
    </template>
    <template v-slot:options>
      <widget-options id="idBookmarks" :widget="uiWidget" />
    </template>
    <template v-slot:widget-contents>
      <widget-section id="bodyBookmarks">
        <template v-slot:body>
          <table-toolbar
            :createButton="tableConfig.createButton"
            :createText="tableConfig.createText"
            @table-create-row="clearForm()"
          />
          <proto-table-list
            table-id="reminders-table"
            ref="remindersWidgetTable"
            tabbableColumn="createdAtDisplay"
            :table-config="tableConfig"
            @table-row-click="editSelected"
            :highlightSelection="true"
          />
          <form-layout
            form-id="protowidgetreminders-form">
            <template v-slot:title>
              <!-- Mode indicator / subsection form title -->
              <legend>
                <h5 class="legend-title">
                  {{ isNew ? 'New Reminder' : 'Selected Reminder' }}
                </h5>
              </legend>
            </template>
            <template v-slot:contents>

              <div class="row">
                <div class="form-group col-md-8 col-lg-8 col-xl-8">
                  <proto-text-area-input
                    inputId="reminders-description"
                    name="Description"
                    :maxLength="500"
                    :showGuidingText="true"
                    guidingText="Include patient name and MRN where applicable."
                    v-model="editState.description"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group">
                    <date-input
                      inputId="reminders-due-date"
                      name='Due Date'
                      v-model="editState.due_date"   
                    />
                  </div>
                <div class="standard-form-group">
                  <text-input
                    inputId="bookmark-link"
                    :name="$t('link')"
                    v-model="editState.link"
                  />
                </div>
              </div>

            </template>
            <template v-slot:save>
              <save-toolbar
                :show="true"     
                ref="saveReminders"
                @save="performSave"
                label="Save Reminder"
                :cancelButton="true"
                @cancel="clearForm()"
              />
            </template>
          </form-layout>
    
        </template>
      </widget-section>
    </template>
  </base-widget>
</template>

<script lang="ts">
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import TextInput from '@/components/shared/TextInput.vue';
import WidgetSection from '@/components/dashboard/widgets/shared/WidgetSection.vue';
import WidgetOptions from '@/components/dashboard/widgets/shared/WidgetOptions.vue';
import WidgetLinks from '@/components/dashboard/widgets/shared/WidgetLinks.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import ProtoTableList from '@/prototypes/shared/ProtoTablelist.vue';
import BaseWidget from '@/components/dashboard/widgets/_BaseWidget.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { TableConfig } from '@/types';
import { i18nMessages } from '@/i18n';
import { Component, Vue } from 'vue-facing-decorator';

import { WidgetName } from '@/APIModels/dashboardWidgets/types';
import { UIWidgetBookmarks } from '@/UIModels/dashboard/widgetBookmarks';
import { parseDisplayDateUiFromDateTime } from '@/utilities/date-utils';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import ProtoTextAreaInput from '@/prototypes/shared/ProtoTextAreaInput.vue';
import DateInput from "@/components/shared/DateInput.vue";
interface ReminderWidgetRow {
  id: string;
  createdAtObject: Date|null;
  createdAtDisplay: string;
  name: string;
  linkHtml: string;
}

interface ReminderWidgetForm {
  id: string;
  created_at: number | Date | null;
  description: string,
  due_date: Date|null;
  link: string,
}

const EDITSTATE_KEY = 'editState';
const LINK_TRUNCATION_LIMIT = 300;

@Component({
  components: {
    WidgetSection,
    WidgetOptions,
    WidgetLinks,
    TableToolbar,
    ProtoTableList,
    FormLayout,
    BaseWidget,
    SaveToolbar,
    TextInput,
    ProtoTextAreaInput,
    DateInput
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('@/components/dashboard/widgets/_locales/WidgetBookmarks.json'),
  ]),
  emits: [
    'reloadDashboard',
  ]
})
export default class WidgetBookmarks extends Vue {
  public currentPage = 1;
  public perPage = 5;
  public errorMessage = null;
  private isNew = true;

  

  // Selection instance to provide to child form component
  private selection = new UIListFormSelection();

  private reminders: any = [];

    // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

    // Form component edit state
  private editState: ReminderWidgetForm = {
    created_at: null,
    id: '',
    description: "",
    due_date: null,
    link: ''
  };


  get tableRows() {
    const reminders = this.reminders || [];

    if (reminders.length > 0) {

    return reminders.map((reminder: any) => {

      return {
        id: reminder.id,
        createdAtDisplay: parseDisplayDateUiFromDateTime(reminder.created_at) || '-',
        dueDateDisplay: parseDisplayDateUiFromDateTime(reminder.due_date) || '-',
        dueDate: reminder.due_date,
        description: reminder.description,
        link: reminder.link,
        linkHtml: reminder.link,
      };
    });
  }
  }

  // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
  async mounted() {
    // No-op if prototypes not enabled
    if (!useCurrentPageStore().configuration.prototypes) return;

    // Make a per-recipient prototype bucket
    this.protoBucket = new UIPrototypeBucket(PROTOTYPES.WidgetReminders, { recipientId: this.$route.params.id });

    // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
    const response = await this.protoBucket.getItem(EDITSTATE_KEY);
    if (!response) return;

    this.reminders = response;
  }

  get uiWidget(): UIWidgetBookmarks {
    return useCurrentPageStore().dashboard.widgetByName(WidgetName.Bookmarks) as UIWidgetBookmarks;
  }
  // Reference to table
  get remindersWidgetTable(): any {
    return this.$refs.remindersWidgetTable as any ;
  }

  // Each save toolbar will need a unique ref
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveReminders as SaveToolbar;
  }

  private editSelected(row: any) {
      if (row) {  
        this.editState.id = row.row.id;
        this.editState.description = row.row.description;
        this.editState.due_date = row.row.dueDate;
        this.editState.link = row.row.link;
        this.isNew = false;
      }
    }


  public clearForm(): any {
      this.isNew = true;
      this.editState.id = '',
      this.editState.description = '';
      this.editState.due_date = null;
      this.editState.link = '';
    }


  public performSave(): any {
    // Call the 'startSaving' method to begin save animation
    if (this.saveToolbar) this.saveToolbar.startSaving();

    let reminders = this.reminders || [];

    const payload: ReminderWidgetForm = {  
      id: Math.random().toString(16).slice(2),
      created_at:  new Date(),
      description: this.editState.description,
      due_date: this.editState.due_date,
      link: this.editState.link
    };

    if (!this.isNew ){  
      payload.id = this.editState.id;
      const foundIndex = reminders.findIndex((reminder: any) => reminder.id == this.editState.id);
      
      if (foundIndex != -1) {
        reminders[foundIndex] = payload;
      }
    } else {  
      reminders.push(payload);
    }

    this.protoBucket.setItem(EDITSTATE_KEY, reminders);

    // Call the 'showFakeSuccess' method to complete successful save animation
    if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    this.clearForm();
  } 

  // Table row click
  public onTableRowClick(event: any) {
    // const listItem = this.uiBookmarkEntries.find((uiBookmarkEntry: UIBookmark) => { return uiBookmarkEntry.id === event.row.id; });
    // if (!listItem) return;

    // this.selection = new UIListFormSelection(listItem.id);
  }

  // Saved successfully
  public onSaveSuccess() {
    this.remindersWidgetTable.resetSelection();
    this.selection = new UIListFormSelection();
  }

  // Deleted item
  public onDeleted() {
    this.remindersWidgetTable.resetSelection();
    this.selection = new UIListFormSelection();
  }
  /**
   * Gets configuration for the table
   *
   * @returns {TableConfig} Configuration for the table
   */
  get tableConfig(): TableConfig {
    return {
      data: this.tableRows || [],
      columns: [
        { label: this.$t('created_at').toString(), field: 'createdAtDisplay' },
        { label: 'Due Date', field: 'dueDateDisplay' },
        { label: 'Description', field: 'description' },
        { label: 'Link to Page', field: 'linkHtml', htmlLink: true},
      ],
      empty: 'Use the form below to add a new Reminder',
      createButton: true,
      createText: "Create Reminder",
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPage: this.perPage,
        setCurrentPage: this.currentPage,
        mode: 'pages',
        perPageDropdown: [10, 25, 50],
        dropdownAllowAll: false,
        nextLabel: '',
        prevLabel: '',
        rowsPerPageLabel: this.$t('results_per_page').toString(),
        position: 'bottom'
      }
    };
  }
}
</script>

<style>
.form-control {
  margin-top: 0.375rem;
  }</style>
