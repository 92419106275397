import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "card summary-card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "media align-items-center" }
const _hoisted_4 = { class: "media-left" }
const _hoisted_5 = { class: "media-body flex-row align-items-center" }
const _hoisted_6 = { class: "summary-name" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "nav card-nav" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "dropdown" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "summary-list" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "secondary-title font-weight-bold"
}
const _hoisted_21 = ["aria-label"]
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "summary-organ" }
const _hoisted_24 = { class: "organ-summary-wrap" }
const _hoisted_25 = { class: "organ-summary-list" }
const _hoisted_26 = { class: "organ-summary-list" }
const _hoisted_27 = {
  key: 0,
  class: "organ-summary-list"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = {
  key: 1,
  class: "title"
}
const _hoisted_30 = ["aria-label"]
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "summary-organ" }
const _hoisted_33 = { class: "organ-summary-wrap" }
const _hoisted_34 = { class: "organ-summary-list" }
const _hoisted_35 = { key: 2 }
const _hoisted_36 = {
  key: 3,
  class: "title"
}
const _hoisted_37 = ["aria-label"]
const _hoisted_38 = { class: "row" }
const _hoisted_39 = { class: "summary-organ" }
const _hoisted_40 = { class: "organ-summary-wrap" }
const _hoisted_41 = { class: "organ-summary-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_summary_card = _resolveComponent("loading-summary-card")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_new_organ_link = _resolveComponent("new-organ-link")!
  const _component_organ_icon = _resolveComponent("organ-icon")!

  return (_ctx.isLoadingLookups || !_ctx.uiRecipientSummary)
    ? (_openBlock(), _createBlock(_component_loading_summary_card, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'id-card'],
                "fixed-width": ""
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.uiRecipientSummary.summaryName) + " ", 1),
                (_ctx.uiRecipientSummary.summaryStatus)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(_ctx.uiRecipientSummary.summaryStatus)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("ul", _hoisted_8, [
                _createElementVNode("li", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    to: { name: 'edit-recipient', params: { id: _ctx.uiRecipientSummary.clientId } },
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('recipient_information')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    to: { name: 'recipient-hla' },
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('hla_information')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_new_organ_link, { navlink: true })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("dl", null, [
              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('client_id')), 1),
              _createElementVNode("dd", null, _toDisplayString(_ctx.uiRecipientSummary.clientId), 1)
            ]),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_15, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('dob')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiRecipientSummary.dateOfBirth || '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isDateOfBirthEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_16, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('age')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiRecipientSummary.age != null ? _ctx.uiRecipientSummary.age : '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isSexEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_17, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('sex')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiRecipientSummary.sex || '-'), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.isBloodTypeEnabled)
              ? (_openBlock(), _createElementBlock("dl", _hoisted_18, [
                  _createElementVNode("dt", null, _toDisplayString(_ctx.$t('abo')), 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.uiRecipientSummary.bloodType || '-'), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.uiRecipientSummary.activeJourneys.length > 0)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_19))
            : _createCommentVNode("", true),
          (_ctx.uiRecipientSummary.activeJourneys.length>0)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_20, _toDisplayString(_ctx.$t('active_journeys')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "summary-items",
            "aria-label": _ctx.$t('active_journeys')
          }, [
            _createElementVNode("div", _hoisted_22, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiRecipientSummary.activeJourneys, (uiJourneySummary, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "summary-journey-item summary-item",
                  key: idx
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass({ ...uiJourneySummary.summaryTileClass, active: _ctx.$route.params.organ_id == uiJourneySummary.id })
                  }, [
                    _createVNode(_component_router_link, {
                      class: "organ-row",
                      to: { name: 'edit-organ', params: { organ_id: uiJourneySummary.id } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_organ_icon, {
                          "organ-code": uiJourneySummary.organCode
                        }, null, 8, ["organ-code"]),
                        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t(_ctx.journeyName(uiJourneySummary.organCode, uiJourneySummary.id))), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("dl", _hoisted_25, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('phase')) + ":", 1),
                        _createElementVNode("dd", null, [
                          _createTextVNode(_toDisplayString(_ctx.lookupValue(uiJourneySummary.phase, 'recipient_journey_phase_status')) + " ", 1),
                          _createElementVNode("span", null, _toDisplayString(uiJourneySummary.phaseEntryDate), 1)
                        ])
                      ]),
                      _createElementVNode("dl", _hoisted_26, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('status')) + ":", 1),
                        _createElementVNode("dd", null, _toDisplayString(_ctx.lookupValue(uiJourneySummary.status, `recipient_journey_phase_status/${uiJourneySummary.phase}/statuses`)), 1)
                      ]),
                      (uiJourneySummary.reason)
                        ? (_openBlock(), _createElementBlock("dl", _hoisted_27, [
                            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('reason')) + ":", 1),
                            _createElementVNode("dd", null, _toDisplayString(_ctx.lookupValue(uiJourneySummary.reason, `recipient_journey_phase_status/${uiJourneySummary.phase}/statuses/${uiJourneySummary.status}/reasons`)), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ], 2)
                ]))
              }), 128))
            ]),
            (_ctx.uiRecipientSummary.postTransplantJourneys.length > 0)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_28))
              : _createCommentVNode("", true),
            (_ctx.uiRecipientSummary.postTransplantJourneys.length > 0)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_29, _toDisplayString(_ctx.$t('post_transplant_journeys')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "summary-items",
              "aria-label": _ctx.$t('post_transplant_journeys')
            }, [
              _createElementVNode("div", _hoisted_31, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiRecipientSummary.postTransplantJourneys, (uiJourneySummary, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "summary-journey-item summary-item",
                    key: idx
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass({ ...uiJourneySummary.summaryTileClass, active: _ctx.$route.params.organ_id == uiJourneySummary.id })
                    }, [
                      _createVNode(_component_router_link, {
                        to: { name: 'edit-organ', params: { organ_id: uiJourneySummary.id } },
                        class: "organ-row"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_organ_icon, {
                            "organ-code": uiJourneySummary.organCode
                          }, null, 8, ["organ-code"]),
                          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t(_ctx.journeyName(uiJourneySummary.organCode, uiJourneySummary.id))), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("dl", _hoisted_34, [
                          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('transplant_date')) + ":", 1),
                          _createElementVNode("dd", null, _toDisplayString(uiJourneySummary.transplantedDate || '-'), 1)
                        ])
                      ])
                    ], 2)
                  ]))
                }), 128))
              ])
            ], 8, _hoisted_30),
            (_ctx.uiRecipientSummary.closedJourneys.length > 0)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_35))
              : _createCommentVNode("", true),
            (_ctx.uiRecipientSummary.closedJourneys.length > 0)
              ? (_openBlock(), _createElementBlock("h5", _hoisted_36, _toDisplayString(_ctx.$t('closed_journeys')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "summary-items",
              "aria-label": _ctx.$t('closed_journeys')
            }, [
              _createElementVNode("div", _hoisted_38, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uiRecipientSummary.closedJourneys, (uiJourneySummary, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "summary-journey-item summary-item",
                    key: idx
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass({ ...uiJourneySummary.summaryTileClass, active: _ctx.$route.params.organ_id == uiJourneySummary.id })
                    }, [
                      _createVNode(_component_router_link, {
                        to: { name: 'edit-organ', params: { organ_id: uiJourneySummary.id } },
                        class: "organ-row"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_organ_icon, {
                            "organ-code": uiJourneySummary.organCode
                          }, null, 8, ["organ-code"]),
                          _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t(_ctx.journeyName(uiJourneySummary.organCode, uiJourneySummary.id))), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"]),
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("dl", _hoisted_41, [
                          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('closed_date')) + ":", 1),
                          _createElementVNode("dd", null, _toDisplayString(uiJourneySummary.closedDate || '-'), 1)
                        ])
                      ])
                    ], 2)
                  ]))
                }), 128))
              ])
            ], 8, _hoisted_37)
          ], 8, _hoisted_21)
        ])
      ]))
}