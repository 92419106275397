import { APIDemographicsConfig, APIRegistrationInfoConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig, UIBundleConfig } from "@/UIModels/configuration/features";

export class UIDemographicsConfig {
  public enabled: boolean;
  public registrationInfo: UIRegistrationInfoConfig;
  public personalInfo: UIFeatureConfig;
  public financialAssistance: UIFeatureConfig;
  public bundles: UIBundleConfig;

  public constructor(apiSource: APIDemographicsConfig) {
    this.enabled = apiSource.enabled;
    this.registrationInfo = new UIRegistrationInfoConfig(apiSource.registration_info);
    this.personalInfo = new UIFeatureConfig(apiSource.personal_info);
    this.financialAssistance = new UIFeatureConfig(apiSource.financial_assistance);
    this.bundles = new UIBundleConfig(apiSource.bundles);
  }
}

export class UIRegistrationInfoConfig {
  public enabled: boolean;
  public urgentRecipient: UIFeatureConfig;

  public constructor(apiSource: APIRegistrationInfoConfig) {
    this.enabled = apiSource.enabled;
    this.urgentRecipient = new UIFeatureConfig(apiSource.urgent_recipient);
  }
}