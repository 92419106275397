import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "proto-tooltip" }
const _hoisted_2 = { class: "tooltip-box" }
const _hoisted_3 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        class: "tooltip-toggle"
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "text-grey",
          icon: ['far', 'exclamation-circle'],
          "fixed-width": ""
        }),
        (_ctx.showLabel)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.labelText), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", {
        class: "tooltip",
        id: `${_ctx.inputID}-tooltip-tip`,
        "aira-role": "tooltip"
      }, [
        _createElementVNode("span", {
          id: `${_ctx.inputID}-tooltip`,
          class: "tooltip-text"
        }, _toDisplayString(_ctx.getText), 9, _hoisted_5)
      ], 8, _hoisted_4)
    ])
  ]))
}