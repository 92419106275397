<template>
  <card-section
    section-id="recipient-death"
    :lookupsToLoad="lookupsToLoad"
    @loaded="loaded()"
    >
    <template v-slot:header>
      {{ $t('title') }}
    </template>
    <template v-slot:body>
      <sub-section
        :title="$t('record_recipient_death')"
        sub-section-id="record-recipient-death"
      >
        <template v-slot:contents>
          <loading-fields v-if="isLoading || !editState" />
          <validation-observer ref="validations">
          <form-layout form-id="recipientDeath-form" :disabled="newRecipient || !canSave">
            <template v-slot:contents>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('recipient_death.death_date')">
                  <date-input
                    ruleKey="recipient_death.death_date"
                    inputId="recipientDeath-death_date"
                    :name="$t('death_date')"
                    v-model="editState.deathDate"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('recipient_death.estimated_death_date')">
                  <checkbox-input
                    ruleKey="recipient_death.estimated_death_date"
                    input-id='recipientDeath-estimated'
                    :labelName="$t('estimated')"
                    :label="$t('yes')"
                    v-model="editState.estimated"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group" v-if="checkPropExists('recipient_death.cause_category_code')">
                  <select-input
                    ruleKey="recipient_death.cause_category_code"
                    selectId="recipientDeath-death_category"
                    :name="$t('death_category')"
                    v-model="editState.causeOfDeathCategory"
                    :options="causeOfDeathCategoryLookup"
                    @change="clearCauseOfDeathType"
                  />
                </div>
                <div class="standard-form-group" v-if="!showCauseOfDeathTypeOther && checkPropExists('recipient_death.cause_type_code')">
                  <select-input
                    ruleKey="recipient_death.cause_type_code"
                    :disabled="causeOfDeathTypeDisabled || causeOfDeathTypeLookup.length === 0"
                    select-id="recipientDeath-death_type"
                    :name="$t('death_type')"
                    v-model="editState.causeOfDeathType"
                    :options="causeOfDeathTypeLookup"
                  />
                </div>
                <div class="standard-form-group" v-if="showCauseOfDeathTypeOther && checkPropExists('recipient_death.cause_type_other')">
                  <text-input
                    ruleKey="recipient_death.cause_type_other"
                    input-id="recipientDeath-death_type_other"
                    :name="$t('death_type_other')"
                    v-model="editState.causeOfDeathTypeOther"
                  />
                </div>
                <div class="standard-form-group" v-if="checkPropExists('recipient_death.related_to_waittime')">
                  <boolean-radio-input
                    ruleKey="recipient_death.related_to_waittime"
                    input-id='recipientDeath-related_to_waittime'
                    :labelName="$t('related_to_waittime')"
                    :acceptId="true"
                    :declineId="false"
                    :acceptLabel="$t('yes')"
                    :declineLabel="$t('no')"
                    v-model="editState.relatedToWaitTime"
                  />
                </div>
              </div>
              <div class="row">
                <div class="standard-form-group-large" v-if="checkPropExists('recipient_death.comments')">
                  <text-area-input
                    ruleKey="recipient_death.comments"
                    inputId="recipientDeath-comments"
                    :name="$t('comments')"
                    v-model="editState.comment"
                  />
                </div>
              </div>
            </template>

            <template v-slot:save>
              <save-toolbar
                :show="!newRecipient && canSave"
                :disabled="!canSave"
                ref="saveRecipientDeath"
                :label="$t('save_button')"
                @save="savePatch()"
                :cancelButton="true"
                @cancel="cancelPatch()"
              />
            </template>
          </form-layout>
          </validation-observer>
        </template>
      </sub-section>
    </template>
  </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { SaveResult } from '@/types';
import { Component, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import { NumericCodeValue } from '@/store/types';
import { EP } from '@/api-endpoints';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { UIRecipient } from '@/UIModels/recipient';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIDeathDetails } from "@/UIModels/recipients/deathDetails";
import LoadingFields from '@/components/shared/LoadingFields.vue';
import { i18nMessages } from "@/i18n";
import { parseFormErrors } from '@/utils';

@Component({
  components: {
    CardSection,
    SubSection,
    TextInput,
    DateInput,
    TimeInput,
    SelectInput,
    CheckboxInput,
    TextAreaInput,
    BooleanRadioInput,
    FormLayout,
    SaveToolbar,
    LoadingFields,
  },
  ...i18nMessages([
    require('./_locales/common.json'),
    require('./_locales/RecipientDeath.json'),
  ]),
  emits: [
    'loaded',
  ],
})
export default class RecipientDeath extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.cause_of_death_category) private causeOfDeathCategoryLookup!: any[];

  @Getter('clientId', { namespace: 'recipients' }) clientId!: string|undefined;
  @Getter("causeOfDeathTypeLookup", { namespace: "lookups" }) causeOfDeathType!: (deathCode: string | undefined) => NumericCodeValue[] | undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('checkPropExists', { namespace: 'validations' }) checkPropExists!: (ruleKey: string) => boolean;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['cause_of_death_category'];

  private isLoading = true;
  private isLoadingDeathData = true;
  private isLoadingDeathRules = true;

  // Immutable view model based on current recipient (e.g for cancel)
  private selection = UIDeathDetails.buildNew();

  // Editable view model for the form
  private editState = this.selection.copyViewModel();

  /**
   * Returns an array of options for cause of death type
   *
   *
   * @returns {NumericCodeValue[]|[]} cause of death type lookups
   */
  get causeOfDeathTypeLookup(): NumericCodeValue[] {
    const deathCode = this.editState.causeOfDeathCategory;
    if (!deathCode) {
      return [];
    }
    // Fetch appropriate options
    return this.causeOfDeathType(deathCode) || [];
  }

  // Checks when to disable the Cause of Death Type field
  get causeOfDeathTypeDisabled(): boolean {
    if (!this.editState.causeOfDeathCategory || this.editState.causeOfDeathCategory == '' || this.showCauseOfDeathTypeOther) {
      this.editState.causeOfDeathType = null;
      return true;
    } else {
      return false;
    }
  }

  // Checks when to show the Cause of Death Type Other field
  get showCauseOfDeathTypeOther(): boolean {
    const deathCode = this.editState.causeOfDeathCategory;

    if (!deathCode) {
      return false;
    }

    if (!this.causeOfDeathCategoryLookup) {
      return false;
    }

    const deathCategory = this.causeOfDeathCategoryLookup.find((deathCat: any) => {
      return deathCode == deathCat.code;
    });

    if (!deathCategory) {
      return false;
    }

    return deathCategory!.other_selected || false;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // clears the cause of death type and cause of death type other fields when a change is made to cause of death category
  private clearCauseOfDeathType(): void {
    this.editState.causeOfDeathType = null;
    this.editState.causeOfDeathTypeOther = null;
  }

  private async loadDeathDetails(): Promise<void> {
    try {
      await this.currentRecipient.load();
      const uiDeath = this.currentRecipient.deathDetails;
      if (uiDeath) this.selection = uiDeath.copyViewModel();
      this.initializeForm();
      this.isLoadingDeathData = false;
      this.checkLoaded();
    } catch (error: unknown) {
      this.isLoadingDeathData = false;
      console.warn(error, 'Something unexpected happen when attempting to get death details');
    }
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    this.loadDeathDetails();

    // check if user has access to death validations (hla will not)
    if (this.checkAllowed(EP.validations.death_edit_validations, "GET")) {
      this.$store.dispatch('validations/loadEditDeath', { clientId: this.clientId }).then(() => {
        this.isLoadingDeathRules = false;
        this.checkLoaded();
      }).catch(() => {
        this.isLoadingDeathRules = false;
        console.warn('Something unexpected happen when attempting to get death rules');
      });
    } else {
      this.isLoadingDeathRules = false;
    }
  }

  private checkLoaded(): void {
    if (this.isLoadingDeathData) return;
    if (this.isLoadingDeathRules) return;
    this.isLoading = false;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recipientDeath#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'recipientDeath');
  }

  /**
   * Populates the Recipient Death form state with data from the selected Recipient.
   */
  public initializeForm(): void {
    this.editState = this.selection.copyViewModel();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar {
    return this.$refs.saveRecipientDeath as unknown as SaveToolbar;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Recipient Death, dispatches a save action, handle errors and registers the save result.
   */
  public async savePatch(): Promise<void> {
    // Report to parent that saving has began
    this.saveToolbar.startSaving();
    const saveParams = { selected: this.selection, recipient: this.currentRecipient };
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);

    // re-load data
    const uiDeath = this.currentRecipient.deathDetails || null;
    if (uiDeath) this.selection = uiDeath.copyViewModel();
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset form when cancel is clicked
  public cancelPatch(): void {
    this.initializeForm();
    this.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'recipient_death.cause_category_code'  : 'recipientDeath-death_category',
    'recipient_death.cause_type_code'      : 'recipientDeath-death_type',
    'recipient_death.cause_type_other'     : 'recipientDeath-death_type_other',
    'recipient_death.death_date'           : 'recipientDeath-death_date',
    'recipient_death.estimated_death_date' : 'recipientDeath-estimated',
    'recipient_death.related_to_waittime'  : 'recipientDeath-related_to_waittime',
    'recipient_death.comments'             : 'recipientDeath-comments',
  }
}
</script>

