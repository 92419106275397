import i18n from '@/i18n';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { LivingAllocation, LivingAllocationStateValues, LivingAllocationConcludedReasonCodes } from '@/store/livingAllocations/types';
import { OrganCodeValue, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';
import { LivingDonor, LivingDonorAddress, LivingDonorsState, LivingDonorAllocationSummary,
  LivingDonorOrgan, LivingDonorOrganDonations, StateLivingDonorsList, LivingDonorJourney, LivingDonorIntendedRecipient } from './types';
import {calculateAge} from "@/utils";

// Helper methods for getters
function livingDonorAddress(livingDonor?: LivingDonor, addressType?: string): LivingDonorAddress|undefined {
  if (!livingDonor || !addressType || !livingDonor.patient_profile || !livingDonor.patient_profile.addresses) {
    return undefined;
  }
  const result = livingDonor.patient_profile.addresses.find((address: any) => { return address.type === addressType; });
  if (!result) {
    return undefined;
  }
  return result;
}


// Getters
export const getters: GetterTree<LivingDonorsState, RootState> = {
  show(state): LivingDonor | undefined {
    return state.selectedLivingDonor;
  },
  new(): LivingDonor {
    return {
      _id: { $oid: '' },
      patient_profile: {
        first_name: '',
        middle_name: '',
        last_name: '',
        ethnicity_code: '',
        ethnicity: '',
        sex: '',
        birth: {
          city: '',
          province: '',
          country: '',
          date: ''
        },
        insurance: {
          number: ''
        },
        contact_details: {
          phone_primary: '',
          phone_alternative: '',
          phone_mobile: '',
          phone_pager: '',
          email: ''
        },
        ctr: {
          national_recipient_id: '',
          now_consent_withdrawn: true,
          national_recipient_serum_id: 0,
          ctr_last_updated: '',
        },
        comments: '',
      },
      client_id: undefined
    };
  },
  /**
   * Get a number for the age of the Living Donor
   *
   * Calculates the age of the Living Donor using the value of Date of Birth
   *
   * @returns {number|null} Living Donor's age or null
   */
   getDonorAge(state): number|null {
    const livingDonorDoB = state.selectedLivingDonor?.patient_profile?.birth?.date || null;
    return livingDonorDoB ? calculateAge(livingDonorDoB) : null;
  },
  livingDonorDisplayName(state): string {
    if (state.selectedLivingDonor) {
      return [state.selectedLivingDonor.patient_profile!.first_name, state.selectedLivingDonor.patient_profile!.last_name].join(' ');
    } else {
      return '';
    }
  },
  permanentAddress(state): LivingDonorAddress|undefined {
    return livingDonorAddress(state.selectedLivingDonor, 'permanent');
  },
  // returns client_id (OATS ID)
  clientId(state): string {
    if (state.selectedLivingDonor && state.selectedLivingDonor.client_id) {
      return state.selectedLivingDonor.client_id.toString();
    } else {
      return '';
    }
  },
  // returns living_donor_info (TGLN ID)
  livingDonorId(state): string {
    if (state.selectedLivingDonor && state.selectedLivingDonor.living_donor_info) {
      return state.selectedLivingDonor.living_donor_info.toString();
    } else {
      return '';
    }
  },
  /**
   * Returns the first Living Donor Journey for the selected Living Donor
   *
   * @param state living donors vue-x store state
   * @returns the Living Donor's first Journey if there is one, null otherwise
   */
  selectedLivingDonorJourney(state): LivingDonorJourney|null {
    if (!state.selectedLivingDonor) return null;

    if (!state.selectedLivingDonor.living_donor_info?.journeys || state.selectedLivingDonor.living_donor_info.journeys.length === 0) return null;

    return state.selectedLivingDonor.living_donor_info.journeys[0];
  },
  /**
   * Returns true if first living donor journey has intended recipients
   *
   * @param state living donors vue-x store state
   * @param getters living donors vue-x getters
   * @returns true if first living donor journey has intended recipients
   */
   hasIntendedRecipients(state, getters): boolean {
    const journey = getters.selectedLivingDonorJourney;
    if (!journey) return false;
    return journey.intended_recipients && journey.intended_recipients.length > 0;
  },
  /**
   * Returns recipient if first living donor journey has intended recipients
   *
   * @param state living donors vue-x store state
   * @param getters living donors vue-x getters
   * @returns first intended recipient from selected living donor journey
   */
   getIntendedRecipient(state, getters): LivingDonorIntendedRecipient|null {
    if (!getters.hasIntendedRecipients) return null;
    // Living Donor can have multiple 'intended_recipients' entries, since they can unlinked.
    // Need to find the 'active' intended recipient (find the first entry WITHOUT Unlink Date)
    const intendedRecipients = getters.selectedLivingDonorJourney?.intended_recipients || [];
    const activeIntendedRecipients = intendedRecipients.filter((intendedRecipient: LivingDonorIntendedRecipient) => {
      return !intendedRecipient.unlink_date;
    });
    // Note: here we assume finding the first active entry is sufficient, because there should only be one
    if (activeIntendedRecipients.length === 0) return null;
    return activeIntendedRecipients[0];
  },
  /**
   * Returns the organ code based on first Living Donor Journey for the selected Living Donor
   *
   * @param state living donors vue-x store state
   * @returns organCode: number
   */
  selectedLivingDonorJourneyOrganCode(state, getters): number|undefined {
    return getters.selectedLivingDonorJourney ? getters.selectedLivingDonorJourney.organ_code : undefined;
  },
  selectedLivingDonorConsentedOrganList(state, getters, rootState, rootGetters): LivingDonorAllocationSummary[] {
    if (!state.selectedLivingDonor) {
      return [];
    }
    const donorOrgans: undefined|LivingDonorOrgan[] = state.selectedLivingDonor.organ_consents;
    if (!donorOrgans || donorOrgans.length < 0) {
      return [];
    }
    const consentedOrgans: LivingDonorOrgan[] = donorOrgans.filter((item: LivingDonorOrgan) => {
      return item.consented === true;
    });
    const consentedOrganList: LivingDonorAllocationSummary[] = [];
    consentedOrgans.forEach((item) => {
      // For all other organs, create just the one Allocation Summary item
      consentedOrganList.push(getters.buildAllocationSummaryItem(item, 'local'));
    });
    return consentedOrganList;
  },
  /**
   * Returns true if the Selected Living Donor has a valid consented organ
   *
   * @param state living donors vue-x store state
   * @returns true if living donor has valid consented organ
   */
  hasSelectedLivingDonorConsentedOrgans(state, getter): boolean {
    const results = getters.selectedLivingDonorConsentedOrganList || [];
    return results.length > 0;
  },
  buildAllocationSummaryItem(state, getters, rootState, rootGetters): (consentedOrgan: LivingDonorOrgan, option?: string) => LivingDonorAllocationSummary {
    return (consentedOrgan: LivingDonorOrgan, option?: string): LivingDonorAllocationSummary => {
      // Donor details / Indicators / Double Kidney
      const selectedDonor = state.selectedLivingDonor || {};
      const donorIndicators = selectedDonor.indicators || {};
      const organCount = '';
      // Get details from Consented Organ data model itself
      const organ_code = consentedOrgan.organ_code || 0;
      const organName = rootGetters['lookups/lookupValue'](consentedOrgan.organ_code, 'organ');
      const availableAllocationType = consentedOrgan.available_allocation_types ? consentedOrgan.available_allocation_types[0] : null;

      // Get details about Allocation
      let allocationInfo;
      if (availableAllocationType) {
        allocationInfo = {
          allocationId: availableAllocationType.client_id,
          allocationStage: getters.describeAllocationStage(availableAllocationType),
          allocationRunDateTime: availableAllocationType.start_date,
        };
      } else {
        // On reloading allocation summary, allocation info can be extracted from allocation instead of directly to consentedOrgan
        const organAllocation = rootGetters['livingAllocations/getAllocationInfoByConsentedOrgan'](consentedOrgan);
        allocationInfo = organAllocation ? {
          allocationId: organAllocation.client_id,
          allocationStage: getters.describeAllocationStage(organAllocation),
          allocationRunDateTime: organAllocation.start_date
        } : {};  
      }
      // Setup display name and routing options (organ's are local but we don't need to show 'local')
      const display_name = organName || '';
      const route = { name: 'living-donor-organ', params: { organ_code } };

      // Return our donor summary item
      return {
        ...consentedOrgan,
        ...allocationInfo,
        organ: organName,
        display_name,
        route,
      };
    };
  },
  /**
   * Generate a string representation of the 'Stage' of an Allocation
   *
   * This is roughly based on the 'State' value of the Allocation itself, but in some situations
   * the webapp shows something else instead e.g. "Transplanted".
   *
   * NOTE: the 'Transplanted' special case is why this getter is in the 'livingDonors' module,
   * because in this situation the Organ Donation data can be what determines the visual 'Stage'.
   *
   * @returns {(allocation: LivingAllocation => string} function to generate visual 'Stage' values
   */
  describeAllocationStage(state, getters): (allocation: LivingAllocation) => string {
    return (allocation): string => {
      if (!allocation) return '-';

      // Check special cases that 'override' allocation state for display text
      const isOrganTransplanted = getters.allocationTransplanted(allocation);
      if (isOrganTransplanted) return 'organ_donation_status.transplanted';

      return `allocation_stage.${allocation.state || 'unknown'}`;
    };
  },
  /**
   * Return true if the Allocation is Transplanted
   *
   * This will use an Allocation client_id and find the right entry in the
   * selectedDonor's organ_donation array.  From there we check to see if
   * the organ_donation has a corresponding transplant_date value, if so we
   * consider the Allocation as Transplanted.
   *
   * @returns {boolean} true if the Allocation is Transplanted
   */
  allocationTransplanted(state): (allocation: LivingAllocation) => boolean {
    return (allocation: LivingAllocation): boolean => {
      if (allocation.state != LivingAllocationStateValues.Concluded) return false;

      return (allocation.concluded_reason_code == LivingAllocationConcludedReasonCodes.OrganTransplanted);
    };
  },
  /**
   * Return true if the transplant is complete
   * - directed => has intended recipient and transplant in progress
   * - in-directed => has offer-accepted / offer-confirmed state
   *
   * @returns {boolean} true if we have a value for living_donor_transplant_in_progress
   */
  isLivingDonorTransplantComplete(state, getters): boolean {
    const selectedJourney = getters.selectedLivingDonorJourney || null;
    if (!selectedJourney) return false;

    const directedDonation = getters.checkJourneyForDirectedDonation(selectedJourney);

    // directed donation
    if (directedDonation) {
      // Transplant Complete occurs only if Living Donor TIP matches this Living Donor ID
      return getters.destinationRecipientAllocatedFromThisLivingDonor;
    // indirected donation
    } else {
      const organDonations = state.selectedLivingDonor && state.selectedLivingDonor.organ_donations ? state.selectedLivingDonor.organ_donations : [];
      return organDonations.length > 0; // if have organ donations, show recovery details
    }
  },

  /**
   * Return true if the Recovery and Donor Information sections should be locked
   * - directed => has intended recipient and transplant in progress
   * - non-directed => living donor recovery details has been saved successfully at least once
   *
   * @returns {boolean} true true if we have a value for living_donor_transplant_in_progress for directed donation
   *                and true if living donor has recovery information, false otherwise
   */
     isLockRecoveryAndDonorInformation(state, getters): boolean {
      const selectedJourney = getters.selectedLivingDonorJourney || null;
      if (!selectedJourney) return false;

      const directedDonation = getters.checkJourneyForDirectedDonation(selectedJourney);

      if (directedDonation) {
        return getters.destinationRecipientAllocatedFromThisLivingDonor;
      } else {
        // NOTE: derived by API based on whether or not mandatory 'completed' field has explicit boolean value or nil (TPGLI-6314)
        return selectedJourney.recovery_info_set;
      }
    },

  /**
   * Does the active intended recipient's journey have Living Donor TIP factor set to this Living Donor's ID?
   * Note: this relates to the 'living_donor_transplant_in_progress' used for Directed Donation scenarios.
   *
   * @returns {boolean} true if ID values match, false otherwise
   */
  destinationRecipientAllocatedFromThisLivingDonor(state, getters): boolean {
    const journey = getters.getIntendedRecipient && getters.getIntendedRecipient.journey || null;
    if (!journey || !journey.stage_attributes?.waitlist) return false;

    // Note: need to make sure the recipient was allocated a donation from this specific Living Donor
    const living_donor_transplant_in_progress = journey.stage_attributes?.waitlist?.factors.living_donor_transplant_in_progress || null;
    const result = living_donor_transplant_in_progress?.$oid == getters.show._id.$oid;
    return result;
  },

  /**
   * Return true if directed donation
   *
   * @returns {boolean} true if directed donation
   */
   checkJourneyForDirectedDonation(state, getters) {
    return (journey?: LivingDonorJourney): boolean => {
      const myJourney: LivingDonorJourney = journey || {};

      const directedDonation = myJourney.directed_donation || false;

      // true if directed donation, otherwise false
      return directedDonation;
    };
  },
  /**
   * Return true if there is a journey
   *
   * @returns {boolean} true if living donor journey
   */
  showRecoveryInformation(state, getters): boolean {
    const selectedJourney = getters.selectedLivingDonorJourney || null;

    return selectedJourney ? true : false;
  },

  /**
   * Transplant Complete subsection should only be visible when:
   * • Organ is Kidney and Living Donor to Donate to is “Preselected Recipient” (journey.directed_donation: true)
   * • Organ is Liver or Lung and Living Donor to Donate to is “Directed Donation” (journey.directed_donation: true)
   *
   * @returns {boolean} true if a directed donation otherwise returns false
   */
  showTransplantCompleteButton(state, getters): boolean {
    const journey = getters.selectedLivingDonorJourney || null;
    if (!journey) return false;

    return !!journey.directed_donation;
  },
};
