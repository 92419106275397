import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-sm-12"
}
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "content-wrap" }
const _hoisted_4 = { class: "container-fluid" }
const _hoisted_5 = { class: "nav-wrapper" }
const _hoisted_6 = { class: "page-content donor-profile" }
const _hoisted_7 = { class: "form-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_top = _resolveComponent("page-top")!
  const _component_side_nav_deceased_donor = _resolveComponent("side-nav-deceased-donor")!
  const _component_referral_information = _resolveComponent("referral-information")!
  const _component_donation_information = _resolveComponent("donation-information")!
  const _component_clinical_information = _resolveComponent("clinical-information")!
  const _component_virology_results = _resolveComponent("virology-results")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dispatchEventsComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("loading")), 1)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_page_top, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _ctx.getDonorsUrl }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('donors')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" / " + _toDisplayString(_ctx.$t('new')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_side_nav_deceased_donor, { newDonor: true }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_validation_observer, {
                    ref: "validations",
                    autocomplete: "off",
                    tag: "form"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_referral_information, {
                        ref: "referralInformation",
                        newDonor: true,
                        canSave: _ctx.isGroupWriteable('donor_personal')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_donation_information, {
                        ref: "donationInformation",
                        newDonor: true,
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_clinical_information, {
                        ref: "clinicalInformation",
                        newDonor: true,
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["canSave"]),
                      _createVNode(_component_virology_results, {
                        ref: "virology",
                        onHandleErrors: _cache[0] || (_cache[0] = (errors) => _ctx.handleErrors(errors)),
                        onClear: _ctx.resetValidationErrors,
                        newRecord: true,
                        virologyType: _ctx.virologyType,
                        canSave: _ctx.isGroupWriteable('donor_medical')
                      }, null, 8, ["onClear", "virologyType", "canSave"]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_save_toolbar, {
                          ref: "saveDonor",
                          label: _ctx.$t('save_donor'),
                          onSave: _ctx.performSave,
                          show: _ctx.canSaveGetter(true)
                        }, null, 8, ["label", "onSave", "show"])
                      ])
                    ]),
                    _: 1
                  }, 512)
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}