<template>
  <div v-if="isProtoCommunicationInformationEnabled">
    <card-section
      section-id="proto_communication_information"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="proto-considerations-section-form">
          <template v-slot:contents>
            <sub-section 
              :title="title" 
              sub-section-id="proto-considerations-section-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                    <!-- Uploaded Files -->
                    <div class="col-sm-12 considerations-section">
                      <fieldset>
                        <div class="row">
                          <div class="standard-form-group">
                            <checkbox-input            
                              name="interpreter-needed"
                              labelName="Interpreter Needed?"
                              inputId="interpreter-needed"
                              v-model="interpreter"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">
                              <text-input
                              input-id="primary-language"
                                name="Primary Language"
                                validation-id="insuranceStatus"
                                v-model="language"
                              />
                          </div>
                          <div class="standard-form-group-small-checkbox">
                            <checkbox-input            
                              name="poa"
                              labelName="POA?"
                              inputId="poa"
                              v-model="poa"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">
                            <text-input
                                input-id="Care-partner-name-poa"
                                name="Care Partner Name (POA)"
                                validation-id="CarePartnerName"
                                v-model="carePartnerName"
                              />
                          </div>
                          <div class="standard-form-group">
                            <checkbox-input            
                              name="decision-support"
                              labelName="Decision Support Needed?"
                              inputId="decision-support"
                              v-model="decision"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group">             
                            <text-input
                              inputId="care-partner-for-support"
                              name="Care Partner Name (Decision Support)"
                              v-model="supportPartner" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="standard-form-group-3-quarters">
                            <text-area-input
                              input-id="audiovisual-inpairments-devices"
                              name="Audiovisual Impairments and Devices"
                              rows="4"
                              :showToolTip="true"
                              toolTipText="Describe the patient's audiovisual impairments and devices and how they impact communication."
                              v-model="inpairmentsDevices" />
                            </div>
                        </div>
                        <div class="row">
                          <div class="standard-form-group-3-quarters">
                            <text-area-input
                              input-id="audiovisual-comments"
                              name="Comments"
                              rows="4"
                              v-model="comments" />
                            </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="saveDemographics"
              :label="`Save ${title}`"
              :cancelButton="true"        
            />
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  
  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoCommunicationInformation extends Vue {

    private title: any = "Communication Information";

    private interpreter: any = '';
    private language: any = '';
    private decision: any = '';
    private supportPartner: any = '';
    private inpairmentsDevices: any = '';
    private comments: any = '';
    private poa: any = '';
    private carePartnerName: any = '';

    get isProtoCommunicationInformationEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.CommunicationInformation);
    }

    public performSave(): any {

      const payload: any = {
        interpreter: this.interpreter,
        language: this.language,
        decision: this.decision,
        supportPartner: this.supportPartner,
        inpairmentsDevices: this.inpairmentsDevices,
        comments: this.comments,
        poa: this.poa,
        carePartnerName : this.carePartnerName
      };
    } 
  }

</script>

<style>


</style>
