import { APIBookmarkEntry, APIDashboardPreferences, APIWidgetBookmarkCustomPreferences, APIWidgetPreferences } from '@/APIModels/user/preferences/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { DEFAULT_WIDGET_COMMON_PREFERENCES, UIBaseWidget } from '@/UIModels/dashboard/baseWidget';
import { UIBookmark } from '@/UIModels/dashboard/widgetBookmarks/bookmark';
import { UIError } from '@/UIModels/error';
import { SaveResult } from '@/types';

export const DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES = {
  entries: [],
};

export const DEFAULT_WIDGET_BOOKMARKS_PREFERENCES = {
  common: DEFAULT_WIDGET_COMMON_PREFERENCES,
  custom: DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES,
};

export class UIWidgetBookmarks extends UIBaseWidget {
  public apiCustomPreferences?: APIWidgetBookmarkCustomPreferences
  public entries: UIBookmark[] = [];

  get customPreferences(): APIWidgetBookmarkCustomPreferences {
    const dashboardPreferences = useCurrentPageStore().preferences.preferenceData.dashboard as APIDashboardPreferences;
    if (!dashboardPreferences) return DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES;
    const result = dashboardPreferences.widgets.bookmarks.custom as APIWidgetBookmarkCustomPreferences;
    if (!result) return DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES;
    return result;
  }

  public async saveBookmarkEntries(apiEntries: APIBookmarkEntry[]): Promise<SaveResult> {
    const newWidgetPreferences = Object.assign({}, (this.apiWidgetPreferences || DEFAULT_WIDGET_BOOKMARKS_PREFERENCES)) as APIWidgetPreferences<APIWidgetBookmarkCustomPreferences>;
    if (!newWidgetPreferences.custom) newWidgetPreferences.custom = DEFAULT_WIDGET_BOOKMARKS_CUSTOM_PREFERENCES;
    newWidgetPreferences.custom.entries = apiEntries;
    try {
      const result = await useCurrentPageStore().preferences.saveWidgetCustom(this, newWidgetPreferences);
      const apiWidgetPreferences = result.responseData as APIWidgetPreferences<APIWidgetBookmarkCustomPreferences>;
      this.updateFromAPIWidgetPreferences(apiWidgetPreferences);
      this.updateFromAPIWidgetCustomPreferences(apiWidgetPreferences.custom as APIWidgetBookmarkCustomPreferences);
      this.load();
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('widgetBookmarks', error);
    }
    return { success: true };
  }

  public async load(): Promise<void> {
    await super.load();
    const apiWidgetPreferences = this.apiWidgetPreferences as APIWidgetPreferences<APIWidgetBookmarkCustomPreferences>;
    if (apiWidgetPreferences?.custom) this.updateFromAPIWidgetCustomPreferences(apiWidgetPreferences.custom);
  }

  public updateFromAPIWidgetCustomPreferences(apiWidgetBookmarkCustomPreferences: APIWidgetBookmarkCustomPreferences): void {
    this.apiCustomPreferences = apiWidgetBookmarkCustomPreferences;
    const apiEntries = apiWidgetBookmarkCustomPreferences.entries || [];
    this.entries = apiEntries.map((apiBookmarkEntry: APIBookmarkEntry): UIBookmark => { return new UIBookmark(this, apiBookmarkEntry); });
  }
}
