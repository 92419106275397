import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "save-toolbar" }
const _hoisted_2 = ["disabled", "title"]
const _hoisted_3 = ["disabled", "title"]
const _hoisted_4 = ["disabled", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "actions"),
    (_ctx.showArchivedButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          disabled: _ctx.isDisabled,
          class: _normalizeClass(_ctx.archiveButtonClass),
          title: _ctx.buttonTitle,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.archive && _ctx.archive(...args)))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.archiveLabel), 1)
          ])
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.showDeleteButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          disabled: _ctx.isDisabled,
          class: _normalizeClass(_ctx.deleteButtonClass),
          title: _ctx.buttonTitle,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.deleteItem && _ctx.deleteItem(...args)))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.deleteLabel), 1)
          ])
        ], 10, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.showCancelCreateButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          type: "button",
          disabled: _ctx.isDisabled,
          class: _normalizeClass(_ctx.showCancelCreateClass),
          title: _ctx.cancelCreateText,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.cancelCreateText), 1)
          ])
        ], 10, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}