<template>
  <header
    ref="site-header"
    class="header"
    :class="{ menuOpen: isActive }"
  >
  <div class="header-inner">
    <!-- Logo / Brand -->
    <div class="nav-top">
      <router-link :to="{ name: 'index' }" exact>      
        <h1 class="brand">
          <div class="logo" v-if="getLogo">
            <img
              :src="getLogo"
              :alt="$t('application_short_name')"
            />
          </div>
          <template v-if="applicationNamePrefix">
            <span class="brand-title d-block d-md-none"><span class="application-name-prefix">{{ applicationNamePrefix }}</span>&nbsp;<span class="application-name-text">{{ $t('application_short_name') }}</span></span>
            <span class="brand-title d-none d-md-block"><span class="application-name-prefix">{{ applicationNamePrefix }}</span>&nbsp;<span class="application-name-text">{{ $t('application_long_name') }}</span></span>
          </template>
          <template v-else>
            <span class="brand-title d-block d-md-none"><span class="application-name-text">{{ $t('application_short_name') }}</span></span>
            <span class="brand-title d-none d-md-block"><span class="application-name-text">{{ $t('application_long_name') }}</span></span>
          </template>
        </h1>
      </router-link>
      <!-- Search / User Drop Down -->
      <div class="d-md-none mobile-user-menu"> 
        <button type="submit" @click="toggleSearch()"  :class="show ? 'active': ''" class="btn btn-outline-primary shadow-none">
          <font-awesome-icon :icon="['fas', 'search']" v-if="!show" />
          <font-awesome-icon :icon="['fas', 'times']" v-if="show" />
          <span class="sr-only">toggle-search</span>
        </button>
        <user-menu />
      </div>
    </div>

    <!-- Main Nav -->

    <nav class="search-nav" v-if='show' role="navigation">
      <user-menu class="d-none d-md-block"  />
    </nav>

    </div>
    <main-menu @toggle="mainMenuClicked"/>
  </header>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import UserMenu from "@/components/shared/site-header/UserMenu.vue";
import MainMenu from "@/components/shared/site-header/MainMenu.vue";
import SearchBar from "@/components/search/SearchBar.vue";
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from "@/stores/currentPage";

@Component({
  components: {
    UserMenu,
    SearchBar,
    MainMenu,
  },
  ...i18nMessages([
    require('@/components/_locales/common.json'),
    require('./_locales/common.json'),
    require('@/components/shared/_locales/single.json'),
  ]),
})
export default class SiteHeader extends Vue {
  public isActive = false;
  public show = false;

  get applicationNamePrefix(): string|undefined {
    return useCurrentPageStore().configuration.applicationNamePrefix;
  }

  get getLogo(): string {
    return useCurrentPageStore().configuration.appLogo;
  }

  private mounted(): void {
    window.addEventListener('resize', this.checkWindowWidth);
    if (window.innerWidth > 765) {
      this.show = true;
    }
  }

  private toggleSearch(): void {
    this.show = !this.show;
  }

  private checkWindowWidth(): void {
    if (window.innerWidth < 991) {
      this.isActive = false;
    }
    if (window.innerWidth > 765) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  public mainMenuClicked(): void {
    this.isActive = !this.isActive;
  }
}


</script>

<style>
#afflo-application .nav-main {
  margin-left: 0;
  padding-bottom: 0.5rem;
  padding-top: 5px;
}
#afflo-application .logo {
  left: 30px;
  top: 13px;
}
#afflo-application .nav-main {
  padding-bottom: 0.5rem;
  padding-top: 5px;
}
</style>
