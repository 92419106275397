<template>
  <div v-if="isProtoPaceAndEngagementEnabled">
    <card-section
      section-id="proto_pace_and_engagement"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="pace-and-engagement-section-form">
          <template v-slot:contents>
            <sub-section 
              :title="title" 
              sub-section-id="pace-and-engagement-section-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                    <!-- Uploaded Files -->
                    <div class="col-sm-12 pace-section">
                      <fieldset>
                        <div class="row">
                          <div class="standard-form-group form-group-checkbox">
                            <checkbox-input            
                              name="centre-fast-track"
                              labelName="Transplant Center Fast-Track?"
                              inputId="centre-fast-track"
                              v-model="editState.fastTrack"
                              label='Yes'
                            />
                          </div>
                          <div class="standard-form-group form-group-checkbox">
                            <label>Patient Preferred Pace / Motivation</label>
                            <div class="row">
                              <div class="col-12">
                                <checkbox-input name="slow" hideLabel="true" labelName="Slow / minimize burden" inputId="slow"
                                  v-model="editState.slow" label='Slow / minimize burden'  :disabled="editState.fast" />
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <checkbox-input name="fast" hideLabel="true" labelName="Go fast" inputId="fast"
                                  v-model="editState.fast" label='Go fast' :disabled="editState.slow" />
                              </div>
                            </div>
                          </div>
          
                        
                          <div class="standard-form-group-large">
                            <text-area-input input-id="additional-information" name="Patient Engagement"
                              rows="4"
                              :showToolTip="true"
                              toolTipText="Is the patient engaged in this process?"
                              v-model="editState.patientEngagement" />
                          </div>
                      
                        </div>

                        
                      </fieldset>
                    </div>
                  </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="savePaceAndEngagement"
              :label="`Save ${title}`"
              :cancelButton="true"
              @save="performSave"
            />
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';

  const EDITSTATE_KEY = 'editState';

  interface PaceAndEngagementForm {
    fastTrack: boolean,
    slow: boolean,
    fast: boolean,
    patientEngagement: string,
  }
  
  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoPaceAndEngagemment extends Vue {

    private title: any = "Pace and Engagemment";

    private editState: PaceAndEngagementForm = {
      fastTrack: false,
      slow: false,
      fast: false,
      patientEngagement: '',
    };

    // Create a prototype bucket to store arbitrary key/value data
    private protoBucket!: UIPrototypeBucket;

    get isProtoPaceAndEngagementEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.PaceAndEngagement);
    }

    // Each save toolbar will need a unique ref
    get saveToolbar(): SaveToolbar {
      return this.$refs.savePaceAndEngagement as SaveToolbar;
    }

    // NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
    async mounted() {
      // No-op if prototypes not enabled
      if (!useCurrentPageStore().configuration.prototypes) return;

      // Make a per-recipient prototype bucket
      this.protoBucket = new UIPrototypeBucket(PROTOTYPES.PaceAndEngagement, { recipientId: this.$route.params.id });

      // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
      const response: PaceAndEngagementForm = await this.protoBucket.getItem(EDITSTATE_KEY);
      if (!response) return;

      this.editState = {
        fastTrack: response.fastTrack,
        slow: response.slow,
        fast: response.fast,
        patientEngagement: response.patientEngagement
      };
    }

    public performSave(): any {
      // Call the 'startSaving' method to begin save animation
      if (this.saveToolbar) this.saveToolbar.startSaving();

      const payload: PaceAndEngagementForm = {
        fastTrack: this.editState.fastTrack,
        slow: this.editState.slow,
        fast: this.editState.fast,
        patientEngagement: this.editState.patientEngagement
      };

      this.protoBucket.setItem(EDITSTATE_KEY, payload);

      // Call the 'showFakeSuccess' method to complete successful save animation
      if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
    } 
  }

</script>

<style>


</style>
