import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { RecipientJourney, AssessmentState, JourneyStatusConfig, RecipientWaitlistAttributes, WaitlistInactiveStatusValue, JourneyStage, WaitlistActiveStatusValue } from '@/store/recipientJourney/types';
import { Recipient, RecipientsState, RecipientMeasurement, RecipientOopTransplant, RecipientProfileDetails, APIAddressInterface, RecipientValidations, SignificantEventDecision, RecipientAttachment } from '@/store/recipients/types';
import { titleCase, calculateAge, isMasked} from "@/utils";
import { OrganCodeValue } from '../lookups/types';
import { SmallBowelDetails } from '../organSpecificDetails/types';

// Helper methods for getters
function recipientAddress(recipient?: Recipient, addressType?: string): APIAddressInterface|undefined {
  if (!recipient || !addressType || !recipient.patient_profile || !recipient.patient_profile.addresses) {
    return undefined;
  }
  const result = recipient.patient_profile.addresses.find((address: any) => { return address.type === addressType; });
  if (!result) {
    return undefined;
  }
  return result;
}

// Getters
export const getters: GetterTree<RecipientsState, RootState> = {
  show(state): Recipient|undefined {
    return state.selectedRecipient;
  },
  recipientDisplayName(state): string {
    if (state.selectedRecipient && state.selectedRecipient.patient_profile) {
      return [state.selectedRecipient.patient_profile!.first_name, state.selectedRecipient.patient_profile!.last_name].join(' ');
    } else {
      return '';
    }
  },
  // TODO: refactor all getters for client ID to use this
  clientId(state): string {
    if (state.selectedRecipient && state.selectedRecipient.client_id) {
      return state.selectedRecipient.client_id.toString();
    } else {
      return '';
    }
  },

  recipientJourneys(state): RecipientJourney[] | undefined {
    if (state.selectedRecipient) {
      return state.selectedRecipient.journeys;
    }
  },
  showList(state) {
    return state.recipientsList;
  },
  includeStomach(state){
    return(journeyId: string):boolean => {
      let stomachIncluded = false;
      if (state.selectedRecipient) {
        const journey = state.selectedRecipient.journeys?.filter((journey: RecipientJourney) => {
          return journey._id?.$oid == journeyId;
        });
        if (!journey) return false;
        journey.forEach((journey) => {
          if (journey.organ_code == OrganCodeValue.SmallBowel){
            const smallBowelSpecificDetails: SmallBowelDetails|undefined = journey?.organ_specific_details;
            stomachIncluded = !!smallBowelSpecificDetails?.stomach_included;
          }
        });
       }
      return stomachIncluded;
    };
  },
  journeyName(state,getters, rootState, rootGetters){
    return(organCode: number, journeyId: string): string => {
      if (!organCode) {
        return '';
      }
      const stomachIncluded = rootGetters["recipients/includeStomach"](journeyId);
      let organName = rootGetters['lookups/lookupValue'](organCode, 'organ');

      if(stomachIncluded && organCode == OrganCodeValue.SmallBowel) organName = organName + ' + Stomach';
      return organName;
    };
  },
  new(): Recipient {
    return {
      _id: { $oid: '' },
      state: '',
      patient_profile: {
        first_name: '',
        middle_name: '',
        last_name: '',
        ethnicity_code: '',
        ethnicity: '',
        sex: '',
        birth: {
          city: '',
          province: '',
          country: '',
          date: ''
        },
        insurance: {
          number: ''
        },
        addresses: [],
        contact_details: {
          phone_primary: '',
          phone_alternate: '',
          phone_mobile: '',
          phone_pager: '',
          email: ''
        },
        ctr: {
          national_recipient_id: '',
          now_consent_withdrawn: true,
          national_recipient_serum_id: 0,
          ctr_last_updated: '',
        },
        comments: '',
      },
      death: {},
      physicians: {
        attending: {},
        family: {},
        referring: {}
      },
      diagnostics: {
        blood: undefined,
        hla: {}
      },
      measurements: [],
      journeys: [],
      client_id: undefined
    };
  },
  decisionEvents(state: RecipientsState): SignificantEventDecision[]|undefined {
    return state.decisionEvents;
  },
  previousTransplants(state: RecipientsState): RecipientOopTransplant[]|undefined {
    // Fetch all the recipient's Out of Province Journeys
    const outOfProvinceJourneys = state.outOfProvinceJourneys;
    if (!outOfProvinceJourneys) {
      return undefined;
    }
    // Map out of province journeys to previous transplants
    const previousTransplants = outOfProvinceJourneys.map((journey: RecipientJourney): RecipientOopTransplant => {
      const stageAttributes = journey.stage_attributes || {};
      const transplant = stageAttributes.transplant || {};
      const factors = transplant.factors || {};
      const oop = journey.oop_info || {};
      return {
        journeyId: journey._id,
        organ_code: journey.organ_code,
        organ_code_other: oop.oop_organ_other,
        transplant_date: factors.transplant_date || undefined,
        province: oop.oop_province || undefined,
        country: oop.oop_country || undefined,
        facility: oop.oop_hospital || undefined,
        country_other: oop.oop_country_other || undefined,
        dialysis_start_date: stageAttributes.waitlist?.factors?.dialysis_start_date || undefined
      };
    });
    return previousTransplants;
  },
  isUrgent(state: RecipientsState): boolean {
    const recipient = state.selectedRecipient;
    return recipient && recipient.urgent || false;
  },

  waitlistStatusDescription(state, getters, rootState, rootGetters) {
    return (attributes: RecipientWaitlistAttributes): string => {
      const statusDescription: string[] = [];
      // Check each boolean waitlist factor related to holds or suspensions
      const factors = attributes?.factors || {};
      if (factors.on_hold_initial_waitlisted) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldInitialWaitlisted);
      }
      if (factors.on_hold_incomplete_data) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldIncompleteData);
      }
      if (factors.on_hold_serum_hla_antibody) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldSerumHlaAntibody);
      }
      if (factors.on_hold_medical) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldMedical);
      }
      if (factors.suspended_medical) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedMedical);
      }
      if (factors.suspended_liver_sodium_meld) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedLiverSodumMeld);
      }
      if (factors.suspended_liver_hcc) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedLiverHcc);
      }
      if (factors.suspended_heart) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedHeart);
      }
      if (factors.on_hold_incomplete_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldIncompleteCluster);
      }
      if (factors.on_hold_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldCluster);
      }
      if (factors.suspended_cluster) {
        statusDescription.push(WaitlistInactiveStatusValue.SuspendedCluster);
      }
      if (factors.on_hold_partial_cluster_transplant) {
        statusDescription.push(WaitlistInactiveStatusValue.OnHoldPartialCluster);
      }
      return statusDescription.join(',');
    };
  },

  /**
   * Returns whether any of the recipient's journeys are eligible for transplant
   *
   * This is used to guarantee that any data that was mandatory for that decision cannot be removed afterwards
   *
   * @returns {boolean} true if at least one journey has Assessment Decision = Recipient to be Listed
   */
  hasJourneyWithAssessmentEligible(state): boolean {
    const recipient = state.selectedRecipient || {};
    const journeys = recipient.journeys || [];
    let result = false;
    journeys.forEach((journey: RecipientJourney) => {
      const stageAttributes = journey.stage_attributes || {};
      const assessmentAttributes = stageAttributes.assessment || {};
      const assessmentState = assessmentAttributes.state;
      result = result || assessmentState === AssessmentState.RecipientToBeListed;
    });
    return result;
  },

  financialAttachments(state): { name?: string, id?: string }[]|undefined{
    const attachments = state.selectedRecipient?.attachments || [];
    if(attachments.length > 0){
      const financialAttachments : { name?: string, id?: string }[]  = attachments.map((item : RecipientAttachment)=>{
        return { 
          name:item.original_filename,
          id: item._id.$oid
        };
      });
      return financialAttachments;
    }
  },
  recipientAge(state): number {
    const recipient = state.selectedRecipient || {};
    const birthDate = recipient.patient_profile?.birth?.date;
    if (birthDate) {
      return calculateAge(birthDate) || 0;
    } else {
      return 0;
    }
  },
};
