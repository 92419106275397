<template>
    <card-section
      section-id="recipient-documents"
      :lookupsToLoad="lookupsToLoad"
      @loaded="loaded()"
      >
      <template v-slot:header>
        {{$t('recipient_documents')}}
      </template>
      <template v-slot:body>
        <template v-if="!editState">
          {{$t('loading')}}
        </template>
        <template v-else>
          <sub-section
            ref="recipientDocumentsSection"
            :title='$t("uploaded_documents")'
            sub-section-id="recipient-uploaded-files"
          >
            <template v-slot:contents>
              <!-- Filter Bar + Create Button -->
              <table-toolbar
                :createButton="canSave"
                :createText="$t('attach_new_document')"
                @table-create-row="onCreateButtonClicked"
              />

              <!-- List of Items, or History List -->
              <table-list
                ref="recipientDocumentsTable"
                table-id="recipientDocuments-uploadedFiles-table"
                :table-config="tableConfig"
                tabbableColumn="dateUploaded"
                @table-row-click="handleTableRowClick($event.row)"
                @table-row-download="(params) => generateDownloadLink(params)"
                :highlightSelection="true"
              />

              <!-- Form layout -->
              <validation-observer ref="validations">
                <form-layout
                  :disabled="!enableForm"
                  form-id="recipientAttachment-form">
                  <template v-slot:title>
                    <!-- Mode indicator / subsection form title -->
                    <legend>
                      <h5 v-if="selection?.isNew" class="legend-title">
                        {{$t('new_recipient_document')}}
                      </h5>
                      <h5 v-else class="legend-title has-guiding-text">
                        {{$t('edit_recipient_document')}}
                      </h5>
                    </legend>
                  </template>

                  <template v-slot:action>
                    <!-- Action Toolbar -->
                    <action-toolbar
                      ref="actionToolbar"
                      @destroy="destroyFile"
                      :permittedActions="editState.permittedActions"
                      :isDestroying="isDestroying"
                      :destroyGuidingText="getDestroyGuidingText"
                      :loading="!editState.loaded"
                    />
                  </template>

                  <template v-slot:contents>
                    <loading-form v-if="!editState.loaded" />
                    <div v-else>
                      <div class="row">
                        <!-- Clinical Attachments -->
                        <div class="standard-form-group-large">

                          <!-- TODO: File Input component -->
                          <validation-provider
                            ref="recipientAttachment-clinicalAttachment-provider"
                            :label="$t('clinical_attachment')"
                            name="recipientAttachment-clinicalAttachment"
                            v-slot="{ errors }"
                          >
                            <label for="recipientAttachment-clinicalAttachment">{{$t('clinical_attachment')}} <i>*</i></label>
                            <input
                              v-if="!editState.isNew"
                              type="text"
                              :disabled="true"
                              class="form-control"
                              :value="editState.originalFilename"
                            />
                            <input
                              v-else
                              input-id="recipientAttachment-clinicalAttachment"
                              ref="fileUploader"
                              type="file"
                              class="form-control"
                              :class="{ 'is-invalid': errors[0] }"
                              @change="onClinicalAttachmentsChanged($event)"
                            />
                            <div class="invalid-feedback" id="recipientAttachment-clinicalAttachment-error" v-if="errors[0]">
                              <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
                              {{ translateError(errors, $t('clinical_attachment')) }}
                            </div>
                          </validation-provider>

                        </div>
                        <div class="standard-form-group">
                          <select-input
                            select-id="recipientAttachment-categoryCode"
                            ruleKey="recipient_documents.category_code"
                            :name='$t("category")'
                            :options="attachmentCategoryOptions(false)"
                            v-model="editState.categoryCode"
                          >
                          </select-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="standard-full-width-group">
                          <text-input
                            :name='$t("fileName")'
                            ruleKey="recipient_documents.original_filename"
                            input-id="recipientAttachment-fileName"
                            v-model="editState.filename"
                          />
                        </div>
                        <div class="row-break d-none d-xl-block"></div>
                        <div class="standard-full-width-group">
                          <text-area-input
                            ruleKey="recipient_documents.description"
                            input-id="recipientAttachment-description"
                            :name='$t("description")'
                            rows="4"
                            v-model="editState.description">
                          </text-area-input>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-slot:save>
                    <save-toolbar
                      :show="enableSaveToolbar"
                      ref="saveDocuments"
                      class="card-footer action-row temp-saving row"
                      :label="$t('save_document')"
                      :cancelButton="true"
                      @save="handleSave()"
                      @cancel="handleCancel()"
                    />
                  </template>

                </form-layout>
              </validation-observer>
            </template>
          </sub-section>
        </template>
      </template>
    </card-section>
</template>

<script lang="ts">
import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter } from 'vuex-facing-decorator';
import { TableConfig } from '@/types';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import SelectInput from '@/components/shared/SelectInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import ModalSection from '@/components/shared/ModalSection.vue';
import { SaveResult, APIResponseWithPermittedActions } from '@/types';
import { AttachmentCategory } from '@/store/lookups/types';
import { i18nMessages } from "@/i18n";
import TableToolbar from '@/components/shared/TableToolbar.vue';
import TableList from '@/components/shared/TableList.vue';
import { parseFormErrors } from '@/utils';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { UIAttachment } from '@/UIModels/recipients/attachment';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from '@/UIModels/recipient';
import { EP } from '@/api-endpoints';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import LoadingForm from '@/components/shared/LoadingForm.vue';

interface TableRow {
  id: string|null;
  downloadParams?: {
    recipientId: string|null;
    id: string|null;
  },
  category?: string|null;
  categoryCode?: number|null;
  dateUploaded?: string|null;
  filename?: string|null;
  filetype?: string|null;
  description?: string|null;
  uploadedBy?: string|null;
  editRemove?: string|null;
  checked?: boolean|null;
}

// What are all the possible per-page sizes allowed in the table list pagination bar?
// NOTE: The list displays 10 records by default, with pagination (AP-1490)
// See: https://shorecg.atlassian.net/wiki/spaces/AP/pages/1581383697/Paginated+Table
// TODO: TECH_DEBT: can page sizes be moved to application level somehow
const PAGE_SIZES = [10, 25, 50];
const DEFAULT_PAGE_SIZE = PAGE_SIZES[0];

@Component({
  components: {
    CardSection,
    SubSection,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    ModalSection,
    TableToolbar,
    TableList,
    LoadingForm,
    FormLayout,
    SaveToolbar,
    ActionToolbar
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientDocuments.json'),
  ]),
  emits: [
    'loaded',
  ]
})
export default class RecipientDocuments extends mixins(DateUtilsMixin) {
  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('AttachmentCategoryOptions', { namespace: 'lookups' }) attachmentCategoryOptions!: (enableDonor: boolean) => AttachmentCategory[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;

  // TODO: File Input component (it can handle errors in standard way)
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [ 'attachment_category' ];

  // Selection instance
  private selection = new UIListFormSelection();

  // Editable view model for the form
  private editState = new UIAttachment();

  private isDestroying = false;

  private get getDestroyGuidingText(): string|null {   
    if (!this.editState || this.editState.isNew || !this.editState.originalFilename) return null;
    const baseText = this.$t("delete_documents_confirmation");
    return baseText.replace('__filename__', this.editState.originalFilename);
  }

  get canSave(): boolean {
    return this.isGroupWriteable("recipient_private");
  }

  // Can we enable the form?
  get enableForm(): boolean {
    return this.editState.canEdit && this.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.editState.canEdit && this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.editState.canEdit && this.canSave;
  }

  get getAttachments(): UIAttachment[] {
    return this.currentRecipient.attachments || [];
  }

  // Prepare recipient attachments for presentation in the historical tables.
  get tableRows(): TableRow[] {
    const rows: TableRow[] = this.getAttachments.map((item: UIAttachment) => {
      return {
        id: item.id,
        downloadParams: {
          recipientId: this.clientId || '',
          id: item.id,
        },
        uuid: item.uuid || null,
        deleted: item.deleted || null,
        category: this.translateCategoryCode(item.categoryCode) || '-',
        category_code: item.categoryCode || null,
        dateUploaded: this.parseDisplayDateUiFromDateTime(item.createdAt),
        fileName: item.originalFilename || '-',
        fileType: item.mimeType || null,
        description: item.description || '-',
        uploadedBy: item.updatedBy ? item.updatedBy : item.createdBy,
        checked: false
      };
    });
    return rows;
  }

  get tableConfig(): TableConfig {
    const tableConfig = [
      { label: `${this.$t('attachment_date')}`,
        field: 'dateUploaded',
        width: '125px',
        sortable: false,
        type: 'date',
        dateInputFormat: useCurrentPageStore().configuration.dateFormatDefinition.DISPLAY_DATE,
        dateOutputFormat: useCurrentPageStore().configuration.dateFormatDefinition.DISPLAY_DATE,
      },
      { label: `${this.$t('fileName')}`,
        field: 'fileName',
        sortable: false,
        download: true,
        tooltip: this.$t('download_this_document').toString(),
      },
      { label: `${this.$t('category')}`,
        field: 'category',
        width: '100px',
        sortable: false
      },
      { label: `${this.$t('fileType')}`,
        field: 'fileType',
        sortable: false
      },
      { label: `${this.$t('description')}`,
        field: 'description',
        sortable: false
      },
      { label: `${this.$t('uploadedBy')}`,
        field: 'uploadedBy',
        width: '135px',
        sortable: false
      },
    ];

    return {
      data: this.tableRows,
      columns: tableConfig,
      empty: this.emptyMessage,
      pagination: true,
      paginationOptions: {
        enabled: true,
        perPageDropdown: PAGE_SIZES,
        defaultPageSize: DEFAULT_PAGE_SIZE,
        position: 'bottom'
      }
    };
  }

  // Show loading state or empty message
  get emptyMessage(): string {
    if (!this.getAttachments) {
      return this.$t('loading').toString();
    } else {
      return this.$t('use_form_below').toString();
    }
  }

  // Event handlers

  /**
    * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
    *
    * @listens #mounted
    */
  private async mounted(): Promise<void> {
    await this.currentRecipient.load();
    this.prepareNewEntry();
    this.resetSaveToolbar();
  }

  /**
    * Emits a loaded event after all subcomponents have finished loading.
    *
    * The Recipient Documents card section emits a loaded event when it finishes loading lookup tables. This is the only
    * loading process that this form component is responsible for, so it immediately emits its own loaded event.
    *
    * @listens recipientDocuments#loaded
    * @emits loaded
    */
  public loaded(): void {
    this.$emit('loaded', 'recipientDocuments');
  }

  // Handle deleting a file. Called after user accepts delete warning modal dialog popup
  private async destroyFile(): Promise<void> {
    try {
      const payload: any = {
        selected: this.editState,
        recipient: this.currentRecipient
      };
      await this.editState.delete(payload);
      this.prepareNewEntry();
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  /**
   * Event handle run when clicking on the edit button on a row in the uploaded files table.
   */
  private handleTableRowClick(row: TableRow) {
    const listItem = this.getAttachments.find((item: UIAttachment) => { return item.id === row.id; });
    if (!listItem) return;

    this.selection = new UIListFormSelection(listItem.id);
    this.prepareEditEntry();
    this.resetSaveToolbar();
  }

  private onCreateButtonClicked(): void {
    this.prepareNewEntry();
    this.resetSaveToolbar();
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Load #new rules
  private queryNewRules(): Promise<APIResponseWithPermittedActions> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.attachments.index,
      payload: [[':recipient_id', this.currentRecipient.clientId]],
      prefix: 'recipient_documents',
    });
  }

  public async prepareEditEntry(): Promise<void> {
    try {
      this.editState = new UIAttachment();
      await this.editState.load({ recipientId: this.currentRecipient.clientId as string, id: this.selection.id as string });

      this.resetFormErrors();
      this.resetFileUploader();
    } catch(err) {
      console.warn(err, 'Something unexpected happened when attempting to load serology details');
    }
  }

  /**
   * Clears the Recipient Documents form state for New Document flow
   */
   public async prepareNewEntry(): Promise<void> {    
    const response: APIResponseWithPermittedActions = await this.queryNewRules();
    this.resetFormErrors();

    // TODO: File Input component (this should use v-model)
    this.resetFileUploader();

    this.selection = new UIListFormSelection();
    this.editState = new UIAttachment();
    this.editState.permittedActions = response.permitted_actions;
    this.editState.loaded = true;

    const recipientDocumentsTable = this.$refs.recipientDocumentsTable as TableList|null;
    if (recipientDocumentsTable) recipientDocumentsTable.resetSelection();
  }

  /**
    * Updates form state when Clinical Attachments files are uploaded
    *
    * @listens clinicalAttachments#changed
    */
  private onClinicalAttachmentsChanged(event: any) {
    if (!!this.editState && !!event.target) {{
      this.editState.clinicalAttachments = event.target.files;

      if (this.editState.clinicalAttachments && this.editState.clinicalAttachments[0]) {
        this.editState.filename = this.editState.clinicalAttachments[0].name;
      } else {
        this.editState.filename = '';
      }
    }}
    // reset attachment control only
    const field = this.$refs['recipientAttachment-clinicalAttachment-provider'] as any;
    if (field) field.reset();
  }

  private resetFileUploader(): void {
    const fileUploader = this.$refs.fileUploader as any;
    if (fileUploader) fileUploader.value = null;
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    const saveToolbar = this.$refs.saveDocuments as unknown as SaveToolbar;
    if (saveToolbar) saveToolbar.reset();
  }

  /**
   * Event handler triggered when uploading a new document, or editing an existing one.
   */
  public async handleSave(): Promise<void> {
    this.resetFormErrors();
    const saveToolbar = this.$refs.saveDocuments as unknown as SaveToolbar;
    if (saveToolbar) saveToolbar.startSaving();

    const payload: any = { 
      selected: this.editState, 
      recipient: this.currentRecipient 
    };

    try {
      const success: SaveResult = await this.editState.save(payload);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Clear selection
  private async handleSuccess(success: SaveResult): Promise<void> {
    const saveToolbar = this.$refs.saveDocuments as unknown as SaveToolbar;
    if (saveToolbar) saveToolbar.stopSaving(success);
    try {
      this.prepareNewEntry();
    } catch(err) {
      console.warn(err, 'Something unexpected happen when attempting to load document details');
    }
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    const saveToolbar = this.$refs.saveDocuments as unknown as SaveToolbar;
    if (saveToolbar) saveToolbar.stopSaving(errors);
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    // Re-initialize form
    if (this.selection.isNew) {
      this.prepareNewEntry();
    } else {
      this.prepareEditEntry();
    }
    this.resetSaveToolbar();
  }

  public async generateDownloadLink(id: string): Promise<void> {
    try {
      const payload: any = { selected: id, recipient: this.currentRecipient };
      const success = await this.editState.generateDownloadLink(payload);
      const linkAttachment = new UIAttachment(success.responseData.attachment);
      if (!linkAttachment.url) {
        // If no url, show error message
        alert(this.$t('error_generating_download_link').toString());
      } else {
        // Otherwise generate link and click
        const link = document.createElement('a');
        link.href = linkAttachment.url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }      
    } catch (result: any) {
      // Show download error
      alert(this.$t('error_generating_download_link').toString());
    }
  }

  /**
   * Use the document_attachment_category lookup table to convert from the code to the value (the visual label to present on the tables).
   *
   * @param categoryCode code from a given attachment representing the Attachment Category
   * @returns {string|undefined} attachment's category as text
   */
  private translateCategoryCode(categoryCode?: string|null): string|undefined {
    const lookupTable = this.attachmentCategoryOptions(false);

    if (!!lookupTable) {
      const entry = lookupTable.find((category: AttachmentCategory) => {
        return category.code === Number(categoryCode);
      });

      if (!!entry) {
        return entry.value;
      }
    }

    return undefined;
  }

  // API responses on the left, UI IDs on the right
  public idLookup: IdLookup = {
    'recipient_documents.file':               'recipientAttachment-clinicalAttachment',
    'recipient_documents.original_filename':  'recipientAttachment-fileName',
    'recipient_documents.category_code':      'recipientAttachment-categoryCode',
    'recipient_documents.description':        'recipientAttachment-description'
  }
}
</script>
