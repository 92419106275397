import { APIJourneyDetailsConfig, APIJourneyOverviewConfig, APIPhaseStatusConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIJourneyOverviewConfig {
  public enabled: boolean;
  public journeyDetails: UIJourneyDetailsConfig;
  public phaseStatus: UIPhaseStatusConfig;

  public constructor(apiSource: APIJourneyOverviewConfig) {
    this.enabled = apiSource.enabled;
    this.journeyDetails = new UIJourneyDetailsConfig(apiSource.journey_details);
    this.phaseStatus = new UIPhaseStatusConfig(apiSource.phase_status);
  }
}

export class UIJourneyDetailsConfig {
  public enabled: boolean;
  public urgentListing: UIFeatureConfig;
  public referredWith: UIFeatureConfig;

  public constructor(apiSource: APIJourneyDetailsConfig) {
    this.enabled = apiSource.enabled;
    this.urgentListing = new UIFeatureConfig(apiSource.urgent_listing);
    this.referredWith = new UIFeatureConfig(apiSource.referred_with);
  }
}

export class UIPhaseStatusConfig {
  public enabled: boolean;
  public workflowStep: UIFeatureConfig;

  public constructor(apiSource: APIPhaseStatusConfig) {
    this.enabled = apiSource.enabled;
    this.workflowStep = new UIFeatureConfig(apiSource.workflow_step);
  }
}
