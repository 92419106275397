<template>
  <div class="card-body collapse show">
    <slot name="body" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class WidgetSection extends Vue {
}
</script>
