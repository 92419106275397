import { APIManageWaitlistConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIManageWaitlistConfig {
  public enabled: boolean;
  public activeListingTransfer: UIFeatureConfig;

  public constructor(apiSource: APIManageWaitlistConfig) {
    this.enabled = apiSource.enabled;
    this.activeListingTransfer = new UIFeatureConfig(apiSource.transfer_active);
  }
}
