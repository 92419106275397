import { APIWidgetPreferences, APIWidgetCommonPreferences } from '@/APIModels/user/preferences/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIError } from '@/UIModels/error';
import { SaveResult } from '@/types';
import { APIDashboardWidget } from '@/APIModels/dashboardWidgets/types';
import { GenericCodeValue } from '@/store/types';

export const STYLE_OPTIONS: GenericCodeValue[] = [
  { code: "full",       value: 'style.full' },
  { code: "half",       value: 'style.half' },
  { code: "one-third",  value: 'style.one-third' },
  { code: "two-thirds", value: 'style.two-thirds' }
];

export const DEFAULT_WIDGET_COMMON_PREFERENCES: APIWidgetCommonPreferences = {
  style: 'full',
  visible: true,
};

export const DEFAULT_WIDGET_PREFERENCES: APIWidgetPreferences = {
  common: DEFAULT_WIDGET_COMMON_PREFERENCES,
};

export class UIBaseWidget {
  // Each dashboard widget is based on 1) role-restricted /dashboard_widgets array; and 2) per-user preference data
  public apiDashboardWidget?: APIDashboardWidget;
  public apiWidgetPreferences?: APIWidgetPreferences;

  // Basic widget attributes from /dashboard_widgets response item
  public name = '';
  public required = false;

  // Common widget preferences
  public visible = true;
  public style = 'full';

  // Create new base widget model
  public constructor(apiDashboardWidget?: APIDashboardWidget, apiWidgetPreferences?: APIWidgetPreferences) {
    if (apiDashboardWidget) this.updateFromAPIDashboardWidget(apiDashboardWidget);
    if (apiWidgetPreferences) this.updateFromAPIWidgetPreferences(apiWidgetPreferences);
  }

  // Update base widget model based on /dashboard_widgets response item
  public updateFromAPIDashboardWidget(apiDashboardWidget: APIDashboardWidget): void {
    this.apiDashboardWidget = apiDashboardWidget;
    this.name = apiDashboardWidget.widget_name;
    this.required = apiDashboardWidget.required;
  }

  // Update base widget model based on preferences
  public updateFromAPIWidgetPreferences(apiWidgetPreferences: APIWidgetPreferences): void {
    this.apiWidgetPreferences = apiWidgetPreferences;
    this.visible = apiWidgetPreferences.common.visible;
    this.style = apiWidgetPreferences.common.style;
  }

  // Load base widget preferences
  public async load(): Promise<void> {
    const apiWidgetPreferences: APIWidgetPreferences = (useCurrentPageStore().preferences?.preferenceData?.dashboard?.widgets || {})[this.name] || DEFAULT_WIDGET_PREFERENCES;
    this.updateFromAPIWidgetPreferences(apiWidgetPreferences);
  }

  // Build a copy of the view model
  public copyViewModel() {
    return new UIBaseWidget(this.apiDashboardWidget, this.apiWidgetPreferences);
  }

  // Extract data that needs to be saved to the API for common widget options
  public extractWidgetOptionsPatch(): APIWidgetPreferences {
    const result: APIWidgetPreferences = Object.assign({}, this.apiWidgetPreferences || DEFAULT_WIDGET_PREFERENCES);
    result.common = {
      visible: this.visible,
      style: this.style,
    };
    return result;
  }

  // Save widget panel options
  public async saveWidgetOptions(opts: { selected: UIBaseWidget }): Promise<SaveResult> {
    if (!opts.selected) throw new UIError('widget');
    try {
      const result = await useCurrentPageStore().preferences.saveWidgetOptions(this) as SaveResult;
      const newWidgetPreferences = result.responseData as APIWidgetPreferences;
      opts.selected.updateFromAPIWidgetPreferences(newWidgetPreferences);
      return result;
    } catch (error: unknown) {
      console.warn(error);
      throw new UIError('widget', error);
    }
  }
}
