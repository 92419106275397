import { APIHeartSpecificDetailsConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIHeartSpecificsConfig {
  public enabled: boolean;
  public heartMeasurements: UIFeatureConfig;
  public vadProcedures: UIFeatureConfig;
  public mechanicalCirculatorySupport: UIFeatureConfig;
  public hemodynamicMeasurements: UIFeatureConfig;
  public thoracicMeasurements: UIFeatureConfig;

  public constructor(apiSource: APIHeartSpecificDetailsConfig) {
    this.enabled = apiSource.enabled;
    this.heartMeasurements = new UIFeatureConfig(apiSource.measurements_heart);
    this.vadProcedures = new UIFeatureConfig(apiSource.procedure_vad);
    this.mechanicalCirculatorySupport = new UIFeatureConfig(apiSource.mechanical_circulatory_support);
    this.hemodynamicMeasurements = new UIFeatureConfig(apiSource.measurements_heart_hemodynamic);
    this.thoracicMeasurements = new UIFeatureConfig(apiSource.thoracic_measurements);
  }
}
