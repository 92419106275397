import { APIFeatureConfig, APIGeneralClinicalConfig, APIRecipientConfig } from '@/APIModels/configuration/features/types';
import { UIFeatureConfig } from '@/UIModels/configuration/features';
import { UIDemographicsConfig } from '@/UIModels/configuration/features/recipientConfig/demographics';
import { UIContactInfoConfig } from '@/UIModels/configuration/features/recipientConfig/contactInfo';

export class UIRecipientConfig {
  public enabled: boolean;
  public demographics: UIDemographicsConfig;
  public contactInfo: UIContactInfoConfig;
  public recipientDocuments: UIFeatureConfig;
  public recipientGeneralClinical: UIRecipientGeneralClinicalConfig;
  public recipientSerology: UIFeatureConfig;
  public recipientDeath: UIFeatureConfig;

  public constructor(apiSource: APIRecipientConfig) {
    this.enabled = apiSource.enabled;
    this.demographics = new UIDemographicsConfig(apiSource.demographics);
    this.contactInfo = new UIContactInfoConfig(apiSource.contact_info);
    this.recipientDocuments = new UIFeatureConfig(apiSource.recipient_documents);
    this.recipientGeneralClinical = new UIRecipientGeneralClinicalConfig(apiSource.general_clinical);
    this.recipientSerology = new UIFeatureConfig(apiSource.virology);
    this.recipientDeath = new UIFeatureConfig(apiSource.death);
  }
}

export class UIRecipientGeneralClinicalConfig {
  public enabled: boolean;
  public bloodType: UIFeatureConfig;
  public measurements: UIFeatureConfig;

  public constructor(apiSource: APIGeneralClinicalConfig) {
    this.enabled = apiSource.enabled;
    this.bloodType = new UIFeatureConfig(apiSource.blood_type);
    this.measurements = new UIFeatureConfig(apiSource.measurements);
  }
}

