// Master list of prototypes
export enum PROTOTYPES {
  ChecklistReorder = 'proto_checklists_reorder',
  CommunicationInformation = 'proto_communication_information',
  TransportationAndMobility = 'proto_transportation_and_mobility',
  PaceAndEngagement = 'proto_pace_and_engagement',
  PrioritizationReport = 'proto_prioritization_report',
  SchedulingPreferences = 'proto_scheduling_preferences',
  ProtoRecipientsListings = 'proto_recipient_listings',
  CareGivers = 'proto_care_givers',
  WidgetReminders = 'proto_widget_reminders'
}
