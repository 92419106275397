import { APIChecklistConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIChecklistConfig {
  public enabled: boolean;
  public outcomes: UIFeatureConfig;

  public constructor(apiSource: APIChecklistConfig) {
    this.enabled = apiSource.enabled;
    this.outcomes = new UIFeatureConfig(apiSource.outcomes);
  }
}
