<template>
  <nav id="side-nav" 
    :class="{ active: isVisible, footerIsVisible: footerIsVisible }" 
    class="page-nav" 
    :style="{ 'top': topPosition + 'px' }">
    <slot name="side-nav-content" />
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component
export default class BaseSideNav extends Vue {
  @Prop({ default: 170 }) scrollThresholdMobile!: number;
  @Prop({ default: 190 }) scrollThresholdDesktop!: number;

  private isVisible = false; 
  private footerIsVisible = false;
  private topPosition = "0";
  
  private mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
    this.checkwidth();
    window.addEventListener('resize', this.checkwidth);
  } 

  private getTopPosition(lastPosition: any, view: any): void {
    const content = document.querySelector(".summary-sticky");
    if ( content ) {
      const topPosition = content.scrollHeight;
      if ( view == "mobile" ) {
        if (lastPosition >= topPosition && lastPosition > this.scrollThresholdMobile ) {
          this.topPosition = (content.scrollHeight).toString();
        } else {
          this.topPosition = '-1';
        }
      } else {
        if ((lastPosition >= topPosition) && (lastPosition > this.scrollThresholdDesktop)) {
          this.topPosition = (content.scrollHeight + 20) .toString();
        }
      }
    }
  }

  public checkwidth() {
    const lastPosition = window.pageYOffset;
    if ((window.innerWidth <= 992)) {
      this.getTopPosition(lastPosition, 'mobile');
    } else {
      this.getTopPosition(lastPosition, 'desktop');
    }
  }

  // Event listeners
  private handleScroll(): void {
    const element = document.querySelector('#sumSticky');
	  if (element !== null) {
      const position = element.getBoundingClientRect();
      if(position.top < window.innerHeight && position.bottom >= 0) {
        this.footerIsVisible = true;
      }
      else {
        this.footerIsVisible = false;
      }
    }

    const lastPosition = window.pageYOffset;
    if ((window.innerWidth >= 992) && (lastPosition > this.scrollThresholdDesktop)) {
      this.isVisible = true;
    } else if ((window.innerWidth <= 992) && (lastPosition > this.scrollThresholdMobile)) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.checkwidth();
  }
}
</script>
