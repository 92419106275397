<template>
  <div v-if="isProtoTransportationAndMobilityEnabled">
    <card-section
      section-id="proto_transportation_and_mobility"
    >
      <template v-slot:header>
        {{title}}
      </template>
      <template v-slot:body >
        <form-layout form-id="proto-transportation-and-mobility-form">
          <template v-slot:contents>
            <sub-section 
              :title="title" 
              sub-section-id="proto-transportation-and-mobility-internal" 
              :hideDivider="true"
              >
              <template v-slot:contents>
                <div class="row">
                  <div class="col-sm-12 transportation-section">
                    <fieldset>
                      <div class="row">
                        <div class="standard-form-group form-group-checkbox">
                          <label>Patient has own transportation?</label>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="interpreter-needed" hideLabel="true" labelName="has-transportation-true"
                                inputId="has-transportation-true" v-model="hasTransportationTrue" label='Yes' :disabled="hasTransportationFalse"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="interpreter-needed" hideLabel="true" labelName="has-transportation-false"
                                inputId="has-transportation-false" v-model="hasTransportationFalse" label='No' :disabled="hasTransportationTrue"/>
                            </div>
                          </div>
                        </div>
                        <div class="standard-form-group form-group-checkbox">
                          <label>Flags</label>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="avoid-cities" hideLabel="true" labelName="Avoid cities" inputId="avoid-cities"
                                v-model="avoidCities" label='Avoid cities' />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="avoid-highways" hideLabel="true" labelName="Avoid highways" inputId="highways"
                                v-model="avoidHighways" label='Avoid highways' />
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="gas-money-sensitivity" hideLabel="true" labelName="Gas money sensitivity"
                                inputId="gas-money-sensitivity" v-model="gasMoneySensitivity" label='Gas money sensitivity' />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <checkbox-input name="mobility-challenges" hideLabel="true" labelName="Mobility challenges"
                                inputId="mobility-challenges" v-model="mobilityChallenges" label='Mobility challenges' />
                            </div>
                          </div>
                        </div>
                        <div class="standard-form-group-large">
                          <div class="row">
                            <div class="col-6">
                              <number-input inputId="distance-miles" name="Max Distance (miles)" v-model="maxMiles" />

                            </div>
                            <div class="col-6">
                              <number-input inputId="max-time" name="Max Time (minutes)" v-model="maxMinuites" />

                            </div>
                          </div>
                        </div>

                        <div class="standard-form-group-large form-group-checkbox">
                          <label>Preferred Transportation Methods</label>
                          <div class="row">
                            <div class="col-6 mb-2">
                              <checkbox-input name="method-car" hideLabel="true" labelName="Car" inputId="method-car"
                                v-model="methodCar" label='Car' />
                            </div>
                            <div class="col-6 align-vertical">
                              <text-input inputId="car-details" name="car-details" hideLabel="true" v-model="carDetails" />
                            </div>
                            <div class="col-6 mb-2">
                              <checkbox-input name="method-public" hideLabel="true" labelName="Public Transportation"
                                inputId="method-public" v-model="methodPublic" label='Public Transportation' />
                            </div>
                            <div class="col-6 align-vertical">
                              <text-input inputId="public-details" name="public-details" hideLabel="true" v-model="publicDetails" />
                            </div>
                            <div class="col-6 mb-2">
                              <checkbox-input name="method-ride" hideLabel="true" labelName="Ride Share" inputId="method-ride"
                                v-model="methodRide" label='Ride Share' />
                            </div>
                            <div class="col-6 align-vertical">
                              <text-input inputId="ride-details" name="ride-details" hideLabel="true" v-model="rideDetails" />
                            </div>
                            <div class="col-6 mb-2">
                              <checkbox-input name="method-medicaid" hideLabel="true" labelName="Medicaid Transportation"
                                inputId="method-medicaid" v-model="methodMedicaid" label='Medicaid Transportation' />
                            </div>
                            <div class="col-6 align-vertical">
                              <text-input inputId="medicaid-details" name="medicaid-details" hideLabel="true"
                                v-model="medicaidDetails" />
                            </div>
                            <div class="col-6 mb-2">
                              <checkbox-input name="method-other" hideLabel="true" labelName="Other" inputId="method-other"
                                v-model="methodOther" label='Car' />
                            </div>
                            <div class="col-6 align-vertical">
                              <text-input inputId="other-details" name="other-details" hideLabel="true" v-model="otherDetails" />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="standard-form-group-3-quarters">
                          <text-area-input input-id="additional-information" name="Mobility Impairments and Devices"
                            rows="4"
                            :showToolTip="true"
                            toolTipText="Describe the patient's mobility impairments and devices and how they impact transportation and scheduling."
                            v-model="mobilityInpairmentsDevices" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="standard-form-group-3-quarters">
                          <text-area-input input-id="inpairments-devices" name="Comments"
                            rows="4"
                            v-model="comments" />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </template>
            </sub-section>
          </template>

          <template v-slot:save>
            <save-toolbar
              :show="true"
              ref="saveTransportation"
              :label="`Save ${title}`"
              :cancelButton="true"        
            />
          </template>
        </form-layout>
      </template>
    </card-section>
  </div>
</template>


<script lang="ts">
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-facing-decorator';
  import CardSection from '@/components/shared/CardSection.vue';
  import SubSection from '@/components/shared/SubSection.vue';
  import CheckboxInput from '@/components/shared/CheckboxInput.vue';
  import NumberInput from '@/components/shared/NumberInput.vue';
  import TextInput from '@/components/shared/TextInput.vue';
  import TextAreaInput from '@/components/shared/TextAreaInput.vue';
  import SelectInput from '@/components/shared/SelectInput.vue';
  import FormLayout from '@/components/shared/FormLayout.vue';
  import SaveToolbar from '@/components/shared/SaveToolbar.vue';
  import { useCurrentPageStore } from '@/stores/currentPage';
  import { PROTOTYPES } from '@/UIModels/prototypes/list';
  
  @Component({
    components: {
      CardSection,
      SubSection,
      TextInput,
      TextAreaInput,
      SelectInput,
      CheckboxInput,
      SaveToolbar,
      FormLayout,
      NumberInput
    },
    emits: [
      'cancel',
    ],
  })
  export default class ProtoTransportationAndMobility extends Vue {

    private title: any = "Transportation and Mobility";
    private hasTransportationTrue = null;
    private hasTransportationFalse = null;
    private avoidCities = null;
    private avoidHighways = null;
    private gasMoneySensitivity = null;
    private mobilityChallenges = null;
    private maxMiles = null;
    private maxMinuites = null;
    private methodCar = '';
    private carDetails = '';
    private methodPublic = '';
    private publicDetails = '';
    private methodRide = '';
    private rideDetails = '';
    private methodMedicaid = '';
    private medicaidDetails = '';
    private methodOther = '';
    private otherDetails = '';
    private mobilityInpairmentsDevices = '';
    private comments = '';


    get isProtoTransportationAndMobilityEnabled(): boolean {
      return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.TransportationAndMobility);
    }

    public performSave(): any {

      const payload: any = {
      hasTransportationTrue: this.hasTransportationTrue,
      hasTransportationFalse: this.hasTransportationFalse,
      avoidCities: this.avoidCities,
      avoidHighways: this.avoidHighways,
      gasMoneySensitivity : this.gasMoneySensitivity,
      mobilityChallenges : this.mobilityChallenges,
      maxMiles : this.maxMiles,
      maxMinuites :  this.maxMinuites,
      methodCar : this.methodCar,
      carDetails : this.carDetails,
      methodPublic : this.methodPublic,
      publicDetails : this.publicDetails,
      methodRide : this.methodRide,
      rideDetails :  this.rideDetails,
      methodMedicaid : this.methodMedicaid,
      medicaidDetails : this.medicaidDetails,
      methodOther : this.methodOther,
      otherDetails : this.otherDetails,
      mobilityInpairmentsDevices : this.mobilityInpairmentsDevices,
      comments : this.mobilityInpairmentsDevices
    };

    } 
  }

</script>

<style>

.form-group-checkbox .col-12, .form-group-checkbox .col-6{

  padding-top: 0;
  padding-bottom: 0;
}

.form-group-checkbox .form-check-inline {
    width: 100% !important;
}
.form-group-checkbox .form-check-inline label {
    width: 100% !important;
    padding-top: .43rem;
}

.align-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

</style>
