<template>
  <div v-if="uiConfig" :id="uiConfig.appBaseClass" :class="`site-wrap ${authenticationClass}`">
    <template v-if="uiConfig.loaded && !uiConfig.sanityCheck">
      <warning-banner/>
    </template>
    <template v-else>
      <!-- show application -->
      <template v-if="baseInfoLoaded">
        <site-header v-if="isLoggedIn" />
        <router-view role="main" />
        <site-footer v-if="isLoggedIn"/>
      </template>
      <template v-else>
        <div class="loading"/>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, mixins } from 'vue-facing-decorator';
import SiteHeader from './components/shared/SiteHeader.vue';
import SiteFooter from './components/shared/SiteFooter.vue';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import WarningBanner from '@/views/WarningBanner.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIPrototypes } from '@/UIModels/prototypes';

@Component({
  components: {
    SiteHeader,
    SiteFooter,
    WarningBanner
  }
})
export default class App extends mixins(TranslationUtilsMixin) {
  @Getter('hasAccessToken', { namespace: 'users' }) private hasAccessToken!: boolean;
  @Getter('isLoggedIn', { namespace: 'users' }) private isLoggedIn!: boolean;

  public baseInfoLoaded = false;
  public organTypes: [] = [];

  /**
   * Returns css class whether logged-in / logged-out 
   *
   * @returns {string}
   */  
  get authenticationClass(): string {
    return this.isLoggedIn ? 'logged-in' : 'logged-out';
  }

  /**
   * true if on /login url
   *
   * @returns {boolean}
   */
  get isLoginPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/login') ? true : false;
  }

  /**
   * true if on /access_token url
   *
   * @returns {boolean}
   */
  get isAccessTokenPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/access_token') ? true : false;
  }

  get uiConfig(): UIConfiguration {
    return useCurrentPageStore().configuration as UIConfiguration;
  }

  get uiPrototypes(): UIPrototypes {
    return useCurrentPageStore().prototypes as UIPrototypes;
  }

  public mounted(): void {
    if (this.hasAccessToken && !this.isLoginPage && !this.isAccessTokenPage) {
      this.$store.dispatch('users/loadUser').then(() => {
        Promise.all([
          this.uiConfig.load(),
          this.$store.dispatch('lookups/load', { lookup: 'organ' }),
        ]).then(() => {
          this.uiPrototypes.load(),
          this.setupI18n();
          this.baseInfoLoaded = true;
        });
      });
    } else {
      this.uiConfig.load().then(() => {
        this.uiPrototypes.load(),
        this.setupI18n();
        this.baseInfoLoaded = true;
      }).catch((error) => {
        this.baseInfoLoaded = true;
      });
    }
  }
}
</script>
