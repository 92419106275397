import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "character-counter"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "mobile-spacing-wrapper" }
const _hoisted_6 = ["id", "readonly", "tabindex", "rows"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("label", {
          for: _ctx.inputId,
          class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
        }, [
          _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
          (!_ctx.disabled)
            ? (_openBlock(), _createBlock(_component_validation_asterisk, {
                key: 0,
                rules: _ctx.formRules,
                crossValues: _ctx.crossValues,
                ruleKey: _ctx.ruleKey
              }, null, 8, ["rules", "crossValues", "ruleKey"]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2),
        (_ctx.maxLength > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getCounter ? _ctx.getCounter : 0) + " / " + _toDisplayString(_ctx.maxLength), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showGuidingText)
        ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.guidingText), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("textarea", _mergeProps({
          ref: "textarea",
          id: _ctx.inputId,
          type: "text",
          class: ["mt-2", { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) }]
        }, field, {
          readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled),
          tabindex:  _ctx.readonly ? -1 : 0,
          rows: _ctx.rows
        }, _toHandlers(_ctx.inputEvents(), true)), null, 16, _hoisted_6),
        (errors[0] && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "invalid-feedback",
              id: `${_ctx.inputId}-error`
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'exclamation-circle'],
                "fixed-width": ""
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
            ], 8, _hoisted_7))
          : _createCommentVNode("", true),
        (_ctx.showAdvisoryMessage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "advisory-message",
              id: `${_ctx.inputId}-warning`
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'exclamation-circle'],
                "fixed-width": ""
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('max_Length_message', { maxLength: _ctx.maxLength })), 1)
            ], 8, _hoisted_8))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}