import { GenericCodeValue } from '@/store/types';

export interface LookupsState {
  /**
   * Each lookup table is stored using its lookup_id as a key and its entries as an array value.
   */
  dispatchTable: any[];
  promiseArray: any[];
  [key: string]: any[];
}

export interface DispatchEntry {
  [key: string]: DispatchType;
}

export enum DispatchType {
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed'
}

export interface DefaultLookupCodes {
  [key: string]: any;
}

export interface LookupEntry {
  code: string|number;
  value?: string;
  values?: {
    [locale: string]: string;
  }
}


export interface Ethnicity {
  code: number;
  value: string;
}

// NOTE: 'sex' and 'gender' are configured as two different lookups
// See: Afflo TxC (v1.0 - 2024) Data Dictionary & UI Spec
export interface Sex extends GenericCodeValue {} // string code
export interface Gender extends GenericCodeValue {} // string code

export enum discontinueAllocationLookupType {
  DonorRelated = '1',
  OfferRelated = '2'
}

export interface discontinueAllocationEntries {
  code?: number;
  value?: string;
  sub_tables?: discontinueAllocationReasonsSubTables;
}

export interface discontinueAllocationReasonsSubTables {
  discontinue_allocation_reasons?: discontinueAllocationReason[];
}

export interface discontinueAllocationReason {
  code?: number;
  value?: string;
}

export interface InsurancePlanCode {
  code: number;
  value: string;
  insurance_number_mask_format?: string|null;
}

export enum InsuranceType {
  // FIXME Keep the other types for now
  'None' = 0,
  'OtherProvince' = 2,
  'Other' = 7,
  'OHIP' = 1,
  'OutOfCanada' = 6,
}

export interface BloodType {
  code: string;
  value: string;
  donor: boolean;
}

export interface SubBloodType {
  code: string;
  value: string;
}

export enum  BloodTypeValue {
  A = 'A',
  B = 'B',
  AB = 'AB',
  Unknown = 'U',
}

export enum SubBloodTypeValue {
  A1 = 'A1',
  A2 = 'A2',
  A1B = 'A1B',
  A2B = 'A2B',
}

export interface RhIndicator {
  code: string;
  value: string;
  donor: boolean;
}

export interface Region {
  code: string;
  value: string;
}


export enum RhIndicatorValue {
  Unknown = '?',
}

export enum YesNoUnknown {
  Yes = 'Y',
  No = 'N',
  Unknown =  'U'
}

export enum SourceOfInformation {
  TransplantProgram = 6
}

export enum RecipientStatus {
  AliveWithFunctionalGraft = 1,
  GraftFailure = 2,
  LostToFollowUp = 3,
  RecipientDeath = 4
}

export interface Organ {
  code: number;
  type: string;
  priority: string|null;
  abbreviation: string;
  value: string;
  expired_date: string;
  cause_of_failure: OrganCauseOfFailure[];
  waitlist_medical_status: OrganWaitlistMedicalStatus[];
  sub_tables: OrganSubTables;
}

export enum OrganCodeValue {
  Liver = 1,
  Heart = 2,
  Kidney = 3,
  Lung = 4,
  PancreasWhole = 6,
  PancreasIslets = 6.5,
  SmallBowel = 7,
  VCA = 7.5,
  VesselsForBanking = 100
}

// for checklists
export enum OrganCodeChecklistValue {
  Liver = "1",
  Heart = "2",
  KidneyLocal = "3_local",
  KidneyProvincial = "3_provincial",
  Lung = "4",
  PancreasWhole = "6",
  // PancreasLocalWhole = "6_local",
  // PancreasProvincialWhole = "6_provincial",
  PancreasIslets = "6.5",
  SmallBowel = "7",
  VCA = "7.5",
}

export enum OrganOfferSpecificationCodeValue {
  BothLungs  = 401,
  RightLung  = 402,
  LeftLung   = 403,
  WholeLiver = 101,
  RightLiver = 102,
  LeftLiver  = 103,
  SingleKidney = 301,
  RightKidney = 311,
  LeftKidney = 312,
  RightLobeLung = 452,
  LeftLobeLung = 453,
}

export const OrganOfferSpecificationCodeValueToOrganCode = {
  411: 4, // lung
  421: 4, // lung
  422: 4, // lung
  423: 4, // lung
  433: 4, // lung
  432: 4, // lung
  401: 4, //BothLungs  = 401,
  402: 4, //RightLung  = 402,
  403: 4, //LeftLung   = 403,
  101: 1, //WholeLiver = 101,
  102: 1, //RightLiver = 102,
  103: 1, //LeftLiver  = 103,
  199: 1, // liver
  301: 3, //SingleKidney = 301,
  302: 6, //SinglePancreas = 302,
  303: [3, 6], //Kidney&Pancreas = 303,
  304: 3, //KidneyBoth = 304,
  305: [3, 6], //KidneyBoth & pancreas = 305,
  311: 3, //RightKidney = 311,
  312: 3, //LeftKidney = 312,
  451: 4, //LungLobe = 451,
  452: 4, //RightLobeLung = 452,
  453: 4, //LeftLobeLung = 453,
};

export const NotificationSubGroups = {
  A_MEDICAL_HOLD_AND_SUSPENSION: [5900, 5920, 6000, 5910, 5930, 6080],
  B_HLA_SERUM: [5700, 5800, 6120, 6130, 6140, 6150],
  C_LIVER: [6010, 6020, 6030, 6060, 6070, 6040, 6050],
  D_JOURNEY: [5000, 5100, 5110, 5120, 5600, 5400],
  E_POST_TRANSPLANT: [6100, 6110],
  F_PATIENT_TRANSFER: [5300, 5310, 5340, 5350, 5370, 5380],
};

export const TransplantProgramNotificationChannels = [
  5900, 5920, 6000, 5910, 5930, 6080,
  5700, 5800, 6120, 6130, 6140, 6150,
  6010, 6020, 6030, 6060, 6070, 6040, 6050,
  5000, 5100, 5110, 5120, 5600, 5400,
  6100, 6110,
  5300, 5310, 5340, 5350, 5370, 5380
];

export const NotificationCodeOrdering = {
  // A_MEDICAL_HOLD_AND_SUSPENSION
  '5900': 1,
  '5920': 2,
  '6000': 3,
  '5910': 4,
  '5930': 5,
  '6080': 6,

  // B_HLA_SERUM
  '5700': 1,
  '5800': 2,
  '6130': 3,
  '6120': 4,
  '6140': 5,
  '6150': 6,

  // C_LIVER
  '6010': 1,
  '6020': 2,
  '6030': 3,
  '6060': 4,
  '6070': 5,
  '6040': 6,
  '6050': 7,

  // D_JOURNEY
  '5000': 1,
  '5100': 2,
  '5110': 3,
  '5120': 4,
  '5600': 5,
  '5400': 6,

  // E_POST_TRANSPLANT
  '6100': 1,
  '6110': 2,

  // F_PATIENT_TRANSFER
  '5300': 1,
  '5310': 2,
  '5340': 3,
  '5350': 4,
  '5370': 5,
  '5380': 6,
};

export enum OrganTypeValue {
  Single = 'single',
  Combination = 'combination',
  Cluster = 'cluster',
}

export interface OrganSubTables {
  organ_specifications?: OrganSpecification[];
  cause_of_failure: OrganCauseOfFailure[];
  disease_code: OrganDiseaseCode[];
  waitlist_medical_status: OrganWaitlistMedicalStatus[];
  absolute_listing_contraindications_adult?: AbsoluteContraindication[];
  relative_listing_contraindications_adult?: RelativeContraindication[];
  absolute_listing_contraindications_pediatric?: AbsoluteContraindication[];
  relative_listing_contraindications_pediatric?: RelativeContraindication[];
}

export interface OrganDiseaseCode {
  code: number;
  icd10_code:	string|null;
  value: string;
}

export interface OrganSpecification {
  code: number;
  organ_spec_code: string;
  transplant: boolean;
  recovery:	boolean;
  failure_registration:	boolean;
  living_related_donation: boolean;
  crossmatch_test: boolean;
  parent_organ_spec: string|null;
  value: string;
  recipient?: boolean;
  offer?: boolean;
  double_lung?: boolean;
  disabled?: boolean;
  restricted_permissions?: boolean;
  double_kidney?: boolean|null;
  effective_date?: string;
  expired_date?: string|null;
  deceased_donor?: boolean;
  living_donor?: boolean;
}

export interface RecipientStatusPostTransplant {
  code: number;
  deceased_recipient: boolean;
  value: string;
}

// types of offers e.g. Primary, Backup, and No Offer
export interface OfferType {
  code: string;
  value: string;
  // subtable for 'No Offer' categories and reasons
  sub_tables?: {
    no_offer_reason_categories: OfferReasonCategory[];
  }
  // general properties that determine if option can be selected
  effective_date?: string;
  expired_date?: string|null;
  disabled?: boolean;
  restricted_permissions?: boolean;
  // specific flags that affect what kinds of offers can be made
  may_require_out_of_sequence_reason?: boolean;
  requires_current_offer_type?: string;
  requires_current_offer_response?: string;
}

// for No Offer, user must also select category
export interface OfferReasonCategory {
  code: number;
  value: string;
  sub_tables?: {
    no_offer_reasons: OfferReason[];
  }
  disabled?: boolean;
  restricted_permissions?: boolean;
}

// for No Offer, user selects reason (based on category)
export interface OfferReason {
  code: number;
  value: string;
  disabled?: boolean;
  restricted_permissions?: boolean;
}

export interface OrganCauseOfFailure {
  code: number;
  former_description: string;
  value: string;
}

export interface OrganWaitlistMedicalStatus {
  code: string;
  rawValue: string;
  value: string;
  priority?: number;
  disabled?: boolean;
  effective_date?: string;
  expired_date?: string;
  restricted_permissions?: boolean;
  urgent_listing_option?: boolean;
}

export enum OrganWaitlistMedicalStatusValue {
  OnHold = '0',
}

export interface OrganizationType {
  code: number;
  value: string;
}

export interface ProfessionalType {
  code: number;
  value: string;
}

export enum CountryValue {
  Canada = 'CAN',
  USA = 'USA',
  Other = 'XX',
}

export interface ContactDetailCategoryLookup {
  code: string;
  value: string;
  format_code: string;
  default: boolean;
  actions: string[];
}

export interface AddressCategoryLookup {
  code: string;
  value: string;
  format_code: string;
  default: boolean;
  default_country?: string;
  default_province?: string;
}

export interface LhinProgram {
  code: number;
  value: string;
}

export enum HeartMedicalStatusValue {
  Sensitized = '4S',
}

export const HEART_STATUSES_EXCLUDED_FROM_SECONDARY = ['0', '4', '4S'];

export interface CauseOfDeathCategory {
  code: string;
  former_value: string;
  value: string;
  cause_of_death_category_types: CauseOfDeathCategoryTypes[];
}

export interface CauseOfDeathCategoryTypes {
  code: number;
  former_value: string;
  display_order: number;
  value: string;
}

export interface CauseOfDeathDonor {
  code: string;
  value: string;
}

export interface Country {
  code: string;
  value: string;
  sub_tables: CountrySubTables;
}

export interface CountrySubTables {
  province: Province[];
}

export interface Province {
  code: string;
  value: string;
  sub_tables?: {
    administrative_regions?: AdministrativeRegion[];
  }
}

export interface AdministrativeRegion {
  code: string;
  value: string;
}

export interface VirologyCode {
  code: string;
  value: string;
  donor: boolean;
  living_donor: boolean;
  recipient: boolean;
  donor_label: string;
  living_donor_label: string;
  value_french: string;
  disabled: boolean;
  virology_result_class: string;
}

export interface AllResponsiblePhysicians {
  code?: string;
  value?: string;
}

export interface AllConsultingPhysicians {
  code?: string;
  value?: string;
}

export interface AllAssessmentOutcomes {
  code?: string;
  value?: string;
}

export interface AllRecipientCoordinators {
  code?: string;
  value?: string;
}

export interface AllPhoneNumberTypes {
  code?: string;
  value?: string;
}

export interface AllDonorTypes {
  code?: string;
  value?: string;
}

export interface DeceasedDonorType {
  code?: string;
  value?: string;
}

export interface AllHospitals {
  code?: string;
  value?: string;
}

export interface AllDispositionStatus {
  code?: string;
  value?: string;
}

export interface RecipientReferralStatusType {
  code: number;
  value: string;
  sub_tables: RecipientReferralStatusTypeDetail[];
}

export interface RecipientReferralStatusTypeDetail {
  code: number;
  value: string;
  sub_tables?: {
    recipient_referral_status_type_detail?: [];
  };
}

export interface DialysisType {
  code: number;
  former_description: string;
  value: string;
}

export interface DialysisAccessMode {
  code: number;
  value: string;
}

export interface DialysisLocationType {
  code: string;
  value: string;
}

export enum DialysisLocationTypeValue {
  Other = '4',
}

export interface DonorOrganTransplantProgram {
  code: number;
  value: string;
}

export interface DonorRefererProfession {
  code: string;
  value: string;
}

export interface HlaDictionaryEntry {
  code: string;
  value: string;
  serological_locus: string;
  sub_tables: {
    molecular_codes: HlaMolecularCode[];
    serological_codes: HlaSerologicalCode[];
  };
}

export interface HlaMolecularCode {
  code: string;
  value: string;
  serological: HlaSerologicalRelation[];
  epitopes: string[];
}

export interface HlaSerologicalCode {
  code: string;
  value: string;
  molecular: HlaMolecularRelation[];
  epitopes: string[];
}

export interface HlaSerologicalRelation {
  code: string;
  value: string;
  tbc: boolean;
}


export interface HlaMolecularRelation {
  code: string;
  value: string;
  tbc: boolean;
}

export interface RecipientReferralDecision {
  code: number;
  value: string;
  final?: boolean;
  sub_tables: {
    recipient_referral_decision_reason: RecipientReferralDecisionReason[];
  };
}

export interface RecipientReferralDecisionReason {
  code: number;
  value: string;
}

export enum RecipientReferralDecisionValue {
  Declined = 3,
  Cancelled = 5,
}

export interface RecipientConsultationDecision {
  code: number;
  value: string;
  final?: boolean;
  sub_tables: {
    recipient_consultation_decision_reason_codes: RecipientConsultationDecisionReason[];
  };
}

export interface RecipientConsultationDecisionReason {
  code: number;
  value: string;
}

export enum RecipientConsultationDecisionValue {
  Cancelled = 4,
  Completed = 6,
  Delayed = 7,
}

export interface RecipientAssessmentDecision {
  code: number;
  value: string;
  final?: boolean;
  sub_tables?: {
    recipient_assessment_decision_reason_codes: RecipientAssessmentDecisionReason[];
  };
}

export interface RecipientAssessmentDecisionReason {
  code: number;
  value: string;
  organ_codes: number[];
}

export interface AbsoluteContraindication {
  code: number;
  value: string;
  disabled?: boolean;
}

export interface RelativeContraindication {
  code: number;
  value: string;
  disabled?: boolean;
}

export enum RecipientAssessmentDecisionValue {
  NotEligible = 2,
  FurtherTreatment = 3,
  Cancelled = 4,
}

export enum RecipientAssessmentDecisionReasonValue {
  ListingCriteria = 3,
  Other = 16,
}

export enum ExceptionalDistributionCodeValues {
  OtherTravel = 200,
  Other = 300,
  OtherIncreasedRisk = 406,
}

// filter out ExD reason codes [200, 300, 400, 406] (see B#15171)
export const SYSTEM_ONLY_EXCEPTIONAL_DISTRIBUTION_REASONS = [
  ExceptionalDistributionCodeValues.OtherTravel,
  ExceptionalDistributionCodeValues.Other,
  ExceptionalDistributionCodeValues.OtherIncreasedRisk
];

export interface ReasonForMedicalHold {
  // a.k.a. waitlist stat reason
  code: number;
  value: string;
  other_selected?: boolean;
}

export interface InotropeTypes {
  code: number;
  value: string;
}

export interface HlaDictionaryEpitopeResult {
  bw4: string|null;
  bw6: string|null;
}

export const EPITOPE_PRESENT = '1';
export const EPITOPE_NOT_PRESENT = '2';
export const EPITOPE_OPTIONS = [
  { code: EPITOPE_PRESENT, value: 'Present' },
  { code: EPITOPE_NOT_PRESENT, value: 'Not Present' },
];

export interface YesNoNoSelection {
  code: string;
  value: string;
}

export interface AttachmentCategory {
  code: number;
  value: string;
}

export interface RecipientNotificationEmail {
  code: string;
  value: string;
  value_french: string;
  other_selected: boolean;
  full_email: string;
  effective_date: string;
  expired_date: string;
}

// TODO: Temporary message, replace me
export const FINAL_DECISION_CONFIRMATION = 'This is a final decision, are you sure?';

export interface HlaEpitope {
  code: string;
  value: string;
  ui_aliases: string[];
  sub_tables: {
    molecular_codes: HlaEpitopeMolecular[];
    serological_codes: HlaEpitopeSerologic[];
  };
}

export interface HlaEpitopeMolecular {
  code: string;
  value: string;
  locus: string;
}

export interface HlaEpitopeSerologic {
  code: string;
  value: string;
  locus: string;
}

export interface HlaAntibodyTestKit {
  code: string;
  value: string;
  supplier_code: string;
  supplier_name: string;
  class: number;
  lot: string;
  bead_count: number;
  prefill_antibodies: string[];
  prefill_epitopes: string[];
  bead_list: HlaTestingKitBead[]|null;
  effective_date: string;
  expired_date: string|null;
}

export interface HlaTestingKitBead {
  molecular: string;
  serological: string;
  epitopes: string[];
}
export interface ReferralFactorCode {
  code: number;
  value: string;
}

export interface AssessmentFactorCode {
  code: number;
  value: string;
}

export interface WaitlistFactorCode {
  code: number;
  value: string;
  abbreviated_value?: string;
}

export interface TransplantFactorCode {
  code: number;
  value: string;
}

export interface PostTransplantFactorCode {
  code: number;
  value: string;
}
export interface TperStatusCode {
  code: number;
  value: string;
}

export interface RecipientSignificantEventFactorCode {
  code: string;
  value: string;
  sub_tables: {
    referral_factor_codes?: ReferralFactorCode[];
    assessment_factor_codes?: AssessmentFactorCode[];
    waitlist_factor_codes?: WaitlistFactorCode[];
    transplant?: TransplantFactorCode[];
    post_transplant_factor_codes?: PostTransplantFactorCode[];
  };
}

export enum RecipientSignificantEventFactorCodeStage {
  Referral       = 'referral',
  Assessment     = 'assessment',
  Waitlist       = 'waitlist',
  Transplant     = 'transplant',
  PostTransplant = 'post-transplant',
}

// Values must be consistent with recipient_significant_event_factor_codes lookup waitlist subtable!!!
export enum WaitlistFactorCodeValue {
  ListingDate          = 1,
  Hold                 = 100,
  HoldSerumHla         = 101,
  HoldMedical          = 102,
  HoldIncompleteData   = 103,
  HoldCluster          = 105,
  Suspended            = 200,
  SuspendedMedical     = 201,
  SuspendedLiverNaMeld = 202,
  SuspendedLiverHcc    = 203,
  SuspendedHeart       = 204,
  SuspendedCluster     = 205,
  MedicalStatus        = 2,
}

export interface ImplantTypes {
  code: number;
  value: string;
}
export interface VadIndications {
  code: number;
  value: string;
}

export interface VadInsertSides {
  code: number;
  value: string;
}
export interface VadDevices {
  code: number;
  value: string;
}
export interface VadDiscontinuedReasons {
  code: number;
  value: string;
}
export interface VcaTypes {
  code: number;
  value: string;
}
export interface VcaLimbTypes {
  code: number;
  value: string;
}
export interface VcaSkinPigmentations {
  code: number;
  value: string;
}

export enum VadInsertSideCodes {
  Left = 'L',
  Right = 'R'
}

export enum CauseOfDeathCategoryCodes {
  Unknown = 'UNK'
}

export enum xMatchAutologousAllogeneic {
  Auto = 'AUTO',
  Allo = 'ALLO'
}

export enum WaitDaysAdjustmentReasons {
  GraftFailureWithin90DaysOfTransplant = 2,
  SccApprovedReinstatement = 3,
}

export enum PancreasTypeCodeValue {
  PAK = 'PAK',
  PRT = 'PRT',
  PTA = 'PTA',
}

export enum VirologyCodeValue {
  COVID19 = 'COVID-19',
}

export enum VirologyResultCodesValue {
  Negative = 'N',
  Positive = 'P',
  NotTested = 'U',
  Undetermined = 'UD',
  Pending = 'IP',
}

export interface CodePrecedence {
  code: any;
  order: number;
}

// Order to display virology result codes
export const VIROLOGY_RESULT_CODE_PRECEDENCE: CodePrecedence[] = [
  {
    code: VirologyResultCodesValue.Positive,
    order: 1,
  },
  {
    code: VirologyResultCodesValue.Negative,
    order: 2,
  },
  {
    code: VirologyResultCodesValue.Pending,
    order: 3,
  },
  {
    code: VirologyResultCodesValue.NotTested,
    order: 4,
  },
  {
    code: VirologyResultCodesValue.Undetermined,
    order: 5,
  },
];
