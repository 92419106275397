import { createStore } from 'vuex';
import { labs } from '@/store/labs';
import { tools } from '@/store/tools';
import { reports } from '@/store/reports';
import { lookups } from '@/store/lookups';
import { history } from '@/store/history';
import { waitlist } from '@/store/waitlist';
import { hospitals } from '@/store/hospitals';
import { recipients } from '@/store/recipients';
import { pageState } from '@/store/currentPage';
import { globalActions } from '@/store/actions';
import { procedures } from '@/store/procedures';
import { allocations } from '@/store/allocations';
import { validations } from '@/store/validations';
import { globalMutations } from '@/store/mutations';
import { laboratories } from '@/store/laboratories';
import { coordinators } from '@/store/coordinators';
import { journeyState } from '@/store/recipientJourney';
import { deceasedDonors } from '@/store/deceasedDonors';
import { administration } from '@/store/administration';
import { organSpecificDetails } from '@/store/organSpecificDetails';
import { users } from '@/store/users';
import { userAccounts } from '@/store/userAccounts';
import { livingDonors } from '@/store/livingDonors';
import { livingAllocations } from '@/store/livingAllocations';
import { utilities } from '@/store/utilities';
import { surgicalPersons } from '@/store/surgicalPersons';
import { responsiblePhysicians } from '@/store/responsiblePhysicians';
import { tasks } from '@/store/tasks';
import { decisions } from '@/store/decisions';

const store = createStore({
  state: {
    pageTitle: 'Loading...',
    currentUser: null,
    systemOrganTypes: {},
  },
  actions: globalActions,
  mutations: globalMutations,
  modules: {
    labs,
    tools,
    reports,
    lookups,
    history,
    waitlist,
    pageState,
    hospitals,
    recipients,
    procedures,
    validations,
    allocations,
    journeyState,
    laboratories,
    coordinators,
    deceasedDonors,
    administration,
    organSpecificDetails,
    users,
    userAccounts,
    livingDonors,
    livingAllocations,
    surgicalPersons,
    responsiblePhysicians,
    utilities,
    tasks,
    decisions
  },
});

export default store;
