<template>
  <div class="widget-links">
    <a :href="`#${optionsId}`" class="nav-link card-header-btn py-0" data-toggle="collapse">
      <font-awesome-icon :icon="['far', 'cog']" data-toggle="tooltip" :title="$t('customize_panel')" />
    </a>
    <a :href="`#${sectionId}`" class="nav-link card-header-btn py-0" data-toggle="collapse">
      <font-awesome-icon :icon="['far', 'caret-down']" data-toggle="tooltip" :title="$t('collapse_panel')" />
    </a>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { i18nMessages } from '@/i18n';

export interface WidgetOptionsModel {
  style: string;
  visible: boolean;
}

@Component({
  ...i18nMessages([
    require('@/components/dashboard/widgets/shared/_locales/WidgetLinks.json'),
  ]),
})
export default class WidgetLinks extends Vue {
  @Prop({ required: true }) optionsId!: string;
  @Prop({ required: true }) sectionId!: string;
}
</script>

<style scoped>
.widget-links {
  display: flex;
}
</style>