<template>
  <form :action="actionUrl" v-on:submit="onSubmit" method="post">
    <button
      type="submit"
      class="btn btn-dark login float-right"
    >
      <div>
        <div class="logo-image-wrapper">
          <img src="@/assets/img/google-logo.png" />
        </div>
        <span>{{ $t('login_with_google_workspace') }}</span>
      </div>
    </button>
  </form>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator';
import { UrlUtilsMixin } from "@/mixins/url-utils-mixin";
import { APINakedRoute, EP } from '@/api-endpoints';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  ...i18nMessages([
    require('@/components/shared/_locales/LoginGoogleWorkspace.json'),
  ]),
})
export default class LoginGoogleWorkspace extends mixins(UrlUtilsMixin) {

  get actionUrl(): string {
    if (useCurrentPageStore().configuration.loginProvider !== 'google_oauth2') return '';

    return APINakedRoute(EP.loginProvider.google_oauth2);
  }

  private onSubmit(): void {
    this.prepareRequest('google_oauth2');
  }
}

</script>
