import { APIContactInfoConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIContactInfoConfig {
  public enabled: boolean;
  public contactDetails: UIFeatureConfig;
  public addresses: UIFeatureConfig;

  public constructor(apiSource: APIContactInfoConfig) {
    this.enabled = apiSource.enabled;
    this.contactDetails = new UIFeatureConfig(apiSource.patient_contact_details);
    this.addresses = new UIFeatureConfig(apiSource.patient_addresses);
  }
}
