<template>
  <div class="nav-block-wrap">
    <ul class="nav flex-column nav-block" 
      :class="{ active: isActive }">
      <li class="nav-item" >
        <span class="nav-link">
          <router-link class="no-scroll" style="color: #2e2e2e !important" :to="{ name: 'edit-organ', params: { organ_id: journeyId } }">
             <font-awesome-icon class="nav-caret" :icon="['fas', (show ? 'chevron-down' : 'chevron-right')]" fixed-width @click.prevent="toggleNav"/>
            {{ $t(organName) }}
          </router-link>
        </span>
        <template v-if="show">
          <ul class="nav flex-column">
            <li class="nav-item" @click="toggleSideNav">
              <a class="nav-link" :href="getUrl('organ-details-section')">{{$t('journey_overview', {organ: $t(organName+"1").toString()}) }}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="isReferralDetailsEnabled">
              <a class="nav-link" :href="getUrl('referral-section')">{{$t('referral')}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="isClinicalNotesEnabled">
              <a class="nav-link" :href="getUrl('notes-section')">{{$t("patient_notes")}}</a>
            </li>
          
            <li class="nav-item" @click="toggleSideNav" v-if="isChecklistsEnabled">
              <a class="nav-link" :href="getUrl('checklist-section')">{{$t("checklists")}}</a>
            </li>

            <!-- Prototype: Checklists Reorder -->
            <li class="nav-item" @click="toggleSideNav" v-if="isProtoChecklistsReorderEnabled">
              <a class="nav-link" :href="getUrl('proto-checklists-reorder')">Checklist (Reorder)</a>
            </li>

            <li class="nav-item" @click="toggleSideNav" v-if="showOrganSpecificDetails">
              <a class="nav-link" :href="getUrl(`${organRoute}-specific-details`)">{{$t('spec', { specific_details: $t('specific_details'), organ: $t(organName+"1").toString() } )}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="isDonorAcceptabilityEnabled">
              <a class="nav-link" :href="getUrl('donor-acceptability-criteria')">{{$t("donor_acceptability_criteria")}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="isTransplantDetailsEnabled">
              <a class="nav-link" :href="getUrl('transplant-details')">{{$t("transplant_details")}}</a>
            </li>
            <li class="nav-item" @click="toggleSideNav" v-if="isPostTransplantFollowUpEnabled">
              <a class="nav-link" :href="getUrl('post-transplant-follow-up-details')">{{$t("post_transplant_follow_up")}}</a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { organCodeLookup } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { i18nMessages } from '@/i18n';
import { OrganCodeValue } from '@/store/lookups/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';

@Component({
  ...i18nMessages([
    require('../../_locales/Organs.json'),
    require('@/components/recipients/_locales/SelectedRecipientJourneyLinks.json'),
  ]),
})
export default class SelectedRecipientJourneyLinks extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;

  @Getter("recipientAge", { namespace: "recipients" }) recipientAge!: number;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter("journeyName", { namespace: "recipients" }) journeyNameWithStomach!: (organCode?: number, journeyId?: string) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;

  @Prop() journeyId!: string;
  @Prop() journeyName!: string;
  @Prop() organCode!: number;

  show = false;

  public toggleNav() {
    this.show = !this.show;
  }

  public mounted() {
    this.show = this.isActive;
  }

  public toggleSideNav(): void {
    if (window.innerWidth < 992) { 
       this.$emit("toggle");
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange() {
    this.show = this.isActive;
  }

  get isReferralDetailsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.referralDetails.enabled;
  }

  get isClinicalNotesEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.clinicalNotes.enabled;
  }

  get isChecklistsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.checklists.enabled;
  }

  get isProtoChecklistsReorderEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.ChecklistReorder);
  }

  get isDonorAcceptabilityEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.donorAcceptability.enabled;
  }

  get isTransplantDetailsEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.transplantDetails.enabled;
  }

  get isPostTransplantFollowUpEnabled(): boolean {
    return useCurrentPageStore().configuration.features.journeyConfig.postTransplantFollowUp.enabled;
  }

  get isActive(): boolean {
    const organId = this.$route.params.organ_id;
    if (!!organId) {
      return this.journeyId == organId;
    }
    return false;
  }

  /**
   * Return organ name based on the organ code, with + Stomach if needed
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.journeyNameWithStomach(this.organCode, this.journeyId);
  }


  /**
   * Return organ route from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organRoute(): string {
    if (!this.organCode) {
      return '';
    }
    return organCodeLookup[this.organCode] || '';
  }

  get showOrganSpecificDetails(): boolean {
    return [
      OrganCodeValue.PancreasWhole
    ].includes(this.organCode);
  }

  public getUrl(hash: string): string {
    hash = `#${hash}`;
    
    if (this.$route.name == 'edit-organ-policy_exemptions') {
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }

    if(this.isActive) {
      return hash;
    } else {
      // We are fine not checking for the existence of client_id as for new recipients we only ever show this in active mode
      const location = this.$router.resolve({ name: 'edit-organ', params: { organ_id: this.journeyId }, hash: hash });
      return location.href;
    }
  }

  public getSubPage(subPage: string): string {
    const location = this.$router.resolve({ name: 'edit-organ-policy_exemptions', params: { organ_id: this.journeyId } });
    return location.href;
  }
}
</script>
