import { MutationTree } from 'vuex';
import { UsersState } from '@/store/users/types';

export const mutations: MutationTree<UsersState> = {
  setUser(state, userinfo) {
    // Store user info
    state.user = userinfo;
  },
  setRoles(state, roles) {
    // Store role lookup info
    state.roles = roles;
  },
  setRoute(state, route: string) {
    // Store route
    state.route = route;
  },
};
