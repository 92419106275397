import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-main" }
const _hoisted_2 = { class: "navbar navbar-expand-lg p-0" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "far fa-w fa-hx fa-bars" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("button", {
        class: "navbar-toggler",
        type: "button",
        "data-toggle": "collapse",
        "data-target": "#nav-main",
        "aria-controls": "nav-main",
        "aria-expanded": "false",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["prevent"])),
        "aria-label": _ctx.$t('toggle_navigation')
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, { icon: ['far', 'bars'] })
        ])
      ], 8, _hoisted_3),
      (_ctx.mainMenuOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: "nav-main",
            class: "collapse navbar-collapse",
            role: "navigation",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["prevent"]))
          }, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              "active-class": "active",
              to: { name: 'index' },
              exact: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('dashboard')), 1)
              ]),
              _: 1
            }),
            (_ctx.showListRecipients)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "nav-link",
                  "active-class": "active",
                  to: { name: 'list-recipients' },
                  onClick: _withModifiers(_ctx.toggleMenu, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('recipients')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isProtoRecepientListingsEnabled)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "nav-link",
                  "active-class": "active",
                  to: { name: 'proto-recipients' },
                  onClick: _withModifiers(_ctx.toggleMenu, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Proto Patients ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.isProtoPrioritizationReportEnabled)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  class: "nav-link",
                  "active-class": "active",
                  to: { name: 'proto-prioritization-report' },
                  onClick: _withModifiers(_ctx.toggleMenu, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Prioritization Report ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}