export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add_required_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Required Organ"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All "])},
        "recipient_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Profile"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo Logo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afflo Logo"])},
        "use_form_below_new_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the form below to add a new row"])},
        "create_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Row"])},
        "current_page_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberStart")), " - ", _interpolate(_named("numberEnd")), " of ", _interpolate(_named("recordCount"))])},
        "unknown_caps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNKNOWN"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "fr": {
        "add_required_organ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter l'organe requis"])},
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous "])},
        "recipient_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil du patient"])},
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Afflo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo AFFLO"])},
        "use_form_below_new_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisez le formulaire ci-dessous pour ajouter une nouvelle rangée"])},
        "create_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une rangée"])},
        "current_page_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("numberStart")), " - ", _interpolate(_named("numberEnd")), " sur ", _interpolate(_named("recordCount"))])},
        "unknown_caps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNKNOWN"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      },
      "en_tgln": {
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS Logo"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OATS Logo"])}
      },
      "fr_tgln": {
        "tgln_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo OATS"])},
        "afflo_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo OATS"])}
      }
    }
  })
}
